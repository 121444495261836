<template>
  <v-combobox
    v-model="search"
    label="Pesquisar"
    placeholder="Pesquise por processo, participante ou apólice"
    single-line
    hide-details
    solo
    dense
    flat
    clearable
    v-click-outside="
      () => {
        handleChangeFocus(false);
      }
    "
    :items="items"
    @focus="handleChangeFocus(true)"
    @update:search-input="handleUpdate($event)"
    @change="handleSearch($event)"
    @keydown.tab="handleChangeFocus(false)"
    no-filter
    :append-icon="''"
    :return-object="false"
    :style="{ 'max-width': !focus ? '250px' : '400px' }"
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-icon v-if="item.type === 'history'" left>mdi-history</v-icon>
        <v-icon v-else left>mdi-magnify</v-icon>
        {{ item.text }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { sugest } from "@/services/search";

export default {
  data() {
    return {
      search: "",
      items: [],
      focus: false,
    };
  },

  methods: {
    handleChangeFocus(focus) {
      this.focus = focus;

      if (focus === true) {
        this.getSugestions();
        this.search = "";
      }
    },
    handleUpdate(search) {
      this.getSugestions(search);
    },
    async getSugestions(search = "") {
      const response = await sugest(search);

      this.items = response.data;
    },

    async handleSearch(search) {
      if (!search) return;

      await this.$router.replace(`/search?q=${encodeURIComponent(search)}`);
    },
  },
};
</script>
