import { indexActive, indexPrevious } from "@/services/reminders";

export default {
  namespaced: true,
  state: {
    active_reminders: [],
    loading_active_reminders: false,

    metadata_previous: {
      current_page: 0,
      last_page: 1,
      total: 0,
    },
    loading_previous_reminders_reminders: false,
    previous_reminders: [],

    alert_reminders: [],
  },
  mutations: {
    setActiveReminders(state, reminders) {
      state.active_reminders = reminders;
    },
    removeActiveReminder(state, reminderId) {
      state.active_reminders = state.active_reminders.filter((reminder) => {
        return reminder.id !== reminderId;
      });
    },
    setMetadataPreviousReminders(state, data) {
      state.metadata_previous = data;
    },
    setPreviousReminders(state, reminders) {
      state.previous_reminders = reminders;
    },
    alertNewReminder(state, reminder) {
      state.alert_reminders.push(reminder);
    },
    removeFromAlertReminders(state, reminder) {
      state.alert_reminders = state.alert_reminders.filter(
        (rec) => rec.id !== reminder.id
      );
    },
    removeReminder(state, reminder) {
      state.previous_reminders = state.previous_reminders.filter(
        (rec) => rec.id !== reminder.id
      );
      state.active_reminders = state.active_reminders.filter(
        (rec) => rec.id !== reminder.id
      );
    },
  },
  actions: {
    async loadActiveReminders({ commit, state }) {
      state.loading_active_reminders = true;
      try {
        const response = await indexActive();
        commit("setActiveReminders", response);
      } catch (error) {
        console.error("erro ao buscar lembretes ativos", error);
      } finally {
        state.loading_active_reminders = false;
      }
    },
    async loadInitialPreviousReminders({ commit, state }) {
      state.loading_previous_reminders = true;
      try {
        const response = await indexPrevious({
          page: 1,
        });

        commit("setMetadataPreviousReminders", {
          current_page: response.current_page,
          last_page: response.last_page,
          total: response.total,
        });

        commit("setPreviousReminders", response.data);
      } catch (error) {
        console.error("erro ao buscar lembretes ativos", error);
      } finally {
        state.loading_previous_reminders = false;
      }
    },
    async loadPreviousReminders({ commit, state }) {
      state.loading_previous_reminders = true;
      try {
        const page = state.metadata_previous.current_page;
        const last_page = state.metadata_previous.last_page;

        if (page < last_page || page === 0) {
          const response = await indexPrevious({
            page: page + 1,
          });

          commit("setMetadataPreviousReminders", {
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
          });

          commit(
            "setPreviousReminders",
            state.previous_reminders.concat(response.data)
          );
        }
      } catch (error) {
        console.error("erro ao buscar lembretes ativos", error);
      } finally {
        state.loading_previous_reminders = false;
      }
    },
    async alertReminder({ dispatch, commit }, reminder) {
      commit("alertNewReminder", reminder);

      setTimeout(() => {
        commit("removeFromAlertReminders", reminder);
      }, 10000);

      dispatch("loadActiveReminders");
      dispatch("loadInitialPreviousReminders");
    },
  },
};
