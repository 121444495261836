// Middlewares
import checkScope from "@/middlewares/scope";

// Pages
import ProductCompensationPage from "@/views/ProductCompensationPage";
import ProductNewCompensationPage from "@/views/ProductNewCompensationPage";
import ProductCompensationData from "@/views/ProductCompensationData";

/**
 * @path /leads/:lead_number/products/:product_id
 */
export default [
    {
        path: "compensations",
        beforeEnter: checkScope("leads.read"),
        component: ProductCompensationPage,
        children: [
            {
                path: "/",
                redirect: { name: "ProductCompensationDataLatest" }
            },
            {
                path: "new",
                name: "ProductNewCompensation",
                component: ProductNewCompensationPage
            },
            {
                path: "latest",
                name: "ProductCompensationDataLatest",
                component: ProductCompensationData
            },
            {
                path: ":version",
                name: "ProductCompensationData",
                component: ProductCompensationData
            },
        ]
    },
];