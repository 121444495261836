<template>
  <div>
    <v-dialog :value="preview" @click:outside="handleClose" max-width="700px" overlay-opacity=".9">
      <v-card dark v-if="file">
        <v-card-title>
          <v-icon size="23" left>{{getFilesIcon()}}</v-icon>
          {{ file.description }}
          <v-spacer />
          <v-btn @click="downloadFile()" icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn v-if="editable" @click="handleEditFile()" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="deletable" @click="confirmDelete" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn @click="handleClose" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-img contain max-height="600" v-if="isFileImage()" :src="getFilePublicUrl()"></v-img>
        <iframe
          v-else
          :src="getFilePublicUrl()"
          frameborder="0"
          width="100%"
          height="500px"
          style="background-color: white"
        ></iframe>
      </v-card>
    </v-dialog>

    <confirm-action-dialog
      :confirm_action="confirm_deletion"
      @change="confirm_deletion=$event"
      @action="handleDelete"
      :message="$capitalize($tc('model.file_file_deleted'))"
    ></confirm-action-dialog>

    <v-dialog v-model="change_file_description" max-width="400" persistent>
      <v-form @submit.prevent="changeFileDescription">
        <v-card dark>
          <v-card-actions></v-card-actions>
          <v-card-text>
            <v-text-field autofocus label="Descrição do arquivo" v-model="file_description"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="change_file_description = false">{{ $capitalize($tc("model.file_cancel_button")) }}</v-btn>
            <v-btn type="submit" :loading="loading_change_file_description" color="primary">{{ $capitalize($tc("model.file_save_button")) }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ConfirmActionDialog from './ConfirmActionDialog';

export default {
  name: "file-preview-dialog",
  components: {
    ConfirmActionDialog
  },
  model: {
    prop: "preview",
    event: "change"
  },
  props: {
    file: {
      type: [Object, File],
      default: () => ({}),
    },
    preview: Boolean,
    editable: {
      type: Boolean,
      default: () => false,
    },
    deletable: {
      type: Boolean,
      default: () => false,
    }
  },
  data: () => ({
    confirm_deletion: false,
    change_file_description: false,
    file_description: null,
    loading: false,
    loading_change_file_description: false
  }),
  methods: {
    async confirmDelete() {
      this.confirm_deletion = true;
    },
    async handleDelete() {
      if (this.deletable) {
        try {
          await this.$listeners.deleteFile();
        } finally {
          this.confirm_deletion = false;
        }
      }
    },
    handleClose() {
      this.$emit('change', false);
    },
    handleEditFile() {
      this.file_description = this.file.description;
      this.change_file_description = true;
    },
    async changeFileDescription() {
      this.loading_change_file_description = true;
      try {
        const response = await this.$http.put("/attachments/" + this.file.id, {
          description: this.file_description
        });
        this.$listeners.handleChangedFile(response.data);
        this.change_file_description = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao alterar nome",
          color: "red"
        });
      } finally {
        this.loading_change_file_description = false;
      }
    },
    isFileImage() {
      const image_extensions = ["jpg", "jpeg", "png", "gif"];

      let is_image = false;

      image_extensions.forEach(extension => {
        if (extension === this.file.extension) {
          is_image = true;
        }
      });

      return is_image;
    },
    async downloadFile() {
      window.open(this.file.public_url);
    },
    getFilePublicUrl() {
      switch (this.file.extension) {
        case "xlsx":
        case "pptx":
        case "docx":
          return this.microsoft_preview(this.file.public_url);
        default:
          return this.file.public_url;
      }
    },
    microsoft_preview(public_url) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${public_url}`;
    },
    getFilesIcon() {
      switch (this.file.extension) {
        case "jpg":
        case "jpeg":
        case "png":
          return "mdi-file-image";
        case "pdf":
          return "mdi-file-pdf";
        case "doc":
        case "docm":
        case "dot":
        case "dotm":
        case "docx":
        case "dotx":
          return "mdi-file-word";
        case "pptx":
          return "mdi-file-powerpoint";
        case "xlsx":
          return "mdi-file-excel";
        case "zip":
        case "rar":
          return "mdi-zip-box";
        case "txt":
          return "mdi-file-document";
        default:
          return "mdi-file";
      }
    }
  }
};
</script>