<template>
    <div>
        <v-text-field 
            :value="value"
            @input="handleInput"
            :label="$capitalize($tc('model.products_bar_code_optional'))"
            placeholder="Ex.: 8806098343362"
            :name="name"
            :error-messages="errorMessages"
        >
            <template v-slot:append>
                <v-btn v-if="value" @click="handleSearchBarcode" icon :loading="loading">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <v-card v-if="sugestion" outlined color="info" dark class="mb-5">
            <v-list-item>
                <v-list-item-avatar v-if="sugestion.external_photos.length" rounded>
                    <v-img :src="sugestion.external_photos[0]"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar color="info darken-2" rounded v-else>
                    <v-avatar>
                        <v-icon>mdi-image-off</v-icon>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ sugestion.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="mt-2">
                        <v-chip v-if="sugestion.type" class="mr-1" label small color="info darken-2">
                            {{ sugestion.type.name }}
                        </v-chip>
                        <v-chip v-if="sugestion.brand" class="mr-1" label small color="info darken-2">
                            {{ sugestion.brand.name }}
                        </v-chip>
                        <v-chip v-if="sugestion.model" class="mr-1" label small color="info darken-2">
                            {{ sugestion.model }}
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn @click="handleFillSugestion" color="info darken-2" small>
                        Aplicar
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
import { autocompleteBarcode } from "@/services/products/products";

export default {
    name: 'barcode-field',

    props: {
        value: String,
        errorMessages: Array,
        name: String
    },

    model: {
        prop: 'value',
        event: 'change'
    },

    data() {
        return {
            sugestion: null,
            notFound: false,
            loading: false
        };
    },

    methods: {
        handleInput(event) {
            this.sugestion = null;
            this.notFound = false;
            this.$emit('change', event);
        },
        async handleSearchBarcode() {
            this.loading = true;
            try {
                const response = await autocompleteBarcode(this.value);
                this.sugestion = response;
            } catch(error) {
                this.notFound = true;
                this.$store.commit('alert', this.$capitalize(this.$tc('model.products_no_products_found_database')));
            } finally {
                this.loading = false;
            }
        },
        handleFillSugestion() {
            this.$emit('applySugestion', { ...this.sugestion });
            this.sugestion = null;
        }
    }
}
</script>