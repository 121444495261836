// Middlewares
import checkScope from "@/middlewares/scope";

// Páginas
import NewProduct from "@/views/NewProduct";
import ProductPage from "@/views/ProductPage";
import ProductPageData from "@/views/ProductPageData";
import EditProduct from "@/views/EditProduct";
import ProductsList from "@/views/ProductsList";

// Routers
import compensationsRouter from './compensations';
import reparationsRouter from './reparations';
import substituitionsRouter from './substituitions';

/**
 * @path /leads/:lead_number
 */
export default [
    {
        path: "products",
        name: "ProductsList",
        beforeEnter: checkScope("leads.read"),
        component: ProductsList
    },
    {
        path: "products/new",
        name: "NewProduct",
        beforeEnter: checkScope("leads.read"),
        component: NewProduct
    },
    {
        path: "products/:product_id",
        beforeEnter: checkScope("leads.read"),
        component: ProductPage,
        children: [
            {
                path: "",
                name: "ProductPageData",
                component: ProductPageData
            },
            ...compensationsRouter,
            ...reparationsRouter,
            ...substituitionsRouter,
        ]
    },
    {
        path: "products/:product_id/edit",
        name: "EditProduct",
        beforeEnter: checkScope("leads.read"),
        component: EditProduct
    }
];