export default {
  namespaced: true,
  state: {
    dialog: false,
    title: "Tem certeza?",
    description: "",
    loading: false,
    positive_action: function () {},
    negative_action: function () {},
    set_error_message: null
  },
  getters: {
    getPositiveAction(state) {
      return state.positive_action
    },
    getNegativeAction(state) {
      return state.negative_action
    },
    getDialog(state) {
      return state.dialog
    }
  },
  mutations: {
    setDialog(state, payload) {
      state.dialog = payload
    },
    openDialog(state) {
      state.dialog = true
    },
    closeDialog(state) {
      state.dialog = false
    },
    setTitle(state, title) {
      if(title) state.title = title
    },
    setDescription(state, description) {
      state.description = description
    },
    setPositiveAction(state, positive_action) {
      if(positive_action) state.positive_action = positive_action
    },
    setNegativeAction(state, negative_action) {
      if(negative_action) state.negative_action = negative_action
    },
    setErrorMessage(state, set_error_message) {
      if(set_error_message) state.set_error_message = set_error_message
    },
  },
  actions: {
    confirm({ commit }, payload) {
      commit('setTitle', payload.title)
      commit('setDescription', payload.description)
      commit('setPositiveAction', payload.positive_action)
      commit('setNegativeAction', payload.negative_action)
      commit('setNegativeAction', payload.negative_action)
      commit('setErrorMessage', payload.set_error_message)
      commit('openDialog')
    },
    async answer({ state, commit }, payload) {
      state.loading = true;
      try {
        if (payload === true) {
          await state.positive_action()
        } else {
          await state.negative_action()
        }
      } catch (error) {
        if (state.set_error_message) {
          state.set_error_message(error)
        } else {
          commit('sendMessage', {
            text: "Não foi possível executar a ação",
            color: "red",
          }, { root: true })
        }
      } finally {
        state.loading = false;
        commit('closeDialog')
      }
    },
  }
};
