var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.product)?_c('div',{staticClass:"productInfo"},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                            name: 'ProductsList'
                        })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","to":{
                            name: 'EditProduct',
                            params: {
                                product_id: _vm.$route.params.product_id
                            }
                        }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'NewProduct' }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$capitalize(_vm.$tc('model.products_new_product_button')))+" ")],1)],1)],1),_c('v-list-item',{attrs:{"to":{
                                    name: 'EditProduct',
                                    params: {
                                        product_id: _vm.$route.params.product_id
                                    }
                                }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$capitalize(_vm.$tc('model.products_change_product')))+" ")],1)],1)],1),_c('v-list-item',{on:{"click":_vm.deleteProduct}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$capitalize(_vm.$tc('model.products_delete_product')))+" ")],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('router-view')],1):(_vm.notFound)?_c('ProductNotFound',{staticClass:"productNotFound"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }