<template>
  <div style="overflow-y: hidden">
    <router-view></router-view>
    <ConfirmDialog />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/tools/ConfirmDialog";
import { heartbeat } from "./services/monitoring";

export default {
  name: "App",
  components: { ConfirmDialog },
  data: () => ({
    timedOut: null,
    heartbeatInterval: null,
    active: false,
    heartbeatDelay: 60000,
    timeoutDelay: 300000,
  }),
  methods: {
    refreshTimeout() {
      this.active = true;
      clearTimeout(this.timedOut);
      this.timedOut = setTimeout(() => {
        this.active = false;
      }, this.timeoutDelay);
    },

    heartbeat(force = false) {
      if (this.active || force) {
        heartbeat();
      }
    },
  },

  watch: {
    active: function (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.heartbeatInterval = setInterval(
            this.heartbeat,
            this.heartbeatDelay
          );
        } else {
          clearInterval(this.heartbeatInterval);
        }
      }
    },
  },

  created() {
    this.refreshTimeout();

    document.addEventListener("keydown", (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      this.refreshTimeout();
    });

    document.addEventListener("ontouchstart", (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      this.refreshTimeout();
    });

    document.addEventListener("mousemove", this.refreshTimeout, false);
  },

  mounted() {
    setTimeout(this.heartbeat, 1000);
  },

  destroyed() {
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.timedOut);
    document.removeEventListener("keydown");
    document.removeEventListener("mousemove");
    document.removeEventListener("ontouchstart");
  },

  unmounted() {
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.timedOut);
    document.removeEventListener("keydown");
    document.removeEventListener("mousemove");
    document.removeEventListener("ontouchstart");
  },
};
</script>

<style>
/* html {
  overflow-y: auto;
} */

a.link {
  text-decoration: underline dotted var(--v-anchor-base) !important;
  text-underline-offset: 2px !important;
  text-decoration-thickness: 1px !important;
}

a.link:hover {
  font-weight: 500 !important;
}

.right-sheet {
  border-radius: 0 !important;
  margin: 0 !important;
  min-height: 100%;
  position: fixed;
  overflow-y: auto !important;
  top: 0;
  right: 0;
  display: flex;
}
</style>
