import Vue from "vue";

export async function index(params) {
    const response = await Vue.prototype.$http.get(
        `/lusitania/jobs`, { 
            params 
    });

    return response.data
}

export async function show(id) {
    const response = await Vue.prototype.$http.get(
        `/lusitania/jobs/${id}`
    );

    return response.data
}

export async function showLeadMetadata(id) {
    const response = await Vue.prototype.$http.get(
        `/lusitania/jobs/${id}/lead_metadata`
    );

    return response.data
}

export async function storeLead(id, payload) {
    const response = await Vue.prototype.$http.post(
        `/lusitania/jobs/${id}/lead`, payload
    );

    return response.data
}

export async function cancel(id) {
    const response = await Vue.prototype.$http.post(`/lusitania/jobs/${id}/cancel`);

    return response.data
}

export async function restore(id) {
    const response = await Vue.prototype.$http.post(`/lusitania/jobs/${id}/restore`);

    return response.data
}

export async function storeGetSingleService(payload) {
    const response = await Vue.prototype.$http.post(`/lusitania/jobs/actions/get_single_service`, payload);

    return response.data
}