<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="handleInputSelected"
      :label="$capitalize($tc('model.products_brand_product'))"
      :placeholder="$capitalize($tc('model.products_exemple_model_placeholder')) + ': Samsung'"
      :items="items"
      item-text="name"
      :loading="loading"
      :search-input.sync="search"
      return-object
      :error-messages="errorMessages"
      :name="name"
      clearable
      @click:clear="$emit('change', null)"
    >
      <template v-slot:no-data>
        <v-list-item @click="handleInputNew(search)" v-if="search">
          <v-list-item-content>
            <v-list-item-title>
              {{ $capitalize($tc('model.products_register_title')) }} "<b>{{ search }}</b
              >"
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ $capitalize($tc('model.products_nothing_found')) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { index as getBrands } from "@/services/products/brands";

export default {
  props: {
    value: {
      default: () => ({})
    },
    errorMessages: Array,
    name: String
  },

  model: {
    event: "change",
    prop: "value"
  },

  data() {
    return {
      getBrands,
      items: [],
      loading: false,
      search: ""
    };
  },

  methods: {
    async getItems() {
      try {
        this.loading = true;
        this.items = await this.getBrands({
          list: true
        });
      } catch (error) {
        console.log("não foi possivel carregar categorias de produtos");
      } finally {
        this.loading = false;
      }
    },
    handleInputSelected(event) {
      const category = {
        name: event.name
      };
      if (event.canonical) {
        category.slug = event.canonical;
      }

      this.$emit("change", category);
    },
    handleInputNew(name) {
      this.items.push({
        name: name
      });
      this.$emit("change", {
        name: name
      });
    }
  },

  created() {
    this.getItems();
  }
};
</script>
