var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$capitalize(_vm.$tc('model.products_additional_title'))))])],1),(_vm.edit == false)?_c('v-list-item-action',[_c('div',[_c('span',{staticClass:"primary--text pointer",on:{"click":function($event){return _vm.handleEdit()}}},[_vm._v(" "+_vm._s(_vm.budgetTool.formatVatPercentage(_vm.value))+" % ")]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.handleClearValue}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1)],1)]):_c('v-list-item-action',[_c('MoneyInput',{key:"aditionalValue",attrs:{"options":{
        locale: 'pt-PT',
        prefix: '',
        suffix: '%',
        length: 11,
        precision: 1
      },"value":_vm.value,"label":"Valor","single-line":"","outlined":"","dense":"","hide-details":""},on:{"input":function($event){_vm.$emit('input', Number($event))},"change":_vm.handleChangeValue,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleCancel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.handleCancel}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }