<template>
  <v-list class="py-0" two-line>
    <v-list-item selectable :href="href">
      <v-list-item-action v-if="!reminder.sent && reminder.diff_day == 'today'">
        <v-icon>mdi-clock-outline</v-icon>
      </v-list-item-action>
      <v-list-item-content v-if="!itemComponent">
        <v-list-item-title>
          <v-icon>mdi-sail-boat-sink</v-icon> Notificação corrompida
        </v-list-item-title>
        <v-list-item-subtitle class="text-wrap">
          Por favor remova este registro
        </v-list-item-subtitle>
      </v-list-item-content>
      <component
        v-else
        v-bind:is="itemComponent"
        :reminder="reminder"
        :ref="`reminder${reminder.id}`"
      />
      <v-list-item-action v-if="!noActions">
        <div>
          <v-btn @click.prevent="edit_dialog = true" icon>
            <v-icon>mdi-clock-edit</v-icon>
          </v-btn>
          <v-btn @click.prevent="confirmRemoveReminder" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>

    <ReminderDialog
      v-model="edit_dialog"
      :reminder="reminder"
      @submit="($event) => saveReminder($event)"
    />
    <v-divider></v-divider>
  </v-list>
</template>

<script>
import ReminderMixin from "@/mixins/ReminderMixin";

import ReminderDialog from "@/components/ReminderDialog";
import AnnotationReminder from "@/components/Reminders/AnnotationReminder";
import VideoCallReminder from "@/components/Reminders/VideoCallReminder";

export default {
  name: "reminder-item",
  components: {
    ReminderDialog,
    AnnotationReminder,
    VideoCallReminder
  },
  mixins: [ReminderMixin],
  props: {
    reminder: {
      type: Object,
      required: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      edit_dialog: false,
    };
  },
  computed: {
    href() {
      if(!this.metadata.redirect) {
        return null;
      }

      const route = this.$router.resolve(this.metadata.redirect);
      return route.href;
    },
    metadata() {
      return this.reminder.metadata;
    },
    itemComponent() {
      switch (this.metadata.type) {
        case "Annotation":
          return "annotation-reminder";
          case "VideoCall":
          return "video-call-reminder";
        default:
          return "";
      }
    },
  },
  methods: {
    saveReminder(payload) {
      this.updateReminder(this.reminder?.id, payload);
    },
    confirmRemoveReminder() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.$capitalize(
          this.$tc("model.reminder_remove_reminder_title")
        ),
        description: this.$capitalize(
          this.$tc("model.reminder_remove_reminder_description")
        ),
        positive_action: () =>
          this.deleteReminder(this.reminder.id, (reminder) => {
            this.$store.commit("reminder/removeReminder", reminder);
          }),
      });
    },
  },
};
</script>

<style></style>
