<template>
  <div>
    <div class="productInfo" v-if="product.id">
      <v-card>
        <v-card-title> {{ $capitalize($tc('model.products_budgets_title')) }} </v-card-title>

        <v-row class="ma-1">
          <v-col>
            <v-card flat>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar>
                    <v-icon>mdi-hammer-screwdriver</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $capitalize($tc('model.products_repair_title')) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ reparationValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="handleEditReparation" color="primary" icon>
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar>
                    <v-icon>mdi-find-replace</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $capitalize($tc('model.products_replacement_title')) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ substituitionValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="handleEditSubstituition" color="primary" icon>
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar>
                    <v-icon>mdi-account-tie</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ compensationType }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ compensationValue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="handleEditCompensation" color="primary" icon>
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row v-if="product.fitting === true">
          <v-col>
            <v-card flat>
              <v-card-title class="text-h6">
                {{ $capitalize($tc('model.products_send_company_title')) }}
              </v-card-title>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-cash-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $capitalize($tc('model.products_chosen_budget')) }}
                  </v-list-item-subtitle>
                  <v-list-item-title v-if="product.selected_budget">
                    {{ product.selected_budget.objective.description }}
                    (v {{ product.selected_budget.version }}) -
                    {{ euro(product.selected_budget.total_value_with_iva) }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-else>---</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <BudgetInput
                    v-if="product.id"
                    @input="product = $event"
                    :product-id="product.id"
                  />
                </v-list-item-action>
              </v-list-item>
              <BudgetCompensationInput
                :value="product.is_compensable"
                @input="handleChangeIsCompensable"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col>
          <v-card>
            <v-card-title> {{ $capitalize($tc('model.products_product_evaluation')) }} </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.damages_technical_evaluation'))"
                    :value="product.technical_analysis"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item :title="$capitalize($tc('model.products_product_framed'))">
                    <div>
                      <span v-if="product.fitting === true">{{ $capitalize($tc('model.products_yes_product')) }}</span>
                      <span v-else-if="product.fitting === false">{{ $capitalize($tc('model.products_no_product')) }}</span>
                      <span v-else>{{ $capitalize($tc('model.products_not_identified_product')) }}</span>
                    </div>
                  </simple-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $capitalize($tc('model.products_product_details')) }} 
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_bar_code_product'))"
                    :value="product.barcode"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_product_type'))"
                    :value="product.type.name"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_brand_product'))"
                    :value="product.brand ? product.brand.name : '---'"
                  ></simple-item>
                </v-col>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_model_product'))"
                    :value="product.model"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_serial_number'))"
                    :value="product.serial_number"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item :title="$capitalize($tc('model.products_purchase_date_product'))">
                    {{ formatDate(product.purchase_date) || "---" }}
                  </simple-item>
                </v-col>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_product_age_product'))"
                    :value="product.age"
                  ></simple-item>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_depreciation'))"
                    :value="formatPercentage(product.depreciation_percentage)"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_name_product'))"
                    :value="product.name"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item :title="$capitalize($tc('model.products_safe_money'))">
                    {{
                      product.insurance_object
                        ? product.insurance_object.type
                        : "---"
                    }}
                  </simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item :title="$capitalize($tc('model.products_size_product'))">
                    {{ product.size == "big" ? $capitalize($tc('model.products_big_size_product')) : $capitalize($tc('model.products_small_size_product')) }}
                  </simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_owner_product'))"
                    :value="product.owner.contact.name"
                  ></simple-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <simple-item
                    :title="$capitalize($tc('model.products_responsible_product'))"
                    :value="product.storage_participant ? product.storage_participant.contact.name : ''"
                  ></simple-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <ProductTechnicalDetails
            :value="product.technical_details"
            readonly
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <AttachmentGallery :title="$capitalize($tc('model.products_photographs_product'))" v-model="product.photos" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import SimpleItem from "@/components/SimpleItem";
import { formatDate } from "@/tools/date.js";
import ProductTechnicalDetails from "@/components/products/ProductTechnicalDetails";
import AttachmentGallery from "@/components/AttachmentGallery";
import { destroy, update } from "@/services/products/products";
import Formatter from "@/tools/money";
import { formatDecimal } from "@/tools/number";
import reparationTool from "@/tools/products/budgets/reparation";
import substituitionTool from "@/tools/products/budgets/substituition";
import BudgetInput from "@/components/Budget/BudgetInput";
import BudgetCompensationInput from "@/components/Budget/BudgetCompensationInput";

export default {
  components: {
    SimpleItem,
    ProductTechnicalDetails,
    AttachmentGallery,
    BudgetInput,
    BudgetCompensationInput
  },

  data() {
    return {
      euro: Formatter.euro,
      formatDate,
      loading: false,
      notFound: false,
      productReparationPage: {
        name: "ProductReparationsPage"
      }
    };
  },

  methods: {
    formatPercentage(number) {
      if (!number) return '';
      return formatDecimal(number, 2) + '%';
    },
    async handleChangeIsCompensable(event) {
      this.product = {
        ...this.product,
        is_compensable: event
      };

      const leadNumber = this.$route.params.lead_number;
      const product = await update(this.product.id, leadNumber, {
        product: {
          ...this.product,
          is_compensable: event
        }
      });

      this.product = product;
      this.$store.commit("alert", this.$capitalize(this.$tc('model.products_save_alert')));
    },
    handleEditSubstituition() {
      if (
        this.product.budgets.find(
          budget => budget.objective.canonical == "substituition"
        )
      ) {
        this.$router.push({ name: "ProductSubstituitionDataLatest" });
      } else {
        this.$router.push({ name: "ProductNewSubstituition" });
      }
    },
    handleEditCompensation() {
      if (
        this.product.budgets.find(
          budget => budget.objective.canonical == "compensation"
        )
      ) {
        this.$router.push({ name: "ProductCompensationDataLatest" });
      } else {
        this.$router.push({ name: "ProductNewCompensation" });
      }
    },
    handleEditReparation() {
      if (
        this.product.budgets.find(
          budget => budget.objective.canonical == "reparation"
        )
      ) {
        this.$router.push({ name: "ProductReparationDataLatest" });
      } else {
        this.$router.push({ name: "ProductNewReparation" });
      }
    },
    deleteProduct() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.$capitalize(this.$tc('model.products_confirmation_delect_product_title')),
        description: this.$capitalize(this.$tc('model.products_description_delect_product')),
        positive_action: async () => {
          const productId = this.$route.params.product_id;
          const leadNumber = this.$route.params.lead_number;

          await destroy(productId, leadNumber);
          this.$store.commit("alert", this.$capitalize(this.$tc('model.products_delete_product_alert')));
          this.$router.replace(`/leads/${leadNumber}/products`);
        }
      });
    }
  },

  computed: {
    product: {
      get() {
        return this.$store.getters["product/getProduct"];
      },
      set(value) {
        return this.$store.commit("product/setProduct", value);
      }
    },
    compensationType() {
      const lastCompensation = this.$store.getters[
        "product/getCompensationByVersion"
      ]("latest");

      if (!lastCompensation) return this.$capitalize(this.$tc('model.products_external_budget_title'));

      return `Externo - ${lastCompensation.external_objective.description}`;
    },
    compensationValue() {
      const lastCompensation = this.$store.getters[
        "product/getCompensationByVersion"
      ]("latest");

      if (lastCompensation) {
        let total = 0;
        lastCompensation.external_items.forEach(budget => {
          total += parseFloat(budget.unit_price) * parseFloat(budget.quantity);
        });

        total += parseFloat(lastCompensation.additional_value);

        return this.euro(total);
      } else {
        return "---";
      }
    },
    reparationValue() {
      const lastReparation = this.$store.getters[
        "product/getReparationByVersion"
      ]("latest");

      if (lastReparation) {
        const total = reparationTool.getTotal(lastReparation);

        return this.euro(total);
      } else {
        return "---";
      }
    },
    substituitionValue() {
      const lastSubstituition = this.$store.getters[
        "product/getSubstituitionByVersion"
      ]("latest");

      if (lastSubstituition) {
        const total = substituitionTool.getTotal(lastSubstituition);

        return this.euro(total);
      } else {
        return "---";
      }
    }
  }
};
</script>
