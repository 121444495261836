<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="subtitle-2">{{ $capitalize($tc('model.products_vat')) }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="edit == false">
      <div>
        <span @click="handleEdit()" class="primary--text pointer">
          {{ value }} %
        </span>
        <v-btn @click="handleClearValue" small icon>
          <v-icon small>mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
    <v-list-item-action v-else>
      <v-select
        @input="handleChangeValue"
        :value="value"
        :items="ivaValues"
        single-line
        :label="$capitalize($tc('model.products_vat'))"
        hide-details
        outlined
        dense
      ></v-select>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Formatter from "@/tools/money";

export default {
  components: {},

  props: {
    value: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      euro: Formatter.euro,
      edit: false,
      ivaValues: [23, 22, 18, 0]
    };
  },

  methods: {
    handleEdit() {
      this.edit = true;
    },
    handleChangeValue(event) {
      console.log(event);
      this.$emit("input", event);
      this.edit = false;
    },
    handleClearValue() {
      this.$emit("input", 0);
      this.edit = false;
    },
    handleCancel() {
      this.edit = false;
    }
  }
};
</script>
