import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure
} from "vee-validate";
import i18n from "../i18n";
import "../validators";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
