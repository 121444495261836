<script>
export default {
  methods: {
    toast(message) {
      this.$store.commit("sendMessage", {
        text: message,
      });
    },

    toastError(message) {
      this.$store.commit("sendMessage", {
        text: message,
        color: "red",
      });
    },

    toastSuccess(message) {
      this.$store.commit("sendMessage", {
        text: message,
        color: "green",
      });
    },
  },
};
</script>
