import Vue from "vue";

export async function indexMine() {
  const response = await Vue.prototype.$http.get(`/video_calls/attempts/mine`);

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post(
    `/video_calls/attempts`,
    payload
  );

  return response.data;
}

export async function answer(id) {
  const response = await Vue.prototype.$http.post(
    `/video_calls/attempts/${id}/answer`
  );

  return response.data;
}

export async function refuse(id) {
  const response = await Vue.prototype.$http.post(
    `/video_calls/attempts/${id}/refuse`
  );

  return response.data;
}

export async function cancel(id) {
  const response = await Vue.prototype.$http.delete(
    `/video_calls/attempts/${id}`
  );

  return response.data;
}
