import { capitalize } from "./string";

export function abreviate(full_name) {
  const splitted_name = full_name.split(" ").map(s => capitalize(s));

  if (splitted_name.length > 1) {
    return `${splitted_name[0]} ${splitted_name[splitted_name.length - 1]}`;
  }

  return full_name;
}

export default {
  abreviate
};