import Vue from "vue";

export async function show(key) {
  const response = await Vue.prototype.$http.get(`/video_calls/${key}`);

  return response.data;
}

export async function showForPerit(key) {
  const response = await Vue.prototype.$http.get(`/video_calls/p/${key}`);

  return response.data;
}

export async function indexForLead(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/video_calls/lead/${lead_number}`
  );

  return response.data;
}

export async function indexMine() {
  const response = await Vue.prototype.$http.get(`/video_calls/mine`);

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post(`/video_calls`, payload);

  return response.data;
}

export async function update(key, payload) {
  const response = await Vue.prototype.$http.put(
    `/video_calls/${key}`,
    payload
  );

  return response.data;
}

export async function joinGuest(key) {
  const response = await Vue.prototype.$http.post(`/video_calls/${key}/join`);

  return response.data;
}

export async function joinPerit(key) {
  const response = await Vue.prototype.$http.post(
    `/video_calls/${key}/join_perit`
  );

  return response.data;
}

export async function finish(key) {
  const response = await Vue.prototype.$http.post(`/video_calls/${key}/finish`);

  return response.data;
}

export async function resend_link(key) {
  await Vue.prototype.$http.post(`/video_calls/${key}/resend_link`);
}
