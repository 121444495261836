<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card :loading="loading">
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" @click="answer(false)" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text> {{ description }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" @click="answer(false)" text>{{ __('Não', 'leads') }}</v-btn>
        <v-btn :disabled="loading" @click="answer(true)" color="primary"
          >{{ __('Sim', 'leads') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  methods: {
    ...mapActions("confirm_dialog", ["answer"]),
  },
  computed: {
    ...mapState("confirm_dialog", {
      title: (state) => state.title,
      description: (state) => state.description,
      loading: (state) => state.loading,
    }),
    dialog: {
      get() {
        return this.$store.getters["confirm_dialog/getDialog"];
      },
      set(value) {
        this.$store.commit("confirm_dialog/setDialog", value);
      },
    },
  },
};
</script>