<template>
    <div>
        <v-card>
            <v-card-title>
                {{ $capitalize($tc('model.products_datasheet')) }}
                <v-spacer></v-spacer>
                <v-btn @click="handleCreateNewFeature()" v-if="readonly == false" color="primary" text>
                    <v-icon left>mdi-plus</v-icon>
                    {{ $capitalize($tc('model.products_add_datasheet')) }}
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                {{ $capitalize($tc('model.products_technical_specifications')) }}
            </v-card-subtitle>
            <v-card-text>
                <v-simple-table dense>
                    <tbody>
                        <tr v-for="(item, index) in value" :key="getFeatureId(item)" :style="{'background-color': index%2 == 0 ? '#f5f5f5' : ''}">
                            <td>
                                <a @click="handleEditFeature(item)" v-if="!readonly">
                                    {{item.feature.name}}
                                </a>
                                <span v-else>{{item.feature.name}}</span>
                            </td>
                            <td class="text-right">{{item.value}}</td>
                            <td v-if="readonly == false" width="100px" class="text-right">
                                <v-btn @click="handleEditFeature(item)" small icon>
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn @click="handleDeleteFeature(item)" small icon>
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-if="value.length == 0">
                            <td colspan="3" class="text-center text--disabled">
                               {{ $capitalize($tc('model.products_no_atrributes')) }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="500" eagle>
            <v-card>
                <ValidationObserver ref="technicalDetailsForm" v-slot="{ invalid }">
                    <v-form @submit.prevent="handleSubmitForm">
                        <v-card-title>
                            {{ $capitalize($tc('model.products_technical_specification')) }}
                            <v-spacer></v-spacer>
                            <v-btn @click="handleCloseDialog" icon>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <ValidationProvider
                                        name="feature"
                                        :rules="{
                                            required: true
                                        }"
                                        v-slot="{ errors }"
                                    >
                                        <v-combobox 
                                            v-model="form.feature"
                                            :items="items"
                                            :loading="loading"
                                            :label="$capitalize($tc('model.products_attribute'))" 
                                            :placeholder="$capitalize($tc('model.products_technical_specifications_placeholder'))"
                                            @change="handleChangeFeature"
                                            :disabled="!candEditName"
                                            :autofocus="candEditName"
                                            :search-input.sync="search"
                                            item-text="name"
                                            return-object
                                            :error-messages="errors"
                                        ></v-combobox>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider
                                        name="value"
                                        :rules="{
                                            required: true
                                        }"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field 
                                            name="value"
                                            v-model="form.value" 
                                            :label="$capitalize($tc('model.products_value'))"
                                            :placeholder="$capitalize($tc('model.products_value_types_placeholder'))"
                                            :autofocus="!candEditName"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="handleCloseDialog" text color="primary">
                                {{ $capitalize($tc('model.products_cancel_button')) }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid">
                                {{ $capitalize($tc('model.products_save_button')) }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { index as getFeatures } from "@/services/products/features";

export default {
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        readonly: {
            type: Boolean,
            default: false
        },
        productTypeId: {
            type: Number,
            default: null
        }
    },

    model: {
        prop: 'value',
        event: 'change'
    },

    data() {
        return {
            dialog: false,
            form: {
                feature: null
            },
            candEditName: true,
            items: [],
            getFeatures,
            loading: false,
            search: ''
        };
    },

    watch: {
        productTypeId(value) {
            if(value) {
                this.getItems(value);
            }
        }
    },

    methods: {
        saveFeature(newFeature, features) {
            if(this.value.find(feature => this.compareFeature(feature, newFeature))) {
                features = this.value.map(feature => {
                    if(this.compareFeature(feature, newFeature)) {
                        return newFeature;
                    }

                    return feature;
                });
            } else {
                features.push(newFeature);
            }

            return features;
        },
        handleSubmitForm() {
            const prepareFeature = (feature) => {
                if(typeof(feature) == 'string') {
                    return {
                        name: feature
                    };
                }

                return feature;
            }

            const input = {
                ...this.form,
                feature: prepareFeature(this.form.feature)
            };

            const newFeatures = this.saveFeature(input, this.value);
            this.$emit('change', newFeatures);

            this.dialog = false;
        },
        handleDeleteFeature(feature) {
            this.$store.dispatch("confirm_dialog/confirm", {
                title: "Excluir atributo?",
                description: "",
                positive_action: () => {
                    this.$emit('change', this.value.filter(f => !this.compareFeature(f, feature)));
                }
            }); 
        },
        compareFeature(feature1, feature2) {
            return this.getFeatureId(feature1) == this.getFeatureId(feature2);
        },
        getFeatureId(item) {
            const slug = item.slug;
            const name = item.feature.name.toLowerCase();
            
            if(slug) {
                return `${name}-${slug}`;
            } else {
                return `${name}`;
            }
        },
        handleEditFeature(feature) {
            this.initDialog({...feature});
            this.candEditName = false;
        },
        handleCreateNewFeature() {
            this.initDialog();
            this.candEditName = true;
        },
        handleChangeFeature() {
            this.form.value = null;
        },
        async initDialog(initialValue = {}) {
            this.form = initialValue;
            this.dialog = true;
            this.$refs.technicalDetailsForm.reset();
        },
        handleCloseDialog() {
            this.dialog = false;
        },
        async getItems(category_id) {
            this.items = [];
            this.loading = true;
            this.items = await this.getFeatures({
                list: true,
                category_id
            });
            this.loading = false;
        }
    },
}
</script>