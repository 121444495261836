<template>
    <div>
        <v-row>
            <v-col>
                <BudgetPageToolbar
                    :title="$capitalize($tc('model.products_replacement_title'))" 
                    :back-to="productPage" 
                    :new-to="newVersionPage" 
                    version-route-name="ProductSubstituitionData"
                    :budget-versions="substituitions"
                />
            </v-col>
        </v-row>

        <router-view></router-view>
    </div>
</template>

<script>
import BudgetPageToolbar from "@/components/Budget/BudgetPageToolbar";

export default {
    components: {
        BudgetPageToolbar
    },

    data() {
        return {
            productPage: {
                name: 'ProductPageData'
            },
            newVersionPage: {
                name: 'ProductNewSubstituition'
            }
        }
    },
    computed: {
        substituitions() {
            return this.$store.getters['product/getSubstituitions']
        }
    }
}
</script>