export function capitalize(s) {
  if (typeof s !== "string") return "";
  s = s.toLowerCase();

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function nlToBr(text) {
  return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

export function removeAccents(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function firstWord(string) {
  return string.split(" ")[0];
}
