import Vue from "vue";
import App from "./App.vue";
import "./style.scss";
import router from "./router";
import store from "./store";
import i18n, { setI18nLanguage } from "./i18n";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./plugins/vee-validate";
import Container from "./components/Container";
import { capitalize } from "./tools/string";
import { languages_info } from "./services/localizations";
import "./plugins/echo";

Vue.component("container", Container);

/** Moment */
import moment from "moment";
moment.locale("pt");
Vue.prototype.$moment = moment;

/** Lodash */
import _ from "lodash";

/** Axios */
import axios from "axios";
axios.defaults.baseURL = "/api";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    switch (status) {
      case 401:
        if (localStorage.getItem("token")) {
          try {
            return store
              .dispatch("auth/refreshTokens")
              .then(() =>
                axios.request({
                  ...error.config,
                  headers: {
                    ...error.config.headers,
                    Authorization: store.state.auth.access_token,
                  },
                })
              )
              .catch((error) => Promise.reject(error));
          } catch (error) {
            router.push({ name: "logout" });
          }
        } else {
          router.push({ name: "login" });
        }
        break;
      case 403:
        store.commit("sendMessage", {
          text: "Você não está autorizado a realizar esta ação",
        });
        break;
    }

    return Promise.reject(error);
  }
);
Vue.prototype.$http = axios;
/** /Axios */

router.beforeEach((to, from, next) => {
  const no_auth_pages = [
    "login",
    "senha",
    "recuperar",
    "logout",
    "video_call",
    "video_call_ended",
    "visit_report",
    "video_call_page",,
    "video_call_not_found",
    "video_call_error"
  ];

  if (!no_auth_pages.find(page => page == to.name)) {
    const access_token = store.getters["auth/getAccessToken"];

    if (!access_token) {
      store.dispatch("auth/refreshTokens").then((new_token) => {
        if (!!new_token) {
          next();
        } else {
          next("/login");
        }
      });
    } else {
      next();
    }
  } else {
    const language_browser = navigator.language.split("-").join("_") || "en_US";
    setI18nLanguage({
      id: language_browser,
      without_country: languages_info[language_browser]?.without_country
    });

    next();
  }
});

const DEFAULT_TITLE = "HCM - Home Claims Management";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

/** Vue the Mask  */
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

/** vuetify-money */
import "./plugins/vuetify-money";

/** vue-highlightjs */
import "./plugins/vue-highlightjs";

/** Vue Drag Drop */
import VueDragDrop from "vue-drag-drop";
Vue.use(VueDragDrop);

/** capitalize string */
Vue.prototype.$capitalize = capitalize;

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
