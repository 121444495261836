<template>
  <v-dialog
    :value="value"
    @change="handleChange"
    @click:outside="handleClose"
    max-width="800px"
  >
    <v-card>
      <v-app-bar flat>
        <v-card-title class="px-0">{{
          $capitalize($tc("model.photographic_report_select_photos_title"))
        }}</v-card-title>

        <v-spacer />
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="uncheckAll">
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </v-btn>
          </template>
          <span>
            {{
              $capitalize($tc("model.photographic_report_deselect_all_photos"))
            }}
          </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="checkAll">
              <v-icon>mdi-check-box-multiple-outline</v-icon>
            </v-btn>
          </template>
          <span>
            {{
              $capitalize($tc("model.photographic_report_select_all_photos"))
            }}
          </span>
        </v-tooltip>
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <empty-state
        :when="photos.length == 0"
        :message="$capitalize($tc('model.products_no_photo_uploaded_message'))"
      >
        <v-form @submit.prevent="submit">
          <v-card-text>
            <v-virtual-scroll
              :bench="1"
              :items="chunked_photos"
              :item-height="lineHeight"
              :height="virtualScrollerHeight"
              v-resize="onResize"
            >
              <template v-slot="{ item }">
                <v-item-group multiple v-model="chosen_photos">
                  <v-row dense no-gutters>
                    <v-col
                      v-for="photo in item"
                      :key="photo.id"
                      class="d-flex child-flex px-1"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                    >
                      <v-item v-slot="{ active, toggle }" :value="photo">
                        <v-card
                          outlined
                          :elevation="active ? 3 : 0"
                          @click="togglePhoto(photo, toggle)"
                        >
                          <v-img
                            aspect-ratio="1"
                            :src="photo.public_url"
                            :height="imageHeight"
                          >
                            <div class="d-flex justify-space-between mx-1 mt-1">
                              <v-btn
                                fab
                                x-small
                                @click.stop.prevent="previewPhoto(photo)"
                                ><v-icon>mdi-magnify</v-icon></v-btn
                              >
                              <v-btn
                                fab
                                x-small
                                color="green"
                                dark
                                v-if="alreadySelected(photo) || active"
                                ><v-icon>mdi-check</v-icon></v-btn
                              >
                            </div>
                          </v-img>
                          <v-overlay
                            absolute
                            class="font-weight-bold"
                            v-if="alreadySelected(photo)"
                            >Já foi selecionada</v-overlay
                          >
                          <v-card-text class="font-weight-medium pb-0">{{
                            photo.name
                          }}</v-card-text>
                          <v-card-text class="pt-0">
                            {{
                              photo.category
                                ? photo.category.description
                                : "Sem categoria"
                            }}
                            <v-tooltip
                              bottom
                              v-if="photo.part_of_photographic_report"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  right
                                  color="warning"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span>{{
                                $capitalize(
                                  $tc(
                                    "model.photographic_report_photo_used_message"
                                  )
                                )
                              }}</span>
                            </v-tooltip>
                          </v-card-text>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" type="submit">
              {{ $capitalize($tc("model.photographic_report_confirm_button")) }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </empty-state>
    </v-card>

    <file-preview-dialog
      v-model="file_preview"
      @change="file_preview = $event"
      :file="preview_photo"
      editable
      @handleChangedFile="handlePhotoChange"
    />
  </v-dialog>
</template>

<script>
import FilePreviewDialog from "@/components/FilePreviewDialog.vue";
import EmptyState from "@/components/EmptyState";

export default {
  components: { FilePreviewDialog, EmptyState },
  props: {
    value: Boolean,
    photos: Array,
    selected_photos: Array,
  },
  data: () => ({
    chosen_photos: [],
    windowHeight: 0,

    //File Preview
    file_preview: false,
    preview_photo: {},
  }),
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      this.chosen_photos = [];
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    alreadySelected(photo) {
      return this.selected_photos.some((rec) => rec.id == photo.id);
    },
    togglePhoto(photo, toggleFunc) {
      if (!this.alreadySelected(photo)) {
        toggleFunc();
      }
    },
    checkAll() {
      this.chosen_photos = this.photos;
    },
    uncheckAll() {
      this.chosen_photos = [];
    },
    submit() {
      this.$listeners.submit(this.chosen_photos);
      this.handleClose();
    },

    // File Preview
    previewPhoto(photo) {
      this.preview_photo = photo;
      this.file_preview = true;
    },
    handlePhotoChange(photo) {
      this.$store.commit("setLeadAvailableReportPhoto", photo);
      this.preview_photo = photo;
    },
  },
  computed: {
    lineHeight: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "260";
        case "sm":
          return "240";
        case "md":
          return "240";
        case "lg":
          return "290";
        case "xl":
          return "290";
      }

      return "250";
    },
    itemsPerLine: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }

      return 6;
    },
    imageHeight: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "160";
        case "sm":
          return "150";
        case "md":
          return "150";
        case "lg":
          return "200";
        case "xl":
          return "200";
      }

      return "200";
    },
    virtualScrollerHeight: function () {
      return this.windowHeight * 0.7;
    },
    chunked_photos: function () {
      const groupsOfPhotos = [];
      const photos = this.photos ? this.photos : [];

      for (let i = 0; i < photos.length; i += this.itemsPerLine) {
        groupsOfPhotos.push(photos.slice(i, i + this.itemsPerLine));
      }

      return groupsOfPhotos;
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>
