import Vue from "vue";

export async function index(params) {
  const response = await Vue.prototype.$http.get("/contacts", {
    params
  });

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/contacts", payload);

  return response.data;
}

export async function update(contactId, payload) {
  const response = await Vue.prototype.$http.put(`/contacts/${contactId}`, payload);

  return response.data;
}


export async function addAddress(
  country_area_id,
  city_id,
  city_area_id,
  street,
  number = null,
  contact_id
) {
  const payload = {
    country_area_id,
    city_id,
    city_area_id,
    street,
    number,
    contact_id
  };
  const response = await Vue.prototype.$http.post("/addresses", payload);

  return response.data;
}

export async function listContactsWithAddresses(filter) {
  const response = await Vue.prototype.$http.get("/contacts_addresses", {
    params: { filter }
  });
  return response.data;
}


export async function saveAddress(contact_id, address) {
  const response = await Vue.prototype.$http.post(`/contacts_addresses/${contact_id}` , {
    address
  });

  return response.data;
}