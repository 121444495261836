import Vue from "vue";
import Vuex from "vuex";
import lead_statuses from "./lead_statuses";
import room_types from "./room_types";
import room_forfaits from "./room_forfaits";
import local_categories from "./local_categories";
import damage_claim from "./damage_claim";
import auth from "./auth";
import localization from "./localization";
import lead_types from "./lead_types";
import letter_types from "./letter_types";
import confirm_dialog from "./confirm_dialog";
import service_managers from "./service_managers";
import scheduling_objectives from "./scheduling_objectives";
import scheduling_types from "./scheduling_types";
import schedulings from "./schedulings";
import visit_report from "./visit_report";
import info_requests from "./info_requests";
import established_budget from "./established_budget";
import tasks from "./tasks";
import eps from "./eps";
import product from "./product";
import reminder from "./reminder";
import lead_form from "./lead_form";
import lead_notifications from "./lead_notifications";
import video_calls from "./video_calls";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    message: "",
    loading: false,
    snackbar: {},
    videoCall: {
      isActive: false,
      isFullscreen: false,
    },
    lead: {
      default_service: {},
      participants: [],
      services: [],
      policy: {
        coverages: [],
        insurance_objects: [],
      },
      participant_insured: {},
      insurer: {},
      country_area: {},
      city: {},
      attachments: [],
      visit_report: {},
      available_report_photos: [],
      last_info_request: {},
      unread_notifications: [],
      read_notifications: [],
    },
    scheduling: {
      status: {},
      scheduling_type: {},
      contact_attempt: {},
      addresses: [],
      date_and_unity: {
        unity: {},
      },
    },
    locale: "pt-pt",
  },
  getters: {
    isLoggedin: (state) => {
      return state.user ? true : false;
    },
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.token;
    },
    getMessage: (state) => {
      return state.message;
    },
    isAdmin: (state) => {
      if (state.user) {
        return state.user.admin;
      } else return false;
    },
    getVideoCall: (state) => {
      return state.videoCall;
    },
    getLoading: (state) => {
      return state.loading;
    },
    getSnackbar: (state) => state.snackbar,
    getLead: (state) => {
      return state.lead;
    },
    getLeadStatusHistory: (state) => {
      return state.lead.status_history;
    },
    getLocale: (state) => {
      return state.locale;
    },
    getScheduling: (state) => {
      return state.scheduling;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      const token_data = token.split(".");
      const data = atob(token_data[1]);
      const user = JSON.parse(data);
      state.user = user;
      Vue.prototype.$http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${state.token}`;
      localStorage.setItem("token", state.token);
    },
    removeToken(state) {
      localStorage.removeItem("token");
      state.user = null;
      state.token = null;
      Vue.prototype.$http.defaults.headers.common["Authorization"] = ``;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setLoading(state, payload) {
      state.loading = payload.loading;
    },
    sendMessage(state, payload) {
      state.snackbar = { ...payload, active: true };
    },
    alert(state, text) {
      state.snackbar = { text, active: true };
    },
    success(state, text) {
      state.snackbar = { text, color: "success", active: true };
    },
    error(state, text) {
      state.snackbar = { text, color: "red", active: true };
    },
    closeMessage(state) {
      state.snackbar.active = false;
    },
    setLead(state, lead) {
      state.lead = lead;
    },
    assignUnity(state, unity) {
      state.lead = {
        ...state.lead,
        unity,
        unity_id: unity.id,
      };
    },
    unassignUnity(state) {
      state.lead = {
        ...state.lead,
        unity: null,
        unity_id: null,
      };
    },
    clearLead(state) {
      state.lead = {
        default_service: {},
        participants: [],
        services: [],
        policy: {
          coverages: [],
          insurance_objects: [],
        },
        insurer: {},
        country_area: {},
        city: {},
        visit_report: {},
        last_info_request: {},
      };
    },
    setScheduling(state, scheduling) {
      state.scheduling = scheduling;
    },
    clearScheduling(state) {
      state.scheduling = {
        status: {},
        scheduling_type: {},
        contact_attempt: {
          participant: {},
        },
        addresses: [],
        date_and_unity: {
          unity: {},
        },
      };
    },
    setLocale(state, payload) {
      state.locale = payload;
    },
    setLeadStatusHistory(state, payload) {
      state.lead.status_history = payload;
    },
    toggleVideoCallFullscreen(state) {
      state.videoCall.isFullscreen = !state.videoCall.isFullscreen;
    },
    setVideoCall(state, videoCall) {
      state.videoCall = videoCall;
    },
    setLeadAvailableReportPhoto(state, photo) {
      state.lead.available_report_photos =
        state.lead.available_report_photos.map((rec) => {
          if (rec.id == photo.id) {
            return {
              ...rec,
              ...photo,
            };
          }

          return rec;
        });
    },
  },
  actions: {
    async getLead({ commit }, lead_number) {
      const response = await Vue.prototype.$http.get(`/leads/${lead_number}`);
      commit("setLead", {
        ...response.data,
      });
    },
    async refreshLead({ commit, state }) {
      if (!state.lead) {
        return;
      }

      const response = await Vue.prototype.$http.get(
        `/leads/${state.lead.identifier_code}`
      );
      commit("setLead", {
        ...response.data,
      });
    },
  },
  modules: {
    auth,
    localization,
    lead_statuses: lead_statuses,
    room_types,
    room_forfaits,
    local_categories,
    damage_claim,
    lead_types,
    letter_types,
    service_managers,
    scheduling_objectives,
    scheduling_types,
    confirm_dialog,
    schedulings,
    visit_report,
    info_requests,
    established_budget,
    tasks,
    eps,
    product,
    lead_form,
    reminder,
    lead_notifications,
    video_calls,
  },
});
