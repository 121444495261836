import { indexTypes } from "@/services/letters";

export default {
  namespaced: true,
  state: {
    letter_types: []
  },
  getters: {
    getLetterTypes(state) {
      return state.letter_types;
    }
  },
  mutations: {},
  actions: {
    async loadLetterTypes({ state }) {
      if (state.letter_types.length == 0) {
        try {
          const types = await indexTypes();

          state.letter_types = types;
        } catch (error) {
          console.error("erro ao buscar os tipos de cartas", error);
        }
      }
    }
  }
};
