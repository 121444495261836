import Vue from "vue";
import Config from "@/assets/config.json";

const base_uri = Config.VUE_APP_BASE_PRODUCTS_API;


export async function index(params) {
  const response = await Vue.prototype.$http.get(`${base_uri}/local_categories`, {
    params
  });

  return response.data;
}