<template>
  <div>
    <div class="subtitle-2">{{ label }}</div>
    <slot v-bind:date="date" v-bind:time="time" />
  </div>
</template>

<script>
export default {
  props: ["timestamp", "time", "date", "label"],
  model: {
    prop: "timestamp",
    event: "change",
  },
  methods: {
    getDate(timestamp) {
      if (!timestamp) return "";
      const parts = timestamp.split(" ");

      return parts[0] || "";
    },
    getTime(timestamp) {
      if (!timestamp) return "";
      const parts = timestamp.split(" ");

      return parts[1] || "";
    },
    setDate(timestamp, date) {
      return `${date} ${this.getTime(timestamp)}`;
    },
    setTime(timestamp, time) {
      return `${this.getDate(timestamp)} ${time}`;
    },
    updateDateTime(value) {
      const time = this.getTime(value);
      const date = this.getDate(value);

      this.$emit("update:time", time);
      this.$emit("update:date", date);
    },
    updateTimestampByDate(date) {
      const time = this.getTime(this.timestamp);
      if (!time) {
        this.$emit("change", date);
        return;
      } else if (!date) {
        this.$emit("change", "");
        return;
      }

      this.$emit("change", this.setDate(this.timestamp, date));
    },
    updateTimestampByTime(time) {
      const date = this.getDate(this.timestamp);
      if (!time) {
        this.$emit("change", date);
        return;
      } else if (!date) {
        this.$emit("change", "");
        return;
      }
      this.$emit("change", this.setTime(this.timestamp, time));
    },
  },
  watch: {
    time: function (value) {
      // Atualiza o timestamp a partir da data/hora enviada
      this.updateTimestampByTime(value);
    },
    date: function (value) {
      // Atualiza o timestamp a partir da data/hora enviada
      this.updateTimestampByDate(value);
    },
    timestamp: function (value) {
      // Atualiza os atributos time e date com o novo timestamp.
      this.updateDateTime(value);
    },
  },
  created() {
    this.updateDateTime(this.timestamp);
  },
};
</script>