<script>
import { uploadPhoto, update, store } from "@/services/products/products";

export default {
    data() {
        return {
            loading: false,
            loadingProgressionValue: 0,
            loadingProgressionText: this.$capitalize(this.$tc('model.products_loading_alert'))
        }
    },

    methods: {
        async updateProduct(event) {
            const leadNumber = this.$route.params.lead_number;
            const imagesToUpload = event.imagesToUpload;
            const productId = event.product.id;

            // Loading
            const totalSteps = imagesToUpload.length + 1; //Images and saving product data
            const stepValue = 100/totalSteps;

            this.loading = true;
            try {
                this.setProgressionValue(0);
                this.loadingProgressionText =  this.$capitalize(this.$tc('model.products_saving_change_product_alert'));

                await update(productId, leadNumber, {
                    product: event.product
                })
                this.addProgressionValue(stepValue);
                await this.uploadAllPhotos(imagesToUpload, productId, leadNumber, stepValue);
                this.setProgressionValue(100);
                this.loadingProgressionText = this.$capitalize(this.$tc('model.products_saved_product_alert'));
                await this.delay(1000);
                this.$router.push({
                    name: 'ProductPageData',
                    params: {
                        product_id: productId
                    }
                });
            } catch(error) {
                console.error(error)
                if(error.response.status == 422) {
                    this.$refs.productForm.setErrors(error.response.data.errors);
                } else {
                    this.$store.dispath('error', this.$capitalize(this.$tc('model.products_unable_update_product')));
                }
            } finally {
                this.loading = false;
            }
        },
        async createProduct(event) {
            const leadNumber = this.$route.params.lead_number;
            const imagesToUpload = event.imagesToUpload;

            // Loading
            const totalSteps = imagesToUpload.length + 1; //Images and saving product data
            const stepValue = 100/totalSteps;

            this.loading = true;
            try {
                this.setProgressionValue(0);
                this.loadingProgressionText = this.$capitalize(this.$tc('model.products_saving_product_data_alert'));

                // Product Data
                const data = await store(leadNumber, {
                    product: event.product
                });

                const productId = data.id;
                this.addProgressionValue(stepValue);

                // Images
                await this.uploadAllPhotos(imagesToUpload, productId, leadNumber, stepValue);

                this.setProgressionValue(100);
                this.loadingProgressionText = this.$capitalize(this.$tc('model.products_saved_product_alert'));
                await this.delay(1000);
                this.$router.push({
                    name: 'ProductPageData',
                    params: {
                        product_id: productId
                    }
                });
            } catch(error) {
                console.error(error);
                if(error.response.status == 422) {
                    this.$refs.productForm.setErrors(error.response.data.errors);
                } else {
                    this.$store.dispath('error', this.$capitalize(this.$tc('model.products_unable_update_product')));
                }
            } finally {
                this.loading = false;
            }
        },
        async uploadAllPhotos(imagesToUpload, productId, leadNumber, stepValue) {
            let uploadCount = 0;
            for (const image of imagesToUpload) {
                uploadCount++;
                this.loadingProgressionText = `A fazer upload da fotografia ${uploadCount} de ${imagesToUpload.length}`
                await this.uploadFile(image, productId, leadNumber);
                await this.delay(500);
                this.addProgressionValue(stepValue);
            }
        },
        delay(delay) {
            return new Promise(resolve => setTimeout(resolve, delay));
        },
        addProgressionValue(value) {
            this.setProgressionValue(this.loadingProgressionValue + value);
        },
        setProgressionValue(value) {
            this.loadingProgressionValue = value;
        },
        uploadFile(file, productId, leadNumber) {
            const data = new FormData();
            data.append("attachment", file);

            return uploadPhoto(leadNumber, productId, data);
        },
    }
}
</script>