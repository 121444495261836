import Vue from "vue";

export async function index(lead_number) {
  const response = await Vue.prototype.$http.get(
    `leads/${lead_number}/established_budgets`
  );

  return response.data;
}

export async function indexBudgetsByCoverages(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/established_budgets_by_coverage`
  );

  return response.data;
}

export async function indexBudgetsTotals(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/established_budget_totals`
  );

  return response.data;
}

export async function indexBudgets(participant_id) {
  const response = await Vue.prototype.$http.get(
    `participants/${participant_id}/established_budgets`
  );

  return response.data;
}

export async function indexBudgetTypes() {
  const response = await Vue.prototype.$http.get("budget_types");

  return response.data;
}

export async function indexBudgetObjectives() {
  const response = await Vue.prototype.$http.get("budget_objectives");

  return response.data;
}

export async function store(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/established_budgets`,
    payload
  );

  return response.data;
}

export async function update(lead_number, budget_id, payload) {
  const response = await Vue.prototype.$http.put(
    `leads/${lead_number}/established_budgets/${budget_id}`,
    payload
  );

  return response.data;
}

export async function approve(participant_id, budget_id, approved) {
  const response = await Vue.prototype.$http.put(
    `participants/${participant_id}/established_budgets/${budget_id}`,
    {
      budget: {
        approved
      }
    }
  );

  return response.data;
}

export async function destroy(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/established_budgets/destroy`,
    payload
  );

  return response;
}