import router from "@/router";
import {
  getLanguages,
  setLanguage
} from "@/services/localizations";

export default {
  namespaced: true,
  state: {
    languages: [],
    changing_language: false
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    },
    isChangingLanguage(state) {
      return state.changing_country;
    }
  },
  actions: {
    async loadLanguages({ state }) {
      if (state.languages.length == 0) {
        try {
          const languages = await getLanguages();
          state.languages = languages ? languages : [];
        } catch (error) {
          console.error("erro ao buscar idiomas", error);
        }
      } else {
        return false;
      }
    },
    async changeLanguage(context, language) {
      context.state.changing_language = true;
      try {
        await setLanguage(language);
        context.dispatch("loadI18NConfig");
        router.go();
      } catch (e) {
        context.commit(
          "sendMessage",
          {
            text: e.response.data.message,
            color: "red"
          },
          { root: true }
        );
      } finally {
        context.state.changing_language = false;
      }
    }
  }
};
