<template>
  <v-avatar
    :color="getRandomColor()"
    :size="size"
    v-if="user_name"
    :title="user_name"
    v-bind="$attrs"
  >
    <v-img
      v-if="avatar_url && image_error == false"
      :src="avatar_url"
      :alt="user_name"
      @error="image_error = true"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
            :size="size - 50 * (size / 100)"
            width="2"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!-- <v-icon
      :size="size - 50 * (size / 100)"
      color="#f1f1f1"
      v-else-if="image_error"
      >mdi-image-off-outline</v-icon
    > -->
    <span
      v-else
      class="white--text"
      :style="{ 'font-size': (font_size ? font_size : 12) + 'px' }"
      >{{ getNameLetters(user_name) }}</span
    >
  </v-avatar>
  <v-avatar color="#ccc" :size="size" v-else-if="!user_name">
    <v-icon :size="size - 50 * (size / 100)" color="#333">mdi-help</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: "user-avatar",
  props: ["avatar_url", "user_name", "size", "font_size", "user_id"],
  data: () => ({
    image_error: false,
  }),
  methods: {
    getRandomColor() {
      if (!this.user_id) return "primary";

      const colors = [
        "purple darken-4",
        "pink darken-4",
        "red darken-4",
        "deep-purple darken-4",
        "indigo darken-4",
        "blue darken-4",
        "deep-purple accent-4",
      ];

      const index = this.user_id % colors.length;

      return colors[index];
    },
    getNameLetters(name) {
      const names = name.split(" ");

      const first_letter = names[0][0];
      const last_letter = this.getLastNameInitial(name);

      return (first_letter + last_letter).toUpperCase();
    },
    getLastNameInitial(name) {
      const names = name.split(" ").map((n) => n.trim());
      if (names.length == 1 || !!!names[1]) return "";

      const last_name = names.reverse().find((n) => !!n);

      return last_name[0];
    },
  },
};
</script>