import { index as indexRoomTypes } from "@/services/room_types";

export default {
  namespaced: true,
  state: {
    state: "loading", //loading,not_found,finished,error,active
    snackbar: false,
    not_found: true,
    finished: false,
    form: {},
    images: [],
    header: {},
    task: {},
    new_participant: {},
    new_participant_loading: false,
    photo_dialog: false,
    room_types: [],
    form_image: {},
    form_image_submitting: false,
    total_uploaded: 0,
    show_image_url: null,
    show_image: false,
    fails: [],
    loading_finish: false,
    coverages_dialog: false,
    policy_coverages_filter: "",
    research_date: "",
    research_time: ""
  },
  getters: {},
  mutations: {},
  actions: {
    async getTask({ state, actions }) {
      state.state = "loading";
      try {
        const response = await state.$http.get(`/visit_reports/${state.id}`);
        state.state =
          response.data.task.finished === true ? "finished" : "active";
        state.form = response.data.form;
        state.header = response.data.header;
        state.task = response.data.task;
        state.images = response.data.images;

        if (state.form.research_date) {
          const date_parts = state.form.research_date.split(" ");

          state.research_date = date_parts[0];
          state.research_time = date_parts[1];
        }
        actions.dispatch("getRoomTypes");
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 404:
              state.state = "not_found";
              break;
            default:
              state.state = "error";
              break;
          }
        } else {
          state.state = "error";
        }
      }
    }
  },
  async getRoomTypes({ state }) {
    const response = await indexRoomTypes();
    state.room_types = response;
  }
};
