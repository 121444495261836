import budgetTool from './budget'

function getItemTotal(item) {
    return parseFloat(item.unit_price * item.quantity);
}

function getSubtotal(budget) {
    let total = 0;
    if (!budget) return total;

    budget.external_items.forEach(item => {
        total += getItemTotal(item);
    });

    return total;
}

function getTotal(budget) {
    if(!budget) return 0

    const subtotal = getSubtotal(budget)
    const depreciation = budgetTool.getDepreciation(budget.depreciation_percentage, subtotal)
    const subtotal_with_depreciation = subtotal - depreciation;

    return subtotal_with_depreciation
}

export default {
    getItemTotal,
    getSubtotal,
    getTotal,
}