<template>
  <v-select
    :label="$capitalize($tc('model.products_budget_type'))"
    v-bind="$attrs"
    :items="items"
    @input="handleInput"
    :value="value"
    @focus="getItems"
    item-text="description"
    item-value="id"
    :loading="loading"
  >
  </v-select>
</template>

<script>
import { index } from "@/services/budget_objectives";

export default {
  props: {
    value: Number
  },

  data() {
    return {
      items: [],
      loading: false
    };
  },

  watch: {
    value(value) {
      if (value && !this.items.length) {
        this.getItems();
      }
    }
  },

  methods: {
    handleInput(event) {
      this.$emit("input", event);
    },
    async getItems() {
      this.loading = true;
      try {
        if (this.items.length) return;

        this.items = await index();
      } catch (error) {
        this.$store.commit(
          "error",
          this.$capitalize(this.$tc('model.products_no_load_budget_type'))
        );
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
