import store from "@/store";

export default function checkMaster(to, from, next) {
  if (store.getters["auth/getIsMaster"]) {
    next();
  } else if (store.getters["auth/getUser"]) {
    store.commit("sendMessage", {
      text: "Você não pode acessar este conteúdo",
      color: "red"
    });
    next(from.path);
  } else {
    next("/login");
  }
}