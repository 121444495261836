import { show, destroy, getProductBudgets } from "@/services/products/products";

export default {
  namespaced: true,

  state: {
      product: null,
      compensations: [],
      reparations: [],
      substituitions: []
  },

  getters: {
      getProduct (state) {
        return state.product;
      },
      getCompensations (state) {
        return state.compensations.sort((a, b) => {
          if(a.id < b.id) return 1;
          if(a.id > b.id) return -1;
          return 0;
        });
      },
      getCompensationByVersion: (state) => (version) => {
        if(state.compensations.length === 0) return null;

        return state.compensations.find(compensation => compensation.version == version);
      },
      getReparations(state) {
        return state.reparations.sort((a, b) => {
          if(a.id < b.id) return 1;
          if(a.id > b.id) return -1;
          return 0;
        });
      },
      getReparationByVersion: (state) => (version) => {
        if(state.reparations.length === 0) return null;

        return state.reparations.find(compensation => compensation.version == version);
      },
      getSubstituitions(state) {
        return state.substituitions.sort((a, b) => {
          if(a.id < b.id) return 1;
          if(a.id > b.id) return -1;
          return 0;
        });
      },
      getSubstituitionByVersion: (state) => (version) => {
        if(state.substituitions.length === 0) return null;

        return state.substituitions.find(substituition => substituition.version == version);
      },
  },
  
  mutations: {
    setProduct (state, product) {
        state.product = { ...product };
    },
    setCompensations (state, compensations) {
      state.compensations = compensations;
    },
    addCompensation (state, compensation) {
      state.compensations.push(compensation);
    },
    setReparations(state, reparations) {
      state.reparations = reparations
    },
    addReparation(state, reparation) {
      state.reparation.push(reparation)
    },
    setSubstituitions(state, substituitions) {
      state.substituitions = substituitions
    },
    addSubstituition(state, substituition) {
      state.substituitions.push(substituition)
    },
    clear(state) {
      state.product = null;
      state.compensations = [];
    },
    setLastCompensation(state, payload) {
      const lastCompensation = state.product.budgets.find(budget => budget.objective.canonical == 'compensation');
      
      if (lastCompensation) {
        state.product.budgets.map(budget => {
          if (budget.objective.canonical == 'compensation') {
            return payload;
          }

          return budget;
        })
      } else {
        state.product.budgets.push(payload);
      }
    },
    setLastReparation(state, payload) {
      const lastReparation = state.product.budgets.find(budget => budget.objective.canonical == 'reparation');
      
      if (lastReparation) {
        state.product.budgets.map(budget => {
          if (budget.objective.canonical == 'reparation') {
            return payload;
          }

          return budget;
        })
      } else {
        state.product.budgets.push(payload);
      }
    },
    setLastSubstituition(state, payload) {
      const lastSubstituition = state.product.budgets.find(budget => budget.objective.canonical == 'substituition');
      
      if (lastSubstituition) {
        state.product.budgets.map(budget => {
          if (budget.objective.canonical == 'substituition') {
            return payload;
          }

          return budget;
        })
      } else {
        state.product.budgets.push(payload);
      }
    },
  },

  actions: {
      async loadProduct ({ commit }, payload) {
        const { productId, leadNumber }  = payload;
        commit('clear', null);

        const response = await show(productId, leadNumber);
        commit('setProduct', response);
      },
      async loadCompensations({ commit }, payload) {
        const { productId } = payload;
        const response = await getProductBudgets(productId, {
          objective: 'compensation'
        });

        commit('setCompensations', response);
      },
      async loadReparations({ commit }, payload) {
        const { productId } = payload;
        const response = await getProductBudgets(productId, {
          objective: 'reparation'
        })

        commit('setReparations', response)
      },
      async loadSubstituitions({ commit }, payload) {
        const { productId } = payload;
        const response = await getProductBudgets(productId, {
          objective: 'substituition'
        })

        commit('setSubstituitions', response)
      }
  }
};