<style lang="scss">
.substituition-budget-item {
  .field {
    background-color: white;
  }
}
</style>

<template>
  <div class="substituition-budget-item">
    <v-card color="grey lighten-3" outlined>
      <ValidationObserver ref="form" v-slot="{ errors, failed }">
        <v-form @submit.prevent="handleChange">
          <v-card-text>
            <FormAlertValidation :errors="errors" :visible="failed" />
            <v-row dense>
              <v-col cols="12">
                <ValidationProvider
                  name="proposed_product_name"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :value="value.proposed_product_name"
                    @input="handleChange('proposed_product_name', $event)"
                    :label="$capitalize($tc('model.products_name_product'))"
                    hide-details
                    outlined
                    :error-messages="errors"
                    class="field"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  name="store_url"
                  :rules="{
                    required: true,
                    regex:
                      '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})',
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    @input="handleChange('store_url', $event)"
                    :value="value.store_url"
                    name="store_url"
                    class="field"
                    outlined
                    :label="$capitalize($tc('model.products_ad_url'))"
                    placeholder="Ex.: https://worten.pt/..."
                    hide-details
                    :counter="45"
                    :error-messages="errors"
                  >
                    <template v-slot:append="">
                      <v-btn
                        :disabled="!value.store_url"
                        :href="value.store_url"
                        target="_blank"
                        icon
                        style="margin-top: -7px"
                        color="info"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  name="store"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="store"
                    :label="$capitalize($tc('model.productbudget_marketplace'))"
                    @input="handleChange('store', $event)"
                    :value="value.store"
                    class="field"
                    outlined
                    :placeholder="$capitalize($tc('model.products_marketplace_placeholder'))"
                    hide-details
                    :counter="45"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="5">
                <ValidationProvider name="unit_price" v-slot="{ errors }">
                  <MoneyInput
                    @input="handleChange('unit_price', $event)"
                    name="unit_price"
                    :value="value.unit_price"
                    class="field"
                    outlined
                    :label="$capitalize($tc('model.products_value'))"
                    hide-details
                    :error-messages="errors"
                  ></MoneyInput>
                </ValidationProvider>
              </v-col>
              <v-col cols="3">
                <ValidationProvider
                  name="quantity"
                  rules="required"
                  v-slot="{ errors }"
                  mode="passive"
                >
                  <v-text-field
                    name="quantity"
                    @change="handleChangeQty"
                    :value="value.quantity"
                    class="field"
                    outlined
                    hide-details
                    :label="$capitalize($tc('model.products_amount_simple_table'))"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  name="coverage_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    @input="handleChange('coverage_id', $event)"
                    :value="value.coverage_id"
                    :items="coverageOptions"
                    item-value="id"
                    item-text="type_description"
                    class="field"
                    :label="$capitalize($tc('model.damages_coverage'))"
                    rows="3"
                    outlined
                    hide-details
                    :error-messages="errors"
                    name="coverage_id"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <!-- <v-col>
                <ValidationProvider
                  name="proposed_product_image"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-file-input
                    name="proposed_product_image"
                    label="Screenshot"
                    class="field"
                    hide-details
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="mdi-image"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="handleChange('proposed_product_image', $event)"
                    :value="value.proposed_product_image"
                    :error-messages="errors"
                  ></v-file-input>
                </ValidationProvider>
              </v-col> -->
            </v-row>
            <v-row class="subtitle-2" no-gutters>
              <v-col> {{ $capitalize($tc('model.products_total_subtitle')) }}: {{ euro(total) }} </v-col>
              <v-col class="text-right">
                <v-btn
                  small
                  @click="handleDelete()"
                  icon
                  :disabled="!deletable"
                >
                  <v-icon small>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>

<script>
import Formatter from "@/tools/money";
import MoneyInput from "@/components/MoneyInput";
import FormAlertValidation from "@/components/FormAlertValidation";

export default {
  components: {
    MoneyInput,
    FormAlertValidation
  },

  props: {
    coverages: Array,
    value: Object,
    deletable: {
      type: Boolean,
      default: true
    },
    units: Array
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      euro: Formatter.euro,
      form: {
        quantity: "1,0",
        value: 0
      }
    };
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    clear() {
      this.$emit("change", {
        quantity: "1,0",
        value: 0
      });

      this.$refs.form.reset();
    },
    handleDelete() {
      this.$emit("delete");
    },
    handleChangeQty(value) {
      value = String(value).replace(".", ",");

      if (value.indexOf(",") == -1) {
        value = `${value},0`;
      }

      this.handleChange("quantity", value);
    },
    handleChange(item, value) {
      // Prepara a mudança no state
      const newValue = {
        ...this.value,
        [item]: value
      };

      this.$emit("change", {
        ...newValue,
        total: this.calculateTotal(newValue) //Calcula total com base no novo state
      });
    },
    calculateTotal(value) {
      const qty = parseFloat(String(value?.quantity).replace(",", ".")) || 0;
      const price = parseFloat(value?.unit_price) || 0;

      return price * qty;
    }
  },

  watch: {
    // Quando houver somente uma opção, selecioná-la automáticamente
    coverageOptions(value) {
      if (value.length == 1) {
        this.handleChange("coverage_id", value[0].id);
      }
    }
  },

  computed: {
    total() {
      return this.calculateTotal(this.value);
    },
    coverageOptions() {
      if (!this.coverages) return [];

      return this.coverages.filter(coverage => coverage.selected == true);
    },
    isInvalid() {
      return true;
    }
  }
};
</script>
