export default {
  namespaced: true,
  state: {
    task_form: {
      current_analysis_tasks: null,
      current_research_tasks: null,
      current_construction_tasks: null,
      previous_analysis_tasks: [],
      previous_research_tasks: [],
      previous_construction_tasks: []
    }
  },
  mutations: {
  },
  actions: {
    loadTasks({ state }, value) {
      state.task_form.current_analysis_tasks = value.current_analysis_tasks;
      state.task_form.current_research_tasks = value.current_research_tasks;
      state.task_form.current_construction_tasks = value.current_construction_tasks;
      state.task_form.previous_analysis_tasks = value.previous_analysis_tasks;
      state.task_form.previous_research_tasks = value.previous_research_tasks;
      state.task_form.previous_construction_tasks = value.previous_construction_tasks;
    },
    clearTasks({ state }) {
      state.task_form.current_analysis_tasks = [];
      state.task_form.current_research_tasks = [];
      state.task_form.current_construction_tasks = [];
      state.task_form.previous_analysis_tasks = [];
      state.task_form.previous_research_tasks = [];
      state.task_form.previous_construction_tasks = [];  
    },
    updateTaskFromList({ state }, [task_id, attribute, value]) {
      state.task_form[attribute] = state.task_form[attribute].map(task => {
        if (task.id == task_id)
          return value;

        return task;
      });
    }
  }
};
