import Vue from "vue";

export async function index(params = {}, advanced_filters) {
  const response = await Vue.prototype.$http.get("/lead_notifications", {
    params: {
      ...params,
      ...advanced_filters
    }
  });

  return response.data;
}

export async function indexByLead(lead_number) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_number}/notifications`);

  return response.data;
}

export async function indexByLeadUnread(lead_number) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_number}/notifications/unread`);

  return response.data;
}

export async function indexByLeadRead(lead_number) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_number}/notifications/read`);

  return response.data;
}

export async function dismiss(notification_id) {
  const response = await Vue.prototype.$http.put(`/lead_notifications/${notification_id}/dismiss`);

  return response.data;
}

export async function dismissByLeadAndType(lead_number, type) {
  const response = await Vue.prototype.$http.put(`/lead_notifications/dismiss_lead_type`, {
    lead_number,
    type
  });

  return response.data;
}

export async function dismissByLead(lead_number) {
  const response = await Vue.prototype.$http.post(`/leads/${lead_number}/notifications/dismiss`);

  return response.data;
}

export async function markAsUnread(notification_id) {
  const response = await Vue.prototype.$http.put(`/lead_notifications/${notification_id}/mark_unread`);

  return response.data;
}

export async function getAnalytics() {
  const response = await Vue.prototype.$http.get(`/lead_notifications/analytics`);

  return response.data;
}

export function getRoute(lead_number, notification_type) {
  switch(notification_type) {
    case 'insurer_new_attachment':
      return getNewAttachmentRoute(lead_number);
    case 'user_new_attachment':
      return getNewAttachmentRoute(lead_number);
    case 'unity_new_attachment':
      return getNewAttachmentRoute(lead_number);
    case 'insurer_new_comment':
      return getNewCommentRoute(lead_number);
    default:
      return null;
  }
}

function getNewAttachmentRoute(lead_number) {
  return {
    name: 'lead_detail_files',
    params: { lead_number }
  };
}

function getNewCommentRoute(lead_number) {
  return {
    name: 'lead_detail_comments',
    params: { lead_number }
  };
}