<script>
import { update, destroy } from "@/services/reminders";

export default {
  data() {
    return {
      deleting_reminder: false,
      saving_reminder: false,
    };
  },

  methods: {
    async createReminder(fnCreate, fnUpdate) {
      this.saving_reminder = true;
      try {
        const response = await fnCreate();

        fnUpdate(response);
        this.$store.dispatch("reminder/loadActiveReminders");
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao salvar lembrete",
          color: "red",
        });
      } finally {
        this.saving_reminder = false;
      }
    },
    async updateReminder(reminder_id, payload, fnUpdate) {
      this.saving_reminder = true;

      try {
        const response = await update(reminder_id, payload);

        if (fnUpdate) fnUpdate(response);

        this.$store.dispatch("reminder/loadActiveReminders");
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(
            this.$tc("model.reminder_failed_save_message")
          ),
          color: "red",
        });
      } finally {
        this.saving_reminder = false;
      }
    },
    async deleteReminder(reminder_id, fnUpdate) {
      this.deleting_reminder = true;

      try {
        const response = await destroy(reminder_id);

        if (fnUpdate) fnUpdate(response);

        this.$store.dispatch("reminder/loadActiveReminders");
      } catch (error) {
        console.error(error);

        if (error.response?.status === 404) {
          // Se não foi encontrado, remove do client
          this.$store.commit("reminder/removeActiveReminder", reminder_id);
          return;
        }

        this.$store.commit("sendMessage", {
          text: this.$capitalize(
            this.$tc("model.reminder_failed_remove_message")
          ),
          color: "red",
        });
      } finally {
        this.deleting_reminder = false;
      }
    },
  },
};
</script>
