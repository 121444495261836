import Vue from "vue";


export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/forfaits", {
    params
  });

  return response.data;
}
