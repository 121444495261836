<template>
  <v-menu v-model="menu" offset-y bottom left :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon v-if="!menu">mdi-video-outline</v-icon>
        <v-icon v-else>mdi-video</v-icon>
      </v-btn>
    </template>

    <v-list style="max-height: 80vh; overflow-y: auto" width="350">
      <v-subheader> CHAMADAS ABERTAS </v-subheader>
      <div v-if="!loading">
        <v-list-item v-for="video_call in video_calls" :key="video_call.key">
          <v-list-item-content>
            <v-list-item-title>
              {{ video_call.lead.identifier_code }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ video_call.participant.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon small>mdi-phone</v-icon>
              {{ video_call.starts_at_diff }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div>
              <v-btn
                @click="handleFinish(video_call)"
                icon
                color="red"
                title="Encerrar"
              >
                <v-icon>mdi-phone-hangup</v-icon>
              </v-btn>
              <v-btn
                :href="video_call.perit_link"
                target="blank"
                icon
                title="Retornar para chamada"
              >
                <v-icon>mdi-phone-forward</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </div>
      <div
        v-if="video_calls.length === 0 && loading === false"
        class="text-center my-5"
      >
        <v-icon large color="grey">mdi-video-box-off</v-icon> <br />
        <span class="mt-2 grey--text">Nenhuma videochamada encontrada</span>
      </div>
      <div v-else-if="loading">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { indexMine, finish } from "@/services/video_calls/video_calls";

export default {
  data() {
    return {
      video_calls: [],
      loading: false,
      menu: false,
    };
  },

  methods: {
    async getVideoCalls() {
      this.loading = true;
      try {
        const { data } = await indexMine();
        this.video_calls = data;
      } finally {
        this.loading = false;
      }
    },
    async handleFinish(video_call) {
      try {
        const { data } = await finish(video_call.key);

        this.video_calls = this.video_calls.filter((item) => {
          if (item.key !== video_call.key) {
            return data;
          }
        });
      } catch (error) {
        if (error.response?.data?.message) {
          this.$store.commit("error", error.response.data.message);
        }
      }
    },
  },

  watch: {
    menu(value) {
      if (value) {
        this.getVideoCalls();
      }
    },
  },
};
</script>
