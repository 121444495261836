import { getSchedulingObjectives } from "@/services/scheduling";

export default {
  namespaced: true,
  state: {
    scheduling_objectives: []
  },
  getters: {
    getSchedulingObjectives(state) {
      return state.scheduling_objectives;
    }
  },
  mutations: {},
  actions: {
    async loadSchedulingObjectives({ state }) {
      if (state.scheduling_objectives.length == 0) {
        try {
          const objectives = await getSchedulingObjectives();
          state.scheduling_objectives = objectives ? objectives : [];
        } catch (error) {
          console.error("erro ao buscar objetivos", error);
        }
      } else {
        return false;
      }
    }
  }
};
