import { getAnalytics } from "@/services/lead_notifications";

export default {
  namespaced: true,
  state: {
    unread_lead_notifications: 0,

    lead_notifications_poll: null,
  },
  getters: {
    getUnreadLeadNotifications(state) {
      return state.unread_lead_notifications;
    },
  },
  mutations: {
    SET_UNREAD_LEAD_NOTIFICATIONS(state, unread_lead_notifications) {
      state.unread_lead_notifications = unread_lead_notifications;
    },
    SET_LEAD_NOTIFICATIONS_POLL(state, poll) {
      state.lead_notifications_poll = poll;
    },
    CLEAR_LEAD_NOTIFICATIONS_POLL(state) {
      window.clearInterval(state.poll);
    },
  },
  actions: {
    async loadLeadNotificationsAnalytics({ commit }) {
      try {
        const requests = await getAnalytics();
        commit("SET_UNREAD_LEAD_NOTIFICATIONS", requests.unread_notifications);
      } catch (error) {
        console.error(
          "erro ao buscar analytics das notificações das leads",
          error
        );
      }
    },
    stopLeadNotificationAnalyticsPoll({ commit }) {
      commit("CLEAR_LEAD_NOTIFICATIONS_POLL");
    },
  },
};
