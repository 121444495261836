export default {
    namespaced: true,
    state: {
        lead_schedulings: []
    },
    getters: {
        getLeadSchedulings(state) {
            return state.lead_schedulings
        }
    },
    mutations: {
        setLeadSchedulings(state, payload) {
            state.lead_schedulings = payload;
        }
    }
};
