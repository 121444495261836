import Vue from "vue";

export const lead_types = {
  multirisks: 1,
  riscos_eletricos: 2,
};

export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/lead_types", {
    params,
  });

  return response.data;
}
