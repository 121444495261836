import { showEditLeadMetadata, showCreateLeadMetadata, showImportLeadMetadata } from "@/services/lead";
import { showLeadMetadata } from "@/services/lusitania/robots/jobs";

export default {
  namespaced: true,

  state: {
    insurer: null,
    lead_types: [],
    appraisals: [],
    lead: null,
    validation_errors: [],
    warnings: [],
  },

  getters: {
    getInsurer(state) {
      return state.insurer;
    },
    getLeadTypes(state) {
      return state.lead_types
    },
    getAppraisals(state) {
      return state.appraisals
    },
    getLead(state) {
      return state.lead;
    },
    getLead(state) {
      return state.lead;
    },
    getValidationErrors(state) {
      return state.validation_errors;
    },
    getWarnings(state) {
      return state.warnings;
    },
  },
  
  mutations: {
    setMetadata(state, metadata) {
        state.insurer = metadata.insurer
        state.lead_types = metadata.lead_types;
        state.appraisals = metadata.appraisals;
        state.validation_errors = metadata.validation_errors;
        state.warnings = metadata.warnings;
    },
    clearMetadata(state) {
      state.insurer = null;
      state.lead_types = [];
      state.appraisals = [];
    },

    setLead(state, lead) {
      state.lead = lead;
    },
    clearLead(state) {
      state.insurer = null;
      state.lead_types = [];
      state.appraisals = [];
    },
  },

  actions: {
      async loadCreateLeadMetadata({ commit }, payload) {
        const { insurerId }  = payload;
        commit('clearMetadata', null);

        const response = await showCreateLeadMetadata(insurerId);
        commit('setMetadata', response);
      },
      async loadEditLeadMetadata({ commit }, payload) {
        const { leadNumber }  = payload;
        commit('clearMetadata', null);
        commit('clearLead', null);

        const response = await showEditLeadMetadata(leadNumber);
        commit('setMetadata', response);
        commit('setLead', response.lead);
      },
      async importLeadMetadata({ commit }, payload) {
        const { insurerId, formData }  = payload;
        commit('clearMetadata', null);
        commit('clearLead', null);

        const response = await showImportLeadMetadata(insurerId, formData);
        commit('setMetadata', response);
        commit('setLead', response.lead);
      },
      async importRobotPayload({ commit }, payload) {
        const { jobId }  = payload;
        commit('clearMetadata', null);
        commit('clearLead', null);

        const response = await showLeadMetadata(jobId);
        commit('setMetadata', response);
        commit('setLead', response.lead);
      }
  }
};