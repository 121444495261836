<script>
export default {
  mixins: [],

  components: {},

  props: {},

  data() {
    return {};
  },

  methods: {
    verifyScope(scope) {
      return this.$store.getters["auth/getScope"](scope);
    },

    isMaster() {
      return this.$store.getters["auth/getIsMaster"];
    },
  },

  computed: {},
};
</script>
