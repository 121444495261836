<template>
  <v-list-item>
    <v-list-item-action>
      <v-simple-checkbox :value="value" @input="handleInput(!value)" color="primary"></v-simple-checkbox>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title> {{ __('Indemnização', 'budget') }} </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import i18n from '@/mixins/i18n';
export default {
  mixins: [i18n],
  props: {
    value: Boolean
  },

  methods: {
    handleInput(event) {
      this.$emit("input", event);
    }
  }
};
</script>
