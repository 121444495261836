<template>
  <v-app id="inspire">
    <v-content style="background-color: #f1f1f1">
      <v-container class="fill-height" style="max-width: 500px">
        <v-row align="center" justify="center" no-gutters>
          <transition name="slide-fade" slot="div">
            <v-col>
              <v-card color="#f1f1f1" flat v-if="loading">
                <v-card-text class="text-center">
                  <!-- <h3>Saindo do Sistema...</h3> -->
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="mt-3"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
              <v-card flat v-else-if="error">
                <v-card-title>{{
                  $capitalize($tc("model.auth_logout"))
                }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-alert type="error" text>{{
                    $capitalize($tc("model.auth_failed_logout"))
                  }}</v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="back()" text>{{
                    $capitalize($tc("model.auth_back_button"))
                  }}</v-btn>
                  <v-btn @click="handleLogout()" color="primary">
                    <v-icon left>mdi-refresh</v-icon
                    >{{ $capitalize($tc("model.auth_try_again")) }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </transition>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "logout",
  data: () => ({
    error: true,
  }),
  methods: {
    ...mapActions("auth", ["logout"]),
    async handleLogout() {
      try {
        await this.logout();
      } catch (error) {
      } finally {
        this.$router.replace("/login");
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapState(["loading"]),
  },
  async created() {
    this.handleLogout();
  },
};
</script>
