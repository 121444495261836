import Vue from "vue";


export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/letters", {
    params
  });

  return response.data;
}

export async function send(id) {
  const response = await Vue.prototype.$http.get(`letters/${id}/send`);

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/letters", payload);

  return response.data;
}

export async function printMany(letters) {
  const response = await Vue.prototype.$http.post("/letters/print", {
    letters
  }, { responseType: 'arraybuffer' });

  return response.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete("/letters/" + id);

  return true;
}

export async function indexTypes() {
  const response = await Vue.prototype.$http.get("/letter_types");

  return response.data;
}
