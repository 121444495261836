<style lang="scss" scoped>
.notification-card {
  position: fixed;
  width: 350px;
  bottom: 20px;
  right: 10px;
  z-index: 999;

  .body {
    padding-top: 20px;
    padding-bottom: 30px;

    .border-2 {
      background-color: #f1f1f1;
      width: 160px;
      height: 160px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .border-1 {
      background-color: #dbdbdb;
      width: 120px;
      height: 120px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .border {
      background-color: white;
      width: 80px;
      height: 80px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<template>
  <div v-if="calls.length">
    <v-card v-if="!minimized" class="notification-card" elevation="12">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="title"
            :title="active_call.video_call.participant.name"
          >
            {{ active_call.video_call.participant.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <RouterLink
              :to="`/leads/${active_call.video_call.lead.identifier_code}`"
              style="text-decoration: none"
            >
              {{ active_call.video_call.lead.identifier_code }}
            </RouterLink>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon small>mdi-calendar</v-icon>
            {{ active_call.video_call.starts_at }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click="handleMinimize()" elevation="0" icon>
            <v-icon left>mdi-chevron-down</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-card-text class="body">
        <div class="text-center">
          <div class="border-2">
            <div class="border-1">
              <div class="border">
                <v-icon size="100" color="grey">mdi-account-circle</v-icon>
              </div>
            </div>
          </div>
          <div class="mt-3 font-weight-bold">
            {{ timer }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="handleRefuse" elevation="0" text>
          <v-icon left>mdi-phone-hangup</v-icon> Recusar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn @click="handleAnswer" elevation="0" color="green" dark>
          <v-icon left>mdi-phone</v-icon> Atender
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-btn
      color="primary"
      @click="handleMaximize()"
      fab
      v-else
      fixed
      right
      bottom
      elevation="10"
    >
      <v-badge color="red" :value="calls.length" :content="timer" large left>
        <v-icon>mdi-phone-in-talk</v-icon>
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
import {
  indexMine,
  answer,
  refuse,
} from "@/services/video_calls/video_call_attempts";

export default {
  data() {
    return {
      minimized: false,
      startTime: new Date(),
      timer: "00:00",
      counter: null,
    };
  },

  methods: {
    async handleRefuse() {
      await refuse(this.calls[0].id);
      this.$store.commit("video_calls/removeAttempt", this.calls[0]);

      this.$store.commit("alert", "Chamada recusada");
    },
    async handleAnswer() {
      const { data } = await answer(this.calls[0].id);
      const route = this.$router.resolve({
        name: "video_call_perit_page",
        params: { key: data.video_call_key },
      });

      window.open(route.href, "_blank");

      this.$store.commit("video_calls/removeAttempt", this.calls[0]);
    },
    handleMinimize() {
      this.minimized = true;
    },
    handleMaximize() {
      this.minimized = false;
    },
    elapsedTime() {
      const currentTime = new Date(); // Data/hora atual
      const timeDiff =
        currentTime -
        new Date(this.calls[0] ? this.calls[0].started_at : new Date()); // Diferença entre o tempo atual e o tempo inicial em milissegundos

      // Calcula as horas, minutos e segundos a partir da diferença em milissegundos
      let minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
      let seconds = Math.floor((timeDiff / 1000) % 60);

      // Formata os valores com dois dígitos, adicionando um zero à esquerda se necessário
      minutes = minutes.toString().padStart(2, "0");
      seconds = seconds.toString().padStart(2, "0");

      return `${minutes}:${seconds}`;
    },
    async getCalls() {
      const { data } = await indexMine();
      this.$store.commit("video_calls/setAttempts", data);
    },
  },
  computed: {
    calls() {
      return this.$store.getters["video_calls/getAttempts"];
    },
    active_call() {
      if (this.calls.length < 1) {
        return null;
      }

      return this.calls[0];
    },
  },
  mounted() {
    // Atualiza o timer a cada segundo
    this.counter = setInterval(() => {
      this.timer = this.elapsedTime();
    }, 1000);
  },
  created() {
    this.getCalls();
  },
  destroyed() {
    clearInterval(this.counter);
  },
};
</script>
