import Vue from "vue";


export async function indexActive() {
  const response = await Vue.prototype.$http.get(
    '/reminders',
    {
      params: {
        active: true,
        list: true
      }
    }
  );

  return response.data;
}

export async function indexPrevious(params) {
  const response = await Vue.prototype.$http.get(
    '/reminders', 
    { 
      params: {
        ...params,
        previous: true
      }
    }
  );

  return response.data
}

export async function update(reminder_id, payload) {
  const response = await Vue.prototype.$http.put(
      `/reminders/${reminder_id}`,
      payload
  );

  return response.data;
}


export async function destroy(reminder_id) {
    const response = await Vue.prototype.$http.delete(
        `/reminders/${reminder_id}`
    );

    return response.data;
}