<template>
  <v-menu
    :value="value"
    @input="$emit('input', $event)"
    v-bind="$attrs"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon>
        <v-badge
          :value="active_reminders.length"
          :content="limitNotification(active_reminders.length)"
          overlap
          color="error"
        >
          <v-icon v-if="value">mdi-bell</v-icon>
          <v-icon v-else>mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card width="500" max-height="500">
      <v-app-bar flat color="white">
        <v-toolbar-title>
          {{ $capitalize($tc("model.reminder_reminders_title")) }}
        </v-toolbar-title>
        <v-spacer />
        <router-link
          class="link"
          :to="{ name: 'reminders' }"
          @click="$emit('input', false)"
        >
          {{ $capitalize($tc("model.reminder_see_all_menu")) }}
        </router-link>
      </v-app-bar>
      <ReminderList id="menu" />
    </v-card>
  </v-menu>
</template>

<script>
import ReminderList from "@/components/ReminderList";
import { mapState } from "vuex";

export default {
  name: "reminder-menu",
  components: { ReminderList },
  props: {
    value: Boolean,
  },
  methods: {
    limitNotification(notificationLength) {
      if (notificationLength > 9) {
        return "+9";
      }

      return notificationLength;
    },
  },
  computed: {
    ...mapState("reminder", ["active_reminders"]),
  },
};
</script>

<style></style>
