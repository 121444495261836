<template>
  <v-dialog
    :value="confirm_action"
    @change="$emit('change', $event)"
    persistent
    width="300px"
  >
    <v-card>
      <v-card-title class="headline">{{
        capitalize($tc("model.interface_question_are_you_sure"))
      }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleCancel"
          :disabled="loading"
          >{{ capitalize($tc("model.interface_no")) }}</v-btn
        >
        <v-btn color="primary" text @click="handleAction" :loading="loading">{{
          capitalize($tc("model.interface_yes"))
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm-action-dialog",
  props: {
    confirm_action: Boolean,
    message: String,
  },
  model: {
    prop: "confirm_action",
    event: "change",
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    async handleAction(payload) {
      this.loading = true;
      await this.$listeners.action();
      this.loading = false;
    },
    handleCancel() {
      this.$emit("change", false);
    },
  },
};
</script>