import Vue from "vue";


export async function show(lead_number, id) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_number}/schedulings/${id}`);

  return response.data;
}

export async function index(lead_number) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_number}/schedulings`);

  return response.data;
}

export async function update(lead_number, id, payload) {
  const response = await Vue.prototype.$http.put(
    `/leads/${lead_number}/schedulings/${id}`,
    payload
  );

  return response.data;
}

export async function create(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_number}/schedulings`,
    payload
  );

  return response.data;
}

export async function destroy(lead_number, id) {
  return await Vue.prototype.$http.delete(`/leads/${lead_number}/schedulings/${id}`);
}

export async function my_schedulings(params) {
  const response = await Vue.prototype.$http.get(`/my_schedulings`, {
      params
  });

  return response.data;
}

export async function getSchedulingObjectives() {
  const response = await Vue.prototype.$http.get(`/scheduling_objectives`);

  return response.data;
}

export async function getSchedulingTypes() {
  const response = await Vue.prototype.$http.get(`/scheduling_types`);

  return response.data;
}

export async function getMessageSugestionToCompany(lead_number) {
  const response = await Vue.prototype.$http.get(`/leads/${lead_number}/schedulings/suggestion_company_message`);

  return response.data;
}