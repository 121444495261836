<template>
  <v-radio-group
    @change="$emit('input', $event)"
    :value="value"
    :label="label"
    :name="name"
    v-bind="$attrs"
  >
    <v-radio
      v-for="item in items"
      :key="item.id"
      :label="`${item.type} (${euro(item.value)})`"
      :value="item.id"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import Formatter from "@/tools/money";

export default {
  props: {
    value: Number,
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: function() { return this.$capitalize(this.$tc('model.products_insurance_object')); }
    },
    name: {
      type: String,
      default: "insurance_object"
    }
  },

  data() {
    return {
      euro: Formatter.euro
    };
  },

  methods: {}
};
</script>
