import budgetTool from './budget'

function getItemTotal(item){
    return parseFloat(item.unit_price * item.quantity);
}

function getSubtotal(budget){
    let total = 0;
    if (!budget) return total;

    budget.replace_items.forEach(item => {
        total += getItemTotal(item);
    });

    return total;
}

function getTotal(budget){
    if (!budget) return 0;

    const subtotal = getSubtotal(budget)
    const depreciation = budgetTool.getDepreciation(budget.depreciation_percentage, subtotal)
    const subtotal_with_depreciation = subtotal - depreciation;

    const additional_value = budgetTool.getAdditional(budget.additional_value, subtotal_with_depreciation)
    const subtotal_with_additional = subtotal_with_depreciation + additional_value

    const vat = budgetTool.getVatValue(budget.vat_percentage, subtotal_with_additional)

    return subtotal_with_additional + vat
}

export default {
    getItemTotal,
    getSubtotal,
    getTotal,
}