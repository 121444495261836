// Middlewares
import checkScope from "@/middlewares/scope";

// Pages
import ProductReparationPage from "@/views/ProductReparationPage";
import ProductNewReparationPage from "@/views/ProductNewReparationPage";
import ProductReparationDataPage from "@/views/ProductReparationDataPage";

/**
 * @path /leads/:lead_number/products/:product_id
 */
export default [
    {
        path: "reparations",
        beforeEnter: checkScope("leads.read"),
        component: ProductReparationPage,
        children: [
            {
                path: "/",
                name: 'ProductReparationsPage',
                redirect: { name: "ProductReparationDataLatest" }
            },
            {
                path: "new",
                name: "ProductNewReparation",
                component: ProductNewReparationPage
            },
            {
                path: "latest",
                name: "ProductReparationDataLatest",
                component: ProductReparationDataPage
            },
            {
                path: ":version",
                name: "ProductReparationData",
                component: ProductReparationDataPage
            },
        ]
    },
];