<template>
  <div>
    <v-text-field
      @click="handleFocus"
      label="Tipo"
      :value="categoryName"
      readonly
    >
      <template #append>
        <v-btn @click="openDialog()" icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <v-dialog v-model="dialog" width="400" persistent scrollable>
      <v-card height="500">
        <v-card-title>
          Escolha o tipo do produto
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="mt-0">
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            @input="handleInputSearch"
            @update="getCategories()"
            @keyup.enter.stop="getCategories(search)"
            label="Pesquisar"
            autofocus
            solo
            hide-details
            clearable
          ></v-text-field>
        </v-card-subtitle>
        <v-card-text>
          <v-skeleton-loader
            v-if="loading"
            type="list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item"
          ></v-skeleton-loader>
          <v-radio-group v-model="form">
            <v-radio
              v-for="product_type in product_types"
              :key="product_type.id"
              :label="product_type.name"
              :value="product_type.id"
            ></v-radio>
          </v-radio-group>
          <div
            v-if="!loading && product_types.length === 0"
            class="text-center mt-2"
          >
            Nenhum tipo encontrado
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" text>Cancelar</v-btn>
          <v-btn @click="handleSubmit" color="primary">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { search } from "@/services/product_types";
import ToastsMixin from "@/mixins/ToastsMixin.vue";

export default {
  mixins: [ToastsMixin],

  props: {
    value: {
      default: () => ({}),
    },
    name: String,
    errorMessages: Array,
  },

  model: {
    event: "change",
    prop: "value",
  },

  data() {
    return {
      product_types: [],
      loading: false,
      search: "",
      dialog: false,
      form: null,
    };
  },

  methods: {
    handleSubmit() {
      this.$emit(
        "change",
        this.product_types.find((item) => item.id === this.form)
      );
      this.closeDialog();
    },

    handleFocus() {
      this.openDialog();
    },

    async getCategories(searchText = "") {
      this.loading = true;
      this.product_types = [];
      try {
        this.product_types = await search(searchText);
        this.search = searchText;
      } catch (error) {
        console.log(error);
        this.toastError("Não foi possível carregar os tipos de produtos");
        this.closeDialog();
      } finally {
        this.loading = false;
      }
    },

    handleInputSearch(searchText) {
      if (!searchText) {
        this.getCategories("");
      }
    },

    openDialog() {
      this.dialog = true;
      this.getCategories();

      if (this.value) {
        this.form = this.value.id;
      }
    },

    closeDialog() {
      this.dialog = false;
    },
  },

  computed: {
    categoryName() {
      if (!this.value) {
        return "";
      }

      return this.value.name;
    },
  },
};
</script>
