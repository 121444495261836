<template>
  <v-alert v-if="visible" type="error" dense v-bind="$attrs">
    <div class="mb-2 mt-1">
      <strong>{{ title }}</strong>
    </div>
    <ul>
      <li v-for="(field, index) in chunkedErrors" :key="index">
        {{ index }}
        <ul>
          <li v-for="error in field" :key="`${index}_${error}`">
            {{ error }}
          </li>
        </ul>
      </li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: function () {
        return this.$capitalize(this.$tc("model.products_validations"));
      },
    },
    errors: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    chunkedErrors() {
      const errors = {};

      Object.keys(this.errors).forEach((error) => {
        if (this.errors[error].length > 0) {
          errors[error] = this.errors[error];
        }
      });

      return errors;
    },
  },
};
</script>
