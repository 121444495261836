<template>
  <div>
    <div v-if="compensation">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $capitalize($tc('model.products_budget_title')) }}
              <v-chip class="ml-2" small
                ><v-icon small left>mdi-tag</v-icon>
                {{ compensation.version }}</v-chip
              >
            </v-card-title>
            <v-card-text>
              <v-subheader>
                {{ $capitalize($tc('model.products_items_card')) }} 
              </v-subheader>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>{{ $capitalize($tc('model.products_item_simple_table')) }}</th>
                    <th>{{ $capitalize($tc('model.products_amount_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_unitary_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_total_title')) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in compensation.external_items"
                    :key="item.id"
                  >
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td class="text-right">
                      {{ euro(item.unit_price) }}
                    </td>
                    <td class="text-right">
                      {{ euro(getItemTotal(item)) }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="3" class="text-right subtitle-2">
                      {{ $capitalize($tc('model.products_total_title')) }}
                    </th>
                    <th class="subtitle-2 text-right">
                      {{ euro(getSubTotal()) }}
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-row class="mt-10">
                <v-col>
                  <v-subheader>{{ $capitalize($tc('model.marketplace_details')) }}</v-subheader>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_type_label')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{
                          compensation.external_objective
                            ? compensation.external_objective.description
                            : "---"
                        }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_repairman_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{
                          compensation.issuer ? compensation.issuer.name : "---"
                        }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_attachment_reference')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          @click="previewFile(compensation.external_document)"
                        >
                          {{
                            compensation.external_document
                              ? compensation.external_document.name
                              : "---"
                          }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc("model.products_sent_by")) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{
                          compensation.sender
                            ? compensation.sender.contact.name
                            : "---"
                        }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.photographic_report_created_on_colunm_table')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ formatDateTime(compensation.created_at) }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_created_by_colunm_table')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip>
                          <UserAvatar
                            :avatar_url="compensation.created_by.avatar_url"
                            :user_name="compensation.created_by.name"
                            :user_id="compensation.created_by.id"
                            left
                          />
                          {{ compensation.created_by.name }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-subheader>{{ $capitalize($tc('model.products_totalizers_subheader')) }}</v-subheader>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Subtotal
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ euro(getSubTotal()) }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Desvalorização
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        -{{ 
                          budgetTool.formatVatPercentage(
                            compensation.depreciation_percentage
                          )
                        }}%
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_total_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ euro(getTotal()) }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <file-dialog
            v-model="file_preview"
            :attachment="file"
            :noDelete="true"
            @updateFile="updateFile"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Formatter from "@/tools/money";
import compensationTool from "@/tools/products/budgets/compensation";
import budgetTool from "@/tools/products/budgets/budget";
import { formatDateTime } from "@/tools/date";

import UserAvatar from "@/components/UserAvatar";
import FileDialog from "@/components/FileDialog";

export default {
  components: { UserAvatar, FileDialog },

  data() {
    return {
      budgetTool,
      formatDateTime,
      euro: Formatter.euro,
      file_preview: false,
      file: {},
      files: []
    };
  },

  methods: {
    updateFile(file_changed) {
      this.files = this.files.map((file_for_change) => {
        return file_for_change.id === file_changed.id
          ? { ...file_changed }
          : file_for_change;
      });
    },
    previewFile(file) {
      this.file = file;
      this.file_preview = true;
    },
    getSubTotal() {
      return compensationTool.getSubtotal(this.compensation);
    },
    getTotal() {
      return compensationTool.getTotal(this.compensation);
    },
    getItemTotal(item) {
      return compensationTool.getItemTotal(item);
    }
  },
  computed: {
    compensation() {
      const version = this.$route.params.version || "latest";
      return this.$store.getters["product/getCompensationByVersion"](version);
    }
  }
};
</script>
