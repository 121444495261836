import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify_instance = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#2B736B",
        secondary: "#464355",
        accent: "#5E948E",
        error: "#FF5252",
        info: "#2196F3",
        success: "#7BCF7D",
        warning: "#FE9F0D"
      },
      dark: {
        primary: "#41B883",
        secondary: "#363636",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#7BCF7D",
        warning: "#FFC107"
      }
    }
  },
  lang: {
    locales: { 
      en: require('vuetify/lib/locale/en.js')
    },
    current: "en"
  }
});

export function changeLocale(lang) {
  import(`vuetify/lib/locale/${lang}.js`)
    .then(locale => {
      if (!vuetify_instance.framework.lang.locales[lang]) {
        vuetify_instance.framework.lang.locales[lang] = locale.default;
      }

      vuetify_instance.framework.lang.current = lang;
    });
} 

export default vuetify_instance;
