import moment from "moment";

export function formatDateTime(value) {
  if (!value) return;
  const dateTime = moment(value).format("L") + " " + moment(value).format("LT");

  return dateTime;
}

export function formatTime(value) {
  if (!value) return;
  const time = moment(value).format("LT");

  return time;
}

export function formatDate(value) {
  if (!value) return;

  return moment(value).format("L");
}

export function formatDateTimeWithDuration(value) {
  if (!value) return;

  const duration = formatDateDuration(value);
  return formatDateTime(value) + " · " + duration;
}

export function formatDateDuration(value) {
  const date = moment(value);
  const duration = moment.duration(date.diff(moment())).humanize(true);
  return duration;
}

export function dateTimeToTimestamp(date, time = 0, seconds = 0) {
  if (!date || time === null || seconds === null) {
    return null;
  }

  time = time.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `${date} ${time}:${seconds}`;
}

// Formato para lidar com dia/mes/ano e hora:minuto
const CUSTOM_FORMAT = "DD/MM/YYYY HH:mm";

// Método novo para formatar apenas a data no formato 'dia/mes/ano'
export function formatCustomDate(value) {
  if (!value) return;

  return moment(value, CUSTOM_FORMAT).format("DD/MM/YYYY");
}

// Método novo para formatar apenas a hora no formato 'hora:minuto'
export function formatCustomTime(value) {
  if (!value) return;

  return moment(value, CUSTOM_FORMAT).format("HH:mm");
}
