import Echo from "laravel-echo"
import Config from "@/assets/config.json";

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    namespace: 'App.Events',
    broadcaster: 'pusher',
    key: Config.VUE_APP_WEBSOCKETS_KEY,
    wsHost: Config.VUE_APP_WEBSOCKETS_HOST,
    wsPort: Config.VUE_APP_WEBSOCKETS_PORT,
    wssPort: Config.VUE_APP_WEBSOCKETS_PORT,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: Config.VUE_APP_BASE_API + '/broadcasting_auth',
    auth: {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    },
});