<template>
    <div>
        <v-row>
            <v-col>
                <v-card>
                    <v-toolbar elevation="0">
                        <v-btn @click="$router.go(-1)" icon>
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title class="primary--text">
                            {{ $capitalize($tc('model.products_new_product_title')) }}
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card>
            </v-col>
        </v-row>

        <ProductForm @submit="createProduct" @cancel="$router.go(-1)" ref="productForm" />

        <v-dialog v-model="loading" width="300" persistent>
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingProgressionText}}
                    <v-progress-linear
                        :value="loadingProgressionValue"
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ProductForm from "@/components/products/ProductForm";
import ProductMixin from "@/mixins/ProductMixin";

export default {
    components: {
        ProductForm,
    },

    mixins: [ ProductMixin ],
}
</script>