<template>
  <div>
    <div v-if="substituition">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $capitalize($tc('model.products_budget_title')) }}
              <v-chip class="ml-2" small
                ><v-icon small left>mdi-tag</v-icon>
                {{ substituition.version }}</v-chip
              >
            </v-card-title>
            <v-card-text>
              <v-subheader>
                {{ $capitalize($tc('model.products_items_card')) }}
              </v-subheader>
              <v-simple-table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $capitalize($tc('model.products_product_simple_table')) }}</th>
                    <th>{{ $capitalize($tc('model.products_ad_simple_table')) }}</th>
                    <th>{{ $capitalize($tc('model.products_amount_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_unitary_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_total_title')) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in substituition.replace_items"
                    :key="item.id"
                  >
                    <td>
                      <v-avatar
                        @click="openDialog(item)"
                        tile
                        size="50"
                        color="grey"
                        class="ma-3"
                      >
                        <v-img
                          v-if="item.proposed_product_image_id"
                          :src="item.proposed_product_image.public_url"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-icon size="25" dark v-else>
                          mdi-image-edit-outline
                        </v-icon>
                      </v-avatar>
                    </td>
                    <td>
                      {{ item.proposed_product_name }}
                    </td>
                    <td>
                      <a :href="item.store_url" target="blank">
                        {{ item.store }}
                        <v-icon small>mdi-open-in-new</v-icon>
                      </a>
                    </td>
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td class="text-right">
                      {{ euro(item.unit_price) }}
                    </td>
                    <td class="text-right">
                      {{ euro(getItemTotal(item)) }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-right subtitle-2">
                      {{ $capitalize($tc('model.products_total_title')) }}
                    </th>
                    <th class="subtitle-2 text-right">
                      {{ euro(getSubTotal()) }}
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-row class="mt-10">
                <v-col>
                  <v-subheader>{{ $capitalize($tc('model.marketplace_details')) }}</v-subheader>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.photographic_report_created_on_colunm_table')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ formatDateTime(substituition.created_at) }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_created_by_colunm_table')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip>
                          <UserAvatar
                            :avatar_url="substituition.created_by.avatar_url"
                            :user_name="substituition.created_by.name"
                            :user_id="substituition.created_by.id"
                            left
                          />
                          {{ substituition.created_by.name }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-subheader>{{ $capitalize($tc('model.products_totalizers_subheader')) }}</v-subheader>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_subtotal_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ euro(getSubTotal()) }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Desvalorização
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        -{{ 
                          budgetTool.formatVatPercentage(
                            substituition.depreciation_percentage
                          )
                        }}%
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_additional_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{
                          budgetTool.formatVatPercentage(
                            substituition.additional_value
                          )
                        }}%
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ __('IVA', 'budget') }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action> {{ iva }}% </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_total_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ euro(getTotal()) }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="imageDialog" max-width="750">
      <v-card>
        <v-card-title>
          {{ $capitalize($tc('model.products_proof_ad_title')) }}
          <v-spacer></v-spacer>
          <v-btn @click="imageDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ $capitalize($tc('model.products_screenshot_comprove_ad')) }}
        </v-card-subtitle>

        <v-card-text>
          <v-img v-if="previewImage" :src="previewImage.public_url" class="mb-10">
          </v-img>
          <v-file-input v-model="image" label="Novo comprovante" accept="image/*" prepend-icon="" prepend-inner-icon="mdi-image-plus" solo></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="uploadImage" color="primary" :loading="uploading" :disabled="!image">
            {{ $capitalize($tc('model.image_predict_upload_button')) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Formatter from "@/tools/money";
import substituitionTool from "@/tools/products/budgets/substituition";
import budgetTool from "@/tools/products/budgets/budget";
import UserAvatar from "@/components/UserAvatar";
import { formatDateTime } from "@/tools/date";
import i18n from '@/mixins/i18n';

export default {
  components: { UserAvatar },
  mixins: [i18n],

  data() {
    return {
      budgetTool,
      formatDateTime,
      euro: Formatter.euro,
      imageDialog: false,
      image: null,
      uploading: false,
      itemId: null,
      previewImage: null
    };
  },

  methods: {
    getSubTotal() {
      return substituitionTool.getSubtotal(this.substituition);
    },
    getTotal() {
      return substituitionTool.getTotal(this.substituition);
    },
    getItemTotal(item) {
      return substituitionTool.getItemTotal(item);
    },
    openDialog(item) {
      this.itemId = item.id;
      this.imageDialog = true;
      this.image = null;
      this.previewImage = item.proposed_product_image
    },
    closeDialog() {
      this.imageDialog = false;
    },
    async uploadImage() {
      this.uploading = true;
      try {
        const response = await this.insertImage(
          this.substituition.product_id,
          this.substituition.id,
          this.itemId,
          this.image
        );
        this.substituition.replace_items = this.substituition.replace_items.map(item => {
          if(item.id === this.itemId) {
            const newReplaceItem = response.data.replace_items.find(i => i.id === this.itemId)
            return {
              ...item,
              proposed_product_image_id: newReplaceItem.proposed_product_image_id,
              proposed_product_image: newReplaceItem.proposed_product_image
            }
          }

          return item;
        })
        this.$store.commit('alert', this.$capitalize(this.$tc('model.products_save_alert')))
        this.imageDialog = false;
      } catch (error) {
        console.error(error);
        this.$store.commit(
          "error",
          this.$capitalize(this.$tc('model.products_could_not_send_proof'))
        );
      } finally {
        this.uploading = false;
      }
    },
    insertImage(productId, budgetId, repairItemId, image) {
      const form = new FormData();
      form.append("attachment", image);

      return this.$http.post(
        `/products/${productId}/budgets/${budgetId}/replace_items/${repairItemId}/proposed_product_image`,
        form
      );
    }
  },

  computed: {
    iva() {
      return budgetTool.formatVatPercentage(this.substituition.vat_percentage);
    },
    substituition() {
      const version = this.$route.params.version || "latest";
      return this.$store.getters["product/getSubstituitionByVersion"](version);
    }
  }
};
</script>
