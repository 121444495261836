<template>
    <div>
        <div class="subtitle-2">
            {{ title }}
        </div>
        <slot>
            <span v-html="prepareText(value) || '---'">
            </span>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'simple-item',
    
    props: {
        title: String,
        value: {
            default: ''
        }
    },

    methods: {
        prepareText(value) {
            if(!value) return null;

            let text = String(value);
            text = this.formatAnnotationMessage(text);

            return text;
        },
        formatAnnotationMessage(message) {
            if(!message) return null;
            
            return message.replace(/(?:\r\n|\r|\n)/g, "<br />");
        },
    }
}
</script>