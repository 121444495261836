import Vue from "vue";

export async function getLeadAndService(service_number) {
  const response = await Vue.prototype.$http.post(`/eps/get_lead_and_service`, {
    service_number,
  });

  return response.data;
}

export async function updateNotification(id, payload) {
  const response = await Vue.prototype.$http.put(
    `/leads/eps_notifications/${id}`,
    payload
  );

  return response.data;
}

export async function unblockNotification(id) {
  const response = await Vue.prototype.$http.post(
    `/leads/eps_notifications/${id}/unblock`
  );

  return response.data;
}

export async function blockNotification(id) {
  const response = await Vue.prototype.$http.post(
    `/leads/eps_notifications/${id}/block`
  );

  return response.data;
}

export async function showNotification(id) {
  const response = await Vue.prototype.$http.get(`/eps/notifications/${id}`);

  return response.data;
}

export async function showNotificationLogRequest(id) {
  const response = await Vue.prototype.$http.get(
    `/eps/notifications/logs/${id}/request`
  );

  return response.data.data;
}

export async function showNotificationLogResponse(id) {
  const response = await Vue.prototype.$http.get(
    `/eps/notifications/logs/${id}/response`
  );

  return response.data.data;
}

export async function sendAnalysisDate(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_number}/eps_notifications/analysis_date`,
    payload
  );

  return response;
}

export async function indexActions() {
  const response = await Vue.prototype.$http.get(`/eps_actions`);

  return response.data;
}

export async function createNotification(payload) {
  const response = await Vue.prototype.$http.post(`/eps_notifications`, {
    notification: payload,
  });

  return response.data;
}

export async function updateEpsUser(payload) {
  const response = await Vue.prototype.$http.post(
    `/eps/update_eps_user`,
    payload
  );

  return response.data;
}

export async function updateStatus(payload) {
  const response = await Vue.prototype.$http.post(
    `/eps/massive_actions/update_status`,
    payload
  );

  return response;
}

export async function setLeadsAsCompleted(payload) {
  const response = await Vue.prototype.$http.post(
    `/eps/massive_actions/set_leads_as_completed`,
    payload
  );

  return response;
}

export async function indexAttachmentCategories() {
  const response = await Vue.prototype.$http.get(
    `/configs/eps/attachment_categories`
  );

  return response.data.data;
}

export async function storeOrUpdateAttachmentCategories(payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/eps/attachment_categories`,
    payload
  );

  return response.data.data;
}

export async function destroyAttachmentCategories(id) {
  await Vue.prototype.$http.delete(`/configs/eps/attachment_categories/${id}`);
}
