<template>
  <v-list-item-content>
    <v-list-item-subtitle class="caption">{{ date }}</v-list-item-subtitle>
    <v-list-item-title>
      Vìdeo Peritagem • {{ reminder.remindable.lead.identifier_code }}
    </v-list-item-title>
    <v-list-item-subtitle class="text-wrap">{{
      content.message
    }}</v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import { formatDateTime } from "@/tools/date";

export default {
  name: "video-call-reminder",
  props: {
    reminder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      return formatDateTime(this.reminder.date);
    },
    content() {
      return this.reminder.metadata.content;
    },
  },
};
</script>

<style></style>
