import Vue from "vue";

export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/service_managers", {
    params
  });

  return response.data;
}

export async function show(id, params = {}) {
  const response = await Vue.prototype.$http.get("/service_managers/" + id, {
    params
  });

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/service_managers", payload);

  return response.data;
}

export async function update(payload, id) {
  const response = await Vue.prototype.$http.put("/service_managers/" + id, payload);

  return response.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete("/service_managers/" + id);

  return true;
}

export async function block(id) {
  const response = await Vue.prototype.$http.post(`/service_managers/${id}/block`);

  return response.data;
}

export async function restore(id) {
  const response = await Vue.prototype.$http.post(`/service_managers/${id}/restore`);

  return response.data;
}