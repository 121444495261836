import {
  indexDamageClaims,
  indexDamageTypes,
  damage_types
} from "@/services/damages";

export default {
  namespaced: true,
  state: {
    loading: false,
    damage_types: [],
    participants: []
  },
  getters: {
    getDamageTypes(state) {
      return state.damage_types;
    },
    getParticipants(state) {
      return state.participants;
    }
  },
  mutations: {
    setDamageTypes(state, payload) {
      state.damage_types = payload;
    },
    setParticipants(state, payload) {
      state.participants = payload;
    }
  },
  actions: {
    clearParticipants(context) {
      context.commit("setParticipants", []);
    },
    addDamageClaim(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      participant.damage_claims.push({
        ...payload,
        product_budgets: [],
        room_budgets: [],
        images: []
      });
    },
    editDamageClaim(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      const damage_claim = participant.damage_claims.find(
        claim => claim.id == payload.id
      );

      Object.assign(damage_claim, payload);
      damage_claim.product_feature = payload.product_feature;
      damage_claim.room_feature = payload.room_feature;
      damage_claim.product_budgets = payload.product_budgets;
      damage_claim.room_budgets = payload.room_budgets;
    },
    removeDamageClaim(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      participant.damage_claims = participant.damage_claims.filter(
        claim => claim.id != payload.id
      );
    },
    updateBudgetsDamageClaim(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      const damage_claim = participant.damage_claims.find(
        claim => claim.id == payload.id
      );

      if (damage_claim.damage_type?.id == damage_types.edificio)
        damage_claim.room_budgets = payload.budgets;
      else damage_claim.product_budgets = payload.budgets;
    },
    updateImagesDamageClaim(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      const damage_claim = participant.damage_claims.find(
        claim => claim.id == payload.id
      );

      damage_claim.images = payload.images;
    },
    async loadDamageClaims(context, lead_number) {
      context.state.loading = true;
      try {
        const response = await indexDamageClaims(lead_number);
        context.commit("setParticipants", response);
      } catch (e) {
        context.commit(
          "sendMessage",
          {
            text: "Não foi possível carregar os danos dos participantes",
            color: "red"
          },
          { root: true }
        );
      } finally {
        context.state.loading = false;
      }
    },
    async loadDamageTypes({ state, commit }) {
      if (state.damage_types.length == 0) {
        try {
          const damage_types = await indexDamageTypes();
          commit("setDamageTypes", damage_types ? damage_types : []);
        } catch (error) {
          console.error("erro ao buscar tipos de danos", error);
        }
      } else {
        return false;
      }
    }
  }
};
