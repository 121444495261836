export default {
  namespaced: true,
  state: {
    attempts: [],
  },
  getters: {
    getAttempts: (state) => {
      return state.attempts;
    },
  },
  mutations: {
    addAttempt(state, attempt) {
      state.attempts.push(attempt);
    },
    setAttempts(state, attempts) {
      state.attempts = attempts;
    },
    removeAttempt(state, attempt) {
      state.attempts = state.attempts.filter((item) => item.id !== attempt.id);
    },
  },
  actions: {},
};
