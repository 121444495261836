import { index as indexLocalCategories } from "@/services/products/local_categories";

export default {
  namespaced: true,
  state: {
    local_categories: []
  },
  getters: {
    getLocalCategories(state) {
      return state.local_categories;
    }
  },
  mutations: {},
  actions: {
    async loadLocalCategories({ state }) {
      if (state.local_categories.length == 0) {
        try {
          const local_categories = await indexLocalCategories({ list: true });

          state.local_categories = local_categories;
        } catch (error) {
          console.error("erro ao buscar os tipos de produtos", error);
        }
      }
    }
  }
};
