import { index as indexLeadTypes } from "@/services/lead_types";

export default {
  namespaced: true,
  state: {
    lead_types: []
  },
  getters: {
    getLeadTypes(state) {
      return state.lead_types;
    }
  },
  mutations: {},
  actions: {
    async loadTypes({ state }) {
      if (state.lead_types.length == 0) {
        try {
          const types = await indexLeadTypes();
          state.lead_types = types ? types : [];
        } catch (error) {
          console.error("erro ao buscar status", error);
        }
      } else {
        return false;
      }
    }
  }
};
