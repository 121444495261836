import { extend } from 'vee-validate';
import * as vee_rules from 'vee-validate/dist/rules';
import locals_rules from './rules/locals';


extend("secret", {
    validate: value => value === "example",
    message: "This is not the magic word"
});

Object.keys(vee_rules).forEach(rule => {
    extend(rule, vee_rules[rule]);
});

Object.keys(locals_rules).forEach(rule => {
    extend(rule, locals_rules[rule]);
});
