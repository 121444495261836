import Vue from "vue";

export const attachment_categories = {
  photo_gallery: 1,
  external_budget: 2,
  remote_analysis: 3,
  final_report: 7,
};

export const image_extensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "apng",
  "svg",
  "bmp",
  "bmp ico",
  "png ico",
];
export const microsoft_document_extensions = [
  // Word
  "docx",
  "docm",
  "dotx",
  "dotm",

  // Excel
  "xlsx",
  "xlsm",
  "xltx",
  "xltm",
  "xlsb",
  "xlam",

  //PowerPoint
  "pptx",
  "pptm",
  "potx",
  "potm",
  "ppam",
  "ppsx",
  "ppsm",
  "sldx",
  "sldm",
  "thmx",
];
export const previewable_extensions = ["pdf", "txt"].concat(
  image_extensions,
  microsoft_document_extensions
);

export async function index(lead_number, params) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/attachments`,
    { params }
  );

  return response.data;
}

export async function indexLeadPhotos(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/attachments/photos`
  );

  return response.data;
}

export async function show(id) {
  const response = await Vue.prototype.$http.get("/attachments/" + id);

  return response.data;
}

export async function store(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_number}/attachments`,
    payload
  );

  return response.data;
}

export async function update(payload, id) {
  const response = await Vue.prototype.$http.put("/attachments/" + id, payload);

  return response.data;
}

export async function destroy(id) {
  await Vue.prototype.$http.delete("/attachments/" + id);

  return true;
}

export async function multipleUpdateCategory(category_id, files) {
  const response = await Vue.prototype.$http.post(
    "attachments/multiple/update_category",
    {
      files,
      category_id,
    }
  );

  return response.data;
}

export async function multipleDestroy(files) {
  const response = await Vue.prototype.$http.post(
    "attachments/multiple/destroy",
    {
      files,
    }
  );

  return response.data;
}

export async function changeAttachmentLockAndVisibility(
  attachment_id,
  entity,
  visible,
  locked = undefined
) {
  const response = await Vue.prototype.$http.put(
    `attachments/visibility/${attachment_id}`,
    {
      entity,
      visible,
      locked,
    }
  );

  return response.data;
}

export async function changeManyAttachmentsVisibility(
  attachments_ids,
  entity,
  visible
) {
  const response = await Vue.prototype.$http.post("attachments/visibility", {
    attachments_ids,
    entity,
    visible,
  });

  return response.data;
}
