<template>
    <div>
        <v-row>
            <v-col>
                <BudgetPageToolbar j
                    :title="$capitalize($tc('model.products_repair_title'))" 
                    :back-to="productPage" 
                    :new-to="newVersionPage" 
                    version-route-name="ProductReparationData"
                    :budget-versions="reparations"
                />
            </v-col>
        </v-row>

        <router-view></router-view>
    </div>
</template>

<script>
import BudgetPageToolbar from "@/components/Budget/BudgetPageToolbar";

export default {
    components: {
        BudgetPageToolbar
    },

    data() {
        return {
            productPage: {
                name: 'ProductPageData'
            },
            newVersionPage: {
                name: 'ProductNewReparation'
            }
        };
    },

    computed: {
        reparations() {
            return this.$store.getters['product/getReparations'];
        }
    },
}
</script>