import Vue from "vue";
import axios from "axios";

export const languages_info = {
  en_US: {
    without_country: "en"
  },
  pt_BR: {
    without_country: "pt"
  },
  pt_PT: {
    without_country: "pt"
  },
  es_ES: {
    without_country: "es"
  },
  fr_FR: {
    without_country: "fr"
  }
};

export async function getLanguages() {
  const response = await Vue.prototype.$http.get(`/languages`);

  return response.data;
}

export async function setLanguage(language) {
  const response = await Vue.prototype.$http.put(
    `/user/language/` + language.id
  );

  return response.data;
}

export async function getInterfaceLanguagesProgress() {
  const response = await Vue.prototype.$http.get(
    `/languages/interface_progress`
  );

  return response.data;
}

export async function getDataLanguagesProgress() {
  const response = await Vue.prototype.$http.get(`/languages/data_progress`);

  return response.data;
}

export async function importInterfaceTranslationFile(payload) {
  const response = await Vue.prototype.$http.post(`/i18n/interface`, payload);

  return response.data;
}

export async function importDataTranslationFile(payload) {
  const response = await Vue.prototype.$http.post(`/i18n/data`, payload);

  return response.data;
}