<script>
export default {
    methods: {
        getFilesIcon(file) {
            let extension = file.extension;
            if (extension) {
                extension = extension.replace(".", "");
            }
            switch (extension) {
                case "jpg":
                case "jpeg":
                case "png":
                return "mdi-file-image";
                case "pdf":
                return "mdi-file-pdf";
                case "doc":
                case "docm":
                case "dot":
                case "dotm":
                case "docx":
                case "dotx":
                return "mdi-file-word";
                case "pptx":
                return "mdi-file-powerpoint";
                case "xlsx":
                return "mdi-file-excel";
                case "zip":
                case "rar":
                return "mdi-zip-box";
                case "txt":
                return "mdi-file-document";
                case "msg":
                return "mdi-microsoft-outlook";
                default:
                return "mdi-file";
            }
        },
        getFilesIconColor(file_icon) {
            switch (file_icon) {
                case "mdi-file-pdf":
                return "#e5625b";
                case "mdi-file-word":
                return "#285495";
                case "mdi-file-excel":
                return "#1F7045";
                case "mdi-file-powerpoint":
                return "#CA4324";
                case "mdi-zip-box":
                return "#6186B2";
                case "mdi-microsoft-outlook":
                return "#1697F6";
                default:
                return "";
            }
        },
        microsoft_preview(public_url) {
            return `https://view.officeapps.live.com/op/embed.aspx?src=${public_url}`;
        },
        isFileImage(file) {
            const image_extensions = ["jpg", "jpeg", "png", "gif", "apng", "svg", "bmp", "bmp ico", "png ico"];

            let is_image = false;

            image_extensions.forEach((extension) => {
                if (this.compareExtension(file, extension)) {
                    is_image = true;
                }
            });

            return is_image;
        },
        compareExtension(file, extension) {
            if(!file) return false;
            
            const fileExtension = file.extension.split('.').join('').toLowerCase();

            return extension == fileExtension;
        }
    }
}
</script>