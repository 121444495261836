<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <slot name="prepend" />
      <div class="my-2"></div>
      <empty-state
        :when="attachments.length == 0"
        :message="$capitalize($tc('model.products_no_photo_uploaded_message'))"
      >
        <v-virtual-scroll
          :bench="1"
          :items="chunked_attachments"
          :item-height="lineHeight"
          :height="virtualScrollerHeight"
          v-resize="onResize"
        >
          <template v-slot="{ item }">
            <v-row dense no-gutters>
              <v-col
                v-for="attachment in item"
                :key="attachment.id"
                class="d-flex child-flex px-1"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card outlined>
                  <v-img
                    aspect-ratio="1"
                    :src="attachment.public_url"
                    :height="imageHeight"
                  >
                    <div class="d-flex justify-space-between mx-2 mt-2">
                      <v-btn
                        fab
                        x-small
                        @click.stop.prevent="previewAttachment(attachment)"
                      >
                        <v-icon>mdi-arrow-expand</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="canDelete"
                        fab
                        x-small
                        @click.stop.prevent="
                          handleDeleteAttachment(attachment.id)
                        "
                        color="red"
                        dark
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-img>
                  <v-card-text class="pt-0" v-if="attachment.category">
                    <v-tooltip
                      bottom
                      v-if="attachment.part_of_attachmentgraphic_report"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" right color="warning"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span>{{
                        $capitalize(
                          $tc(
                            "model.attachmentgraphic_report_attachment_used_message"
                          )
                        )
                      }}</span>
                    </v-tooltip>
                  </v-card-text>
                  <v-list-item
                    @click.stop.prevent="previewAttachment(attachment)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ attachment.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="attachment.category">
                        <v-chip x-small>
                          {{ attachment.category.description }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>{{
                        attachment.description || "---"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-virtual-scroll>
      </empty-state>
    </v-card-text>

    <file-dialog
      v-model="file_preview"
      :attachment="preview_attachment"
      @updateFile="handleAttachmentChange"
      @deleteFile="handleDeleteAttachment"
    />
  </v-card>
</template>

<script>
import EmptyState from "@/components/EmptyState";
import FileDialog from "@/components/FileDialog.vue";

export default {
  components: { FileDialog, EmptyState },
  props: {
    attachments: Array,
    title: String,
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "attachments",
    event: "input"
  },
  data: () => ({
    windowHeight: 0,

    //File Preview
    file_preview: false,
    preview_attachment: {}
  }),
  methods: {
    handleDeleteAttachment(event) {
      this.$emit(
        "input",
        this.attachments.filter(attachment => attachment.id != event)
      );
    },
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },

    // File Preview
    previewAttachment(attachment) {
      this.preview_attachment = attachment;
      this.file_preview = true;
    },
    handleAttachmentChange(attachment) {
      this.$emit(
        "input",
        this.attachments.map(item => {
          if (attachment.id == item.id) {
            return attachment;
          }

          return item;
        })
      );
      this.file_preview = false;
    }
  },
  computed: {
    lineHeight: function() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "260";
        case "sm":
          return "240";
        case "md":
          return "240";
        case "lg":
          return "290";
        case "xl":
          return "290";
      }

      return "250";
    },
    itemsPerLine: function() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }

      return 6;
    },
    imageHeight: function() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "160";
        case "sm":
          return "150";
        case "md":
          return "150";
        case "lg":
          return "200";
        case "xl":
          return "200";
      }

      return "200";
    },
    virtualScrollerHeight: function() {
      return this.windowHeight * 0.7;
    },
    chunked_attachments: function() {
      const groupsOfAttachments = [];
      const attachments = this.attachments ? this.attachments : [];

      for (let i = 0; i < attachments.length; i += this.itemsPerLine) {
        groupsOfAttachments.push(attachments.slice(i, i + this.itemsPerLine));
      }

      return groupsOfAttachments;
    }
  },
  mounted() {
    this.onResize();
  }
};
</script>
