import { index } from "@/services/service_managers";

export default {
  namespaced: true,
  state: {
    service_managers: []
  },
  getters: {
    getServiceManagers(state) {
      return state.service_managers;
    }
  },
  mutations: {},
  actions: {
    async loadServiceManagers({ state }) {
      if (state.service_managers.length == 0) {
        try {
          const { data } = await index();
          state.service_managers = data ? data : [];
        } catch (error) {
          console.error("erro ao buscar service_managers", error);
        }
      } else {
        return false;
      }
    }
  }
};
