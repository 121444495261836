import Vue from "vue";
import VueI18n from "vue-i18n";
import { localeChanged as localeChangedVee } from "vee-validate";
import Messages from "@/assets/i18n/vue.json";
import { changeLocale as changeLocaleVuetify } from "@/plugins/vuetify";
import { languages_info } from "@/services/localizations";

Vue.use(VueI18n);

//initial translations
let initial_messages = Messages;
const loaded_validations = {};

async function loadValidationMessages(lang, messages) {
  if (loaded_validations[lang] === undefined) {
    try {
      const locale = await import(`vee-validate/dist/locale/${lang}.json`);
      loaded_validations[lang] = locale.messages;
    } catch (e) {
      const lang_without_country = languages_info[lang].without_country;
      const locale = await import(
        `vee-validate/dist/locale/${lang_without_country}.json`
      );
      loaded_validations[lang] = locale.messages;
    }
  }

  messages.validation = loaded_validations[lang];
  return messages;
}

for (const [key, value] of Object.entries(initial_messages)) {
  loadValidationMessages(key, value).then(
    (messages) => (initial_messages[key] = messages)
  );
}

const i18n = new VueI18n({
  locale: "pt_PT",
  messages: initial_messages,
  fallbackLocale: {
    pt_BR: ["pt_PT"],
    pt_PT: ["pt_BR"],
    default: ["en_US"],
  },
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

export function setI18nLanguage(lang) {
  i18n.locale = lang.id;
  Vue.prototype.$http.defaults.headers.common["Content-Language"] = lang.id;
  Vue.prototype.$moment.locale(lang.without_country);
  Vue.prototype.$moment.locale(lang.without_country, {
    calendar: {
      lastDay: i18n.tc("model.date_moment_calendar_last_day"),
      sameDay: i18n.tc("model.date_moment_calendar_same_day"),
      nextDay: i18n.tc("model.date_moment_calendar_next_day"),
      lastWeek: i18n.tc("model.date_moment_calendar_last_week"),
      nextWeek: i18n.tc("model.date_moment_calendar_next_week"),
      sameElse: i18n.tc("model.date_moment_calendar_same_else"),
    },
  });
  document.querySelector("html").setAttribute("lang", lang.id);
  localeChangedVee();
  changeLocaleVuetify(lang.without_country);
  Vue.config.disableNoTranslationWarning = true;
  return lang;
}

export function loadAsyncMessages() {
  fetch("/storage/i18n/vue.json")
    .then((res) => res.json())
    .then((i18n_messages) => {
      for (const [key, value] of Object.entries(i18n_messages)) {
        loadValidationMessages(key, value).then((messages) => {
          i18n.setLocaleMessage(key, messages);
        });
      }
    });
}

loadAsyncMessages();

export default i18n;
