<style lang="scss">
    .compensation-budget-item {
        .field {
            background-color: white
        }
    }
</style>

<template>
    <div class="compensation-budget-item">
        <v-card 
            color="grey lighten-3"
            outlined
        >
            <ValidationObserver ref="form" v-slot="{ errors, failed }">
                <v-form @submit.prevent="handleChange">
                    <v-card-text>
                        <FormAlertValidation :errors="errors" :visible="failed" />
                        <v-row dense>
                            <v-col cols="7">
                                <ValidationProvider
                                    name="name"
                                    rules="required|max:100"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        @input="handleChange('name', $event)"
                                        :value="value.name"
                                        name="name"
                                        class="field"
                                        outlined
                                        :label="$capitalize($tc('model.products_item_name'))" 
                                        hide-details
                                        :counter="45"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="2">
                                <ValidationProvider
                                    name="quantity"
                                    rules="required"
                                    v-slot="{ errors }"
                                    mode="passive"
                                >
                                    <v-text-field 
                                        name="quantity"
                                        @change="handleChangeQty"
                                        :value="value.quantity"
                                        class="field" 
                                        outlined 
                                        hide-details
                                        :label="$capitalize($tc('model.products_amount_simple_table'))"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="3">
                                <ValidationProvider
                                    name="value"
                                    rules="required|min_value:0.01"
                                    v-slot="{ errors }"
                                >
                                    <MoneyInput
                                        @input="handleChange('value', $event)"
                                        name="value"
                                        :value="value.value"
                                        class="field" 
                                        outlined 
                                        :label="$capitalize($tc('model.products_unitary_value'))" 
                                        hide-details
                                        :error-messages="errors"
                                    ></MoneyInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider
                                    name="coverage_id"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <v-autocomplete
                                        @input="handleChange('coverage_id', $event)"
                                        :value="value.coverage_id"
                                        :items="coverageOptions"
                                        item-value="id"
                                        item-text="type_description"
                                        class="field" 
                                        :label="__('Cobertura', 'budget')" 
                                        rows="3" 
                                        outlined 
                                        hide-details
                                        :error-messages="errors"
                                        name="coverage_id"
                                    ></v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="subtitle-2" no-gutters>
                            <v-col>
                                Total: {{ euro(total) }}
                            </v-col>
                            <v-col class="text-right">
                                <v-btn small @click="handleDelete()" icon :disabled="!deletable">
                                    <v-icon small>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </div>
</template>

<script>
import Formatter from "@/tools/money";
import MoneyInput from "@/components/MoneyInput";
import FormAlertValidation from "@/components/FormAlertValidation";
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    components: { 
        MoneyInput, 
        FormAlertValidation 
    },

    props: {
        coverages: Array,
        value: Object,
        deletable: {
            type: Boolean,
            default: true
        }
    },

    model: {
        prop: 'value',
        event: 'change'
    },

    data() {
        return {
            euro: Formatter.euro,
            form: {
                quantity: '1,0',
                value: 0
            }
        };
    },

    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        clear() {
            this.$emit('change', {
                quantity: '1,0',
                value: 0
            });

            this.$refs.form.reset();
        },
        handleDelete() {
            this.$emit('delete');
        },
        handleChangeQty(value) {
            value = String(value).replace('.', ',');

            if(value.indexOf(',') == -1) {
                value = `${value},0`
            }

            this.handleChange('quantity', value);
        },
        handleChange(item, value) {
            // Prepara a mudança no state
            const newValue = {
                ...this.value,
                [item]: value,
            };

            this.$emit('change', {
                ...newValue,
                total: this.calculateTotal(newValue) //Calcula total com base no novo state
            });
        },
        calculateTotal(value) {
            const qty = parseFloat(String(value?.quantity).replace(',', '.')) || 0;
            const price = parseFloat(value?.value) || 0;
            
            return price * qty;
        }
    },

    watch: {
        // Quando houver somente uma opção, selecioná-la automáticamente
        coverageOptions(value) {
            if(value.length == 1) {
                this.handleChange('coverage_id', value[0].id)
            }
        }
    },

    computed: {
        total() {
            return this.calculateTotal(this.value);
        },
        coverageOptions() {
            if(!this.coverages) return [];

            return this.coverages.filter(coverage => coverage.selected == true);
        },
        isInvalid() {
            return true;
        }
    }
}
</script>