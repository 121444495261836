<template>
  <v-card>
    <v-toolbar elevation="0" dense>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon :to="backTo" exact>
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="mx-3 mt-2">
      <v-chip-group active-class="primary--text" :show-arrows="true">
        <v-chip :to="newTo" small>
          <v-icon left small>mdi-tag-plus</v-icon> 
            {{ $capitalize($tc('model.products_new_version_chip')) }}
        </v-chip>

        <v-chip
          v-for="budgetVersion in budgetVersions"
          :key="budgetVersion.id"
          :to="{
            name: versionRouteName,
            params: {
              version: budgetVersion.version
            }
          }"
          exact
          small
        >
          <span v-if="budgetVersion.version == 'latest'">
            <!-- <v-icon size="20" left small>mdi-tag-heart-outline</v-icon> -->
            <b class="mr-2">#</b>
            {{ euro(budgetVersion.total_value_with_iva) }}
          </span>
          <span v-else>
            <!-- <v-icon size="20" left small>mdi-tag-outline</v-icon> -->
            <b class="mr-2">#{{ budgetVersion.version }}</b>
            {{ euro(budgetVersion.total_value_with_iva) }}
          </span>
        </v-chip>
      </v-chip-group>
    </div>
  </v-card>
</template>

<script>
import Formatter from "@/tools/money";

export default {
  name: "BudgetPageToolbar",

  props: {
    title: String,
    backTo: [String, Object],
    newTo: [String, Object],
    versionRouteName: String,
    budgetVersions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      euro: Formatter.euro
    };
  }
};
</script>
