<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="subtitle-2">{{ $capitalize($tc('model.products_additional_title')) }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="edit == false">
      <div>
        <span @click="handleEdit()" class="primary--text pointer">
          {{ budgetTool.formatVatPercentage(value) }} %
        </span>
        <v-btn @click="handleClearValue" small icon>
          <v-icon small>mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
    <v-list-item-action v-else>
      <MoneyInput
        @input="$emit('input', Number($event))"
        @change="handleChangeValue"
        @keydown.esc="handleCancel"
        :options="{
          locale: 'pt-PT',
          prefix: '',
          suffix: '%',
          length: 11,
          precision: 1
        }"
        key="aditionalValue"
        :value="value"
        label="Valor"
        single-line
        outlined
        dense
        hide-details
      >
        <template v-slot:append-outer>
          <v-btn @click="handleCancel" icon small>
            <v-icon small>mdi-close-circle</v-icon>
          </v-btn>
        </template>
      </MoneyInput>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Formatter from "@/tools/money";
import MoneyInput from "@/components/MoneyInput";
import budgetTool from "@/tools/products/budgets/budget";

export default {
  components: {
    MoneyInput
  },

  props: {
    value: Number
  },

  data() {
    return {
      budgetTool,
      euro: Formatter.euro,
      edit: false
    };
  },

  methods: {
    handleEdit() {
      this.edit = true;
    },
    handleChangeValue(event) {
      this.edit = false;
    },
    handleClearValue() {
      this.$emit("input", 0);
      this.edit = false;
    },
    handleCancel() {
      this.edit = false;
    }
  }
};
</script>
