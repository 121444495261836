<template>
  <div>
    <v-card-text class="pt-0">
      <v-chip-group
        v-model="filter"
        active-class="primary--text text--accent-4"
      >
        <v-chip value="all">
          {{ $capitalize($tc("model.reminder_all_reminders")) }}
        </v-chip>
        <v-chip value="today" v-if="today_reminders.length > 0">
          {{ $capitalize($tc("model.reminder_today_reminders")) }}
        </v-chip>
        <v-chip value="tomorrow" v-if="tomorrow_reminders.length > 0">
          {{ $capitalize($tc("model.reminder_tomorrow_reminders")) }}
        </v-chip>
        <v-chip value="future" v-if="future_reminders.length > 0">
          {{ $capitalize($tc("model.reminder_fprogrammed_reminders")) }}
        </v-chip>
        <v-chip value="previous" v-if="previous_reminders.length > 0">
          {{ $capitalize($tc("model.reminder_previous_reminders")) }}
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <empty-state
      :when="hasNoReminders"
      :message="$capitalize($tc('model.reminder_no_reminders_message'))"
    >
      <template v-if="hasCounter">
        <v-row dense class="px-3">
          <v-col>
            <v-sheet
              rounded
              class="d-flex px-4 justify-space-between"
              color="grey lighten-3"
              height="80"
            >
              <div class="align-self-center">
                <v-avatar color="orange" size="32">
                  <v-icon dark small>mdi-calendar</v-icon>
                </v-avatar>
                <div class="text-subtitle-2 text--secondary text-center">
                  {{ $capitalize($tc("model.reminder_today_reminders")) }}
                </div>
              </div>
              <div class="text-h4 align-self-center">
                {{ today_reminders.length }}
              </div>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet
              rounded
              class="d-flex px-4 justify-space-between"
              color="grey lighten-3"
              height="80"
            >
              <div class="align-self-center">
                <v-avatar color="purple" size="32">
                  <v-icon dark small>mdi-calendar-arrow-right</v-icon>
                </v-avatar>
                <div class="text-subtitle-2 text--secondary text-center">
                  {{ $capitalize($tc("model.reminder_tomorrow_text")) }}
                </div>
              </div>
              <div class="text-h4 align-self-center">
                {{ tomorrow_reminders.length }}
              </div>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet
              rounded
              class="d-flex px-4 justify-space-between"
              color="grey lighten-3"
              height="80"
            >
              <div class="align-self-center">
                <v-avatar color="blue" size="32">
                  <v-icon dark small>mdi-clock</v-icon>
                </v-avatar>
                <div class="text-subtitle-2 text--secondary text-center">
                  {{ $capitalize($tc("model.reminder_programmed_reminders")) }}
                </div>
              </div>
              <div class="text-h4 align-self-center">
                {{ future_reminders.length }}
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row dense class="px-3">
          <v-col>
            <v-sheet
              rounded
              class="d-flex px-4 justify-space-between"
              color="grey lighten-3"
              height="80"
            >
              <div class="align-self-center">
                <v-avatar color="grey" size="32">
                  <v-icon dark small>mdi-file-cabinet</v-icon>
                </v-avatar>
                <div class="text-subtitle-2 text--secondary text-center">
                  {{ $capitalize($tc("model.reminder_all_reminders")) }}
                </div>
              </div>
              <div class="text-h4 align-self-center">
                {{ all_reminders_count }}
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
      <v-list>
        <template v-if="filtered_today_reminders.length > 0">
          <v-subheader>{{
            $capitalize($tc("model.reminder_today_reminders"))
          }}</v-subheader>
          <reminder-item
            v-for="reminder in filtered_today_reminders"
            :reminder="reminder"
            :key="`reminder-${id}-${reminder.id}`"
          />
        </template>
        <template v-if="filtered_tomorrow_reminders.length > 0">
          <v-subheader>{{
            $capitalize($tc("model.reminder_tomorrow_reminders"))
          }}</v-subheader>
          <reminder-item
            v-for="reminder in filtered_tomorrow_reminders"
            :reminder="reminder"
            :key="`reminder-${id}-${reminder.id}`"
          />
        </template>
        <template v-if="filtered_future_reminders.length > 0">
          <v-subheader>{{
            $capitalize($tc("model.reminder_programmed_reminders"))
          }}</v-subheader>
          <reminder-item
            v-for="reminder in filtered_future_reminders"
            :reminder="reminder"
            :key="`reminder-${id}-${reminder.id}`"
          />
        </template>
        <template v-if="filtered_previous_reminders.length > 0">
          <v-subheader> Anteriores </v-subheader>
          <reminder-item
            v-for="reminder in filtered_previous_reminders"
            :reminder="reminder"
            :key="`reminder-${id}-${reminder.id}`"
          />
        </template>
      </v-list>
      <v-btn
        v-if="show_load_more_button"
        @click="loadPreviousReminders"
        block
        tile
        color="light-grey"
        :loading="loading_previous_reminders"
      >
        <v-icon left>mdi-chevron-down</v-icon>
        {{ $capitalize($tc("model.reminder_lead_more_option")) }}
      </v-btn>
    </empty-state>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import EmptyState from "@/components/EmptyState";
import ReminderItem from "@/components/ReminderItem";

export default {
  components: {
    EmptyState,
    ReminderItem,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hasCounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: "all",
    };
  },
  computed: {
    hasNoReminders() {
      if (this.loading_active_reminders || this.loading_previous_reminders) {
        return false;
      }
      return this.all_reminders_count === 0;
    },
    today_reminders() {
      return this.active_reminders.filter(
        (reminder) => reminder.diff_day == "today"
      );
    },
    tomorrow_reminders() {
      return this.active_reminders.filter(
        (reminder) => reminder.diff_day == "tomorrow"
      );
    },
    future_reminders() {
      return this.active_reminders.filter(
        (reminder) => reminder.diff_day == "future"
      );
    },
    filtered_today_reminders() {
      if (this.filter == "all" || this.filter == "today") {
        return this.today_reminders;
      }

      return [];
    },
    filtered_tomorrow_reminders() {
      if (this.filter == "all" || this.filter == "tomorrow") {
        return this.tomorrow_reminders;
      }

      return [];
    },
    filtered_future_reminders() {
      if (this.filter == "all" || this.filter == "future") {
        return this.future_reminders;
      }

      return [];
    },
    filtered_previous_reminders() {
      if (this.filter == "all" || this.filter == "previous") {
        return this.previous_reminders;
      }

      return [];
    },
    all_reminders_count() {
      return (
        this.today_reminders.length +
        this.tomorrow_reminders.length +
        this.future_reminders.length +
        this.metadata_previous.total
      );
    },
    show_load_more_button() {
      return (
        this.metadata_previous.current_page <
          this.metadata_previous.last_page &&
        this.filtered_previous_reminders.length > 0
      );
    },
    ...mapState("reminder", [
      "active_reminders",
      "previous_reminders",
      "metadata_previous",
      "loading_active_reminders",
      "loading_previous_reminders",
    ]),
  },
  methods: {
    ...mapActions("reminder", ["loadPreviousReminders"]),
  },
  created() {
    window.Echo.join("reminders").listen("NewMessage", (e) => {
      console.log("new reminder");
    });
  },
};
</script>

<style></style>
