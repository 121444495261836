import _, { values } from "lodash"

function getVatValue(vat_percentage, subtotal_with_additional) {
    if(!vat_percentage || !subtotal_with_additional) return 0

    return parseFloat(subtotal_with_additional) * (parseFloat(vat_percentage) / 100)
}

function getAdditional(additional_value, subtotal) {
    if(!additional_value || !subtotal) return 0

    return parseFloat(subtotal) * (parseFloat(additional_value) / 100)
}

function getDepreciation(depreciation_percentage, subtotal) {
    if(!depreciation_percentage || !subtotal) return 0

    return parseFloat(subtotal) * (parseFloat(depreciation_percentage) / 100)
}

function formatVatPercentage(vat_percentage) {
    return _.round(vat_percentage || 0, 1);   
}

export default {
    getDepreciation,
    getVatValue,
    formatVatPercentage,
    getAdditional,
}