<template>
  <div>
    <v-row>
      <v-col>
        <v-card :disabled="loading">
          <ValidationObserver ref="compensationForm">
            <v-form @submit.prevent="handleSubmit">
              <v-card-title>
                {{ $capitalize($tc('model.products_new_external_budget_title')) }}
              </v-card-title>

              <v-card-text>
                <v-subheader>
                  {{ $capitalize($tc('model.products_items_card')) }} 
                </v-subheader>
                <v-row v-for="(budgetItem, index) in budgetItems" :key="index">
                  <v-col>
                    <CompensationBudgetItem
                      :ref="`budgetItem${index}`"
                      v-model="budgetItems[index]"
                      @delete="handleDeleteItem(index)"
                      :coverages="lead.policy.coverages"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-btn @click="handleAddNewItem" color="primary" text>
                      <v-icon left>mdi-plus</v-icon>
                      {{ $capitalize($tc('model.products_add_item')) }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-10">
                  <v-col>
                    <v-subheader class="pl-0 mb-5">
                      {{ $capitalize($tc('model.marketplace_details')) }}
                    </v-subheader>

                    <ValidationProvider
                      name="external_objective_id"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <BudgetObjectiveInput
                        v-model="product_budget.external_objective_id"
                        outlined
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      name="product_budget.issued_by"
                      :rules="{
                        required: product_budget.external_objective_id === 2
                      }"
                      v-slot="{ errors }"
                    >
                      <ContactInput
                        name="product_budget.issued_by"
                        v-model="product_budget.issued_by"
                        :label="$capitalize($tc('model.products_repairman_title'))"
                        outlined
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      name="product_budget.external_document_id"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <AttachmentInput
                        :label="$capitalize($tc('model.products_attachment_reference'))"
                        v-model="product_budget.external_document_id"
                        :attachments="attachments"
                        :loading="loadingAttachments"
                        outlined
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      name="product_budget.sent_by"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <ParticipantPicker
                        :label="$capitalize($tc('model.products_sent_by'))"
                        v-model="product_budget.sent_by"
                        :lead="lead"
                        return-object
                        outlined
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader>
                      {{ $capitalize($tc('model.products_totalizers_subheader')) }}
                    </v-subheader>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-2"
                            >{{ $capitalize($tc('model.products_subtotal_title')) }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="mr-8 subtitle-2">
                            {{ euro(subtotal) }}
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider/>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-2"
                            >{{ $capitalize($tc('model.products_devaluation')) }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="mr-8">
                            -{{ budgetTool.formatVatPercentage(depreciation_percentage) }} %
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider/>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-2"
                            >{{ $capitalize($tc('model.products_total_title')) }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="mr-8 subtitle-2">
                            {{ euro(total) }}
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                  >{{ $capitalize($tc('model.products_save_button')) }}</v-btn
                >
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AttachmentInput from "@/components/Lead/AttachmentInput";
import CompensationBudgetItem from "@/components/Budget/Compensation/CompensationBudgetItem";
import Formatter from "@/tools/money";
import budgetTool from "@/tools/products/budgets/budget";
import ContactInput from "@/components/ContactInput";
import { index as indexAttachments } from "@/services/attachment";
import ParticipantPicker from "@/components/Lead/ParticipantPicker";
import BudgetObjectiveInput from "@/components/Budget/BudgetObjectiveInput";

export default {
  components: {
    CompensationBudgetItem,
    ContactInput,
    AttachmentInput,
    ParticipantPicker,
    BudgetObjectiveInput
  },

  data() {
    return {
      budgetTool,
      product_budget: {},
      euro: Formatter.euro,
      budgetItems: [
        {
          quantity: "1,0"
        }
      ],
      attachments: [],
      loadingAttachments: false,
      loading: false,
      budgetObjective: null,
      depreciation_percentage: 0
    };
  },

  methods: {
    async handleSubmit() {
      const productId = this.$route.params.product_id;
      const itemIndex = this.budgetItems.map((item, index) => index);
      let valid = true;

      for (let index of itemIndex) {
        const validation = await this.$refs[`budgetItem${index}`][0].validate();
        if (!validation) valid = false;
      }

      if (!valid) {
        this.$store.commit("error", this.$capitalize(this.$tc('model.products_one_or_invalid_items')));
        return;
      }

      const validation = await this.$refs.compensationForm.validate();
      if (!validation) return;

      const payload = {
        product_budget: {
          objective: "compensation",
          additional_value: 0,
          external_objective_id: this.product_budget.external_objective_id,
          issued_by: this.product_budget.issued_by
            ? this.product_budget.issued_by.id
            : null,
          sent_by: this.product_budget.sent_by
            ? this.product_budget.sent_by.id
            : null,
          external_document_id: this.product_budget.external_document_id,
          external_items: this.budgetItems.map(item => ({
            coverage_id: item.coverage_id,
            description: item.name,
            quantity: parseFloat(item.quantity.replace(",", ".")),
            unit_price: item.value
          }))
        }
      };

      this.loading = true;
      try {
        const response = await this.$http.post(
          `/products/${productId}/budgets`,
          payload
        );
        await this.$store.dispatch("product/loadCompensations", {
          productId
        });
        this.$store.commit("product/setLastCompensation", response.data);
        this.$router.push({
          name: "ProductCompensationDataLatest"
        });
      } catch (error) {
        this.$store.commit(
          "error",
          this.$capitalize(this.$tc('model.products_no_send_budget_message'))
        );
      } finally {
        this.loading = false;
      }
    },
    fillNewBudget() {
      this.depreciation_percentage = parseFloat(this.product.depreciation_percentage);
    },
    fillBudget(budget) {
      this.product_budget = {
        issued_by: budget.issuer,
        external_objective_id: budget.external_objective_id,
        sent_by: budget.sender,
        external_document_id: budget.external_document_id
      };

      this.depreciation_percentage = parseFloat(this.product.depreciation_percentage);
      this.budgetItems = [];
      for (const budget of budget.external_items) {
        this.budgetItems.push({
          coverage_id: budget.coverage_id,
          name: budget.description,
          quantity: String(budget.quantity).replace(".", ","),
          value: parseFloat(budget.unit_price),
          total: parseFloat(budget.unit_price) * parseFloat(budget.quantity)
        });
      }
    },
    async handleAddNewItem() {
      this.budgetItems.push({
        quantity: "1,0"
      });
    },
    handleDeleteItem(index) {
      if (this.budgetItems.length > 1) {
        this.budgetItems.splice(index, 1);
      } else {
        this.$refs[`budgetItem${index}`][0].clear();
      }
    },
    async getAttachments() {
      this.loadingAttachments = true;
      const attachments = await indexAttachments(
        this.$route.params.lead_number,
        {
          list: true,
          category_id: 2 // Categoria orçamentos externos
        }
      );
      this.attachments = attachments;
      this.loadingAttachments = false;
    }
  },

  watch: {
    lastCompensation: function(value) {
      if (!value) {
        this.fillNewBudget();
      } else {
        this.fillBudget({ ...value });
      }
    }
  },

  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    product() {
      return this.$store.getters['product/getProduct'];
    }, 
    total() {
      let sum = this.subtotal;
      sum -= budgetTool.getDepreciation(this.depreciation_percentage, this.subtotal);


      return sum;
    },
    subtotal() {
      let sum = 0;
      for (const budget of this.budgetItems) {
        sum += budget.total || 0;
      }

      return sum;
    },
    lastCompensation() {
      return this.$store.getters["product/getCompensationByVersion"]("latest");
    }
  },

  mounted() {
    this.getAttachments();

    const lastCompensation = this.$store.getters[
      "product/getCompensationByVersion"
    ]("latest");
    if (lastCompensation) {
      this.fillBudget({ ...lastCompensation });
    } else {
      this.fillNewBudget();
    }
  }
};
</script>
