<template>
  <v-dialog
    :value="value"
    persistent
    max-width="1100"
    style="overflow: hidden"
  >
    <v-card dark>
      <v-row no-gutters>
        <v-col cols="12" md="7" style="max-height: 70vh" v-if="attachment.id">
          <template v-if="previewable">
            <v-img contain max-height="100%" v-if="isFileImage(attachment)" :src="getFilePublicUrl()"></v-img>
            <iframe
              v-else
              :src="getFilePublicUrl()"
              frameborder="0"
              width="100%"
              height="500px"
              style="background-color: white"
            ></iframe>
        </template>
        <div 
            style="
                background-color: white;
                width: 100%;
                height: 380px;
            "
            class="d-flex align-center justify-center text-center"
            v-else
        >
          <div>
            <v-avatar color="grey lighten-2">
                <v-icon>mdi-file</v-icon>
            </v-avatar><br />
            <div class="grey--text mt-2"> {{ $capitalize($tc("model.documents_no_preview_available")) }}</div>
            <v-btn
              @click="downloadFile()"
              color="primary"
              text
            >Download</v-btn>
          </div>
        </div>
        </v-col>
        <v-col style="height: 100%">
          <v-form @submit.prevent="handleChangeFile">
            <v-card flat dark>
              <v-card-title>
                {{ $capitalize($tc("model.file_file_title_dialog")) }}
                <v-spacer></v-spacer>
                <v-btn @click="downloadFile()" icon>
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn @click="confirm_file_deletion=true" icon v-if="deletable">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn @click="handleClose()" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  :label="$capitalize($tc('model.file_file_name_label_dialog'))"
                  v-model="form.name"
                  prepend-icon="mdi-file"
                ></v-text-field>
                <v-textarea
                  :label="$capitalize($tc('model.file_file_description_label_dialog'))"
                  v-model="form.description"
                  auto-grow
                  prepend-icon="mdi-text"
                ></v-textarea>
              </v-card-text>
              <v-card-actions v-if="editable">
                <v-btn :loading="loading" type="submit" color="primary" block>{{ $capitalize($tc("model.file_save_button")) }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-card>

    <confirm-action-dialog
      :confirm_action="confirm_file_deletion"
      @change="confirm_file_deletion=$event"
      @action="deleteFile()"
      :message="$capitalize($tc('model.file_file_deleted'))"
    ></confirm-action-dialog>
  </v-dialog>
</template>

<script>
import { 
  update as updateAttachment, 
  destroy as deleteAttachment,
  image_extensions,
  microsoft_document_extensions,
  previewable_extensions
} from "@/services/attachment";

import ConfirmActionDialog from "@/components/ConfirmActionDialog";
import AttachmentsMixin from "@/mixins/AttachmentsMixin";

export default {
  name: "file-dialog",
  props: {
    value: {
      type: Boolean
    }, 
    attachment: {
      type: Object
    }, 
    noDelete: {
      type: Boolean,
      default: false
    }, 
    noEdit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [AttachmentsMixin],
  components: { ConfirmActionDialog },
  data: () => ({
    file: {},
    loading: false,
    form: {
      name: null,
      description: null
    },
    confirm_file_deletion: false,
  }),
  methods: {
    async deleteFile() {
      try {
        if(this.$listeners.deleteAttachment == null) {
          await deleteAttachment(this.attachment.id);
        } else {
          await this.$listeners.deleteAttachment(this.attachment);
        }

        this.$emit("deleteFile", this.attachment.id);
        
        this.$store.commit("sendMessage", {
          text: "Arquivo excluído"
        });
        this.confirm_file_deletion = false;
        this.handleClose();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: error.response.data.message,
          color: "red",
        });
      }
    },
    async handleChangeFile() {
      this.loading = true;

      const payload = {
        ...this.form
      };

      try {
        let response;

        if(this.$listeners.updateAttachment == null) {
          response = await updateAttachment(payload, this.attachment.id);
        } else {
          response = await this.$listeners.updateAttachment(this.attachment, payload);
        }
        this.form = response;

        this.$emit("updateFile", this.form);

        this.$store.commit("sendMessage", {
          text: "Arquivo atualizado",
          color: "green"
        });
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao atualizar arquivo",
          color: "red"
        });
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.$emit("input", false);
      this.resetForm();
    },
    resetForm() {
      this.file = {};
      this.form = {
        name: null,
        description: null
      };
    },
    getFilePublicUrl() {
      switch (this.attachment.extension) {
        case "xlsx":
        case "pptx":
        case "docx":
          return this.microsoft_preview(this.attachment.public_url);
        default:
          return this.attachment.public_url;
      }
    },
    microsoft_preview(public_url) {
      const url = encodeURIComponent(public_url);

      return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
    },
    async downloadFile() {
      window.open(this.attachment.public_url);
    },
  },
  watch: {
    value: function (value) {
      if (value) {
        this.form.name = this.attachment.name;
        this.form.description = this.attachment.description;
      }
    }
  },
  computed: {
    deletable() {
      return this.noDelete === false;
    },
    editable() {
      return this.noEdit === false;
    },
    previewable() {
      return previewable_extensions.some(extension => extension == this.attachment.extension);
    }
  }
}
</script>