import { indexMyInfoRequests } from "@/services/info_requests";

export default {
  namespaced: true,
  state: {
    my_info_requests: [],
    all_info_requests: []
  },
  mutations: {},
  actions: {
    async loadMyInfoRequests({ state }) {
      try {
        const requests = await indexMyInfoRequests();
        state.my_info_requests = requests ? requests : [];
      } catch (error) {
        console.error("erro ao buscar status", error);
      }
    },
  }
};
