import Vue from "vue";
import Config from "@/assets/config.json";

const products_url = Config.VUE_APP_BASE_PRODUCTS_API;


export async function autocompleteBarcode(barcode) {
  const response = await Vue.prototype.$http.get(`/products/autocomplete/${barcode}`);

  return response.data;
}

export async function index(leadNumber, params) {
  const response = await Vue.prototype.$http.get(`/leads/${leadNumber}/products`, params);

  return response.data;
}

export async function show(id, leadNumber) {
  const response = await Vue.prototype.$http.get(`/leads/${leadNumber}/products/${id}`);

  return response.data;
}

export async function update(id, leadNumber, data) {
  const response = await Vue.prototype.$http.put(`/leads/${leadNumber}/products/${id}`, data);

  return response.data;
}

export async function store(leadNumber, data) {
  const response = await Vue.prototype.$http.post(`/leads/${leadNumber}/products`, data);

  return response.data;
}


export async function destroy(id, leadNumber) {
  const response = await Vue.prototype.$http.delete(`/leads/${leadNumber}/products/${id}`);

  return response;
}

export async function uploadPhoto(leadNumber, productId, data) {
  const response = await Vue.prototype.$http.post(`/leads/${leadNumber}/products/${productId}/photos`, data);

  return response;
}

export async function getProductBudgets(id, params) {
  const response = await Vue.prototype.$http.get(`products/${id}/budgets`, {params});

  return response.data;
}