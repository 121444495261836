<template>
  <div class="home">
    <AdminApp />
  </div>
</template>

<script>
// @ is an alias to /src
import AdminApp from "@/components/AdminApp.vue";

export default {
  name: "home",
  components: {
    AdminApp,
  },
};
</script>
