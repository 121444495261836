<template>
  <div>
    <v-row>
      <v-col>
        <BudgetForm
          :title="$capitalize($tc('model.products_new_replacement_budget_title'))"
          objective="substituition"
          :loading="loading"
          @submit="handleCreateBudget"
          ref="budgetForm"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BudgetForm from "@/components/Budget/BudgetForm";

export default {
  components: {
    BudgetForm
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    async handleCreateBudget(payload) {
      const productId = this.$route.params.product_id;
      this.loading = true;
      try {
        const response = await this.$http.post(
          `/products/${productId}/budgets`,
          {
            product_budget: {
              objective: "substituition",
              additional_value: payload.additionalValue,
              vat_percentage: payload.vat_percentage,
              replace_items: payload.budgetItems.map(item => ({
                ...item,
                quantity: parseFloat(item.quantity.replace(",", "."))
              }))
            }
          }
        );
        await this.$store.dispatch("product/loadSubstituitions", {
          productId
        });
        this.$store.commit("product/setLastSubstituition", response.data);
        this.$router.push({
          name: "ProductSubstituitionDataLatest"
        });
      } catch (error) {
        console.error(error);
        this.$store.commit(
          "error",
          this.$capitalize(this.$tc('model.products_no_send_budget_message'))
        );
      } finally {
        this.loading = false;
      }
    }
  },

  computed: {
    lastSubstituition() {
      return this.$store.getters["product/getSubstituitionByVersion"]("latest");
    }
  },

  watch: {
    lastSubstituition(value) {
      if (!value) {
        this.$refs.budgetForm.fillNewBudget();
      } else {
        this.$refs.budgetForm.fillBudget({ ...value });
      }
    }
  },

  mounted() {
    const lastSubstituition = this.$store.getters[
      "product/getSubstituitionByVersion"
    ]("latest");
    if (lastSubstituition) {
      this.$refs.budgetForm.fillBudget({ ...lastSubstituition });
    } else {
        this.$refs.budgetForm.fillNewBudget();
    }
  }
};
</script>
