<template>
  <div>
    <div v-if="reparation">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $capitalize($tc('model.products_budget_title')) }}
              <v-chip class="ml-2" small
                ><v-icon small left>mdi-tag</v-icon>
                {{ reparation.version }}</v-chip
              >
            </v-card-title>
            <v-card-text>
              <v-subheader>
                {{ $capitalize($tc('model.products_items_card')) }} 
              </v-subheader>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>{{ $capitalize($tc('model.products_item_simple_table')) }}</th>
                    <th>{{ $capitalize($tc('model.products_amount_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_unitary_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_material_simple_table')) }}</th>
                    <th class="text-right">{{ $capitalize($tc('model.products_total_title')) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in reparation.repair_items" :key="item.id">
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.labor_quantity }}
                      {{ item.labor_quantity_unit.abbreviation }}
                    </td>
                    <td class="text-right">
                      {{ euro(item.unit_price) }}
                    </td>
                    <td class="text-right">
                      {{ euro(item.total_material_price) }}
                    </td>
                    <td class="text-right">
                      {{ euro(getItemTotal(item)) }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-right subtitle-2">
                      {{ $capitalize($tc('model.products_total_title')) }}
                    </th>
                    <th class="subtitle-2 text-right">
                      {{ euro(getSubTotal()) }}
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-row class="mt-10">
                <v-col>
                  <v-subheader>{{ $capitalize($tc('model.products_items_card')) }}</v-subheader>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.photographic_report_created_on_colunm_table')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ formatDateTime(reparation.created_at) }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_created_by_colunm_table')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip>
                          <UserAvatar
                            :avatar_url="reparation.created_by.avatar_url"
                            :user_name="reparation.created_by.name"
                            :user_id="reparation.created_by.id"
                            left
                          />
                          {{ reparation.created_by.name }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-subheader>{{ $capitalize($tc('model.products_totalizers_subheader')) }}</v-subheader>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_subtotal_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ euro(getSubTotal()) }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Desvalorização
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        -{{ 
                          budgetTool.formatVatPercentage(
                            reparation.depreciation_percentage
                          )
                        }}%
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_additional_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{
                          budgetTool.formatVatPercentage(
                            reparation.additional_value
                          )
                        }}%
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ __('IVA', 'budget') }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ vatPercentage }}%
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $capitalize($tc('model.products_total_title')) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ euro(getTotal()) }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Formatter from "@/tools/money";
import reparationTool from "@/tools/products/budgets/reparation";
import budgetTool from "@/tools/products/budgets/budget";
import UserAvatar from "@/components/UserAvatar";
import { formatDateTime } from "@/tools/date";
import i18n from '@/mixins/i18n';

export default {
  components: { UserAvatar },
  mixins: [i18n],

  data() {
    return {
      euro: Formatter.euro,
      budgetTool,
      formatDateTime
    };
  },

  methods: {
    getSubTotal() {
      return reparationTool.getSubtotal(this.reparation);
    },
    getTotal() {
      return reparationTool.getTotal(this.reparation);
    },
    getItemTotal(item) {
      return reparationTool.getItemTotal(item);
    }
  },

  computed: {
    vatPercentage() {
      return budgetTool.formatVatPercentage(this.reparation.vat_percentage);
    },
    reparation() {
      const version = this.$route.params.version || "latest";
      return this.$store.getters["product/getReparationByVersion"](version);
    }
  }
};
</script>
