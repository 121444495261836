import Vue from "vue";

export async function search(query) {
  const response = await Vue.prototype.$http.get("search", {
    params: {
        q: query
    }
  });

  return response.data;
}

export async function sugest(query) {
  const response = await Vue.prototype.$http.post("search/sugest", {
    query
  });

  return response.data;
}
