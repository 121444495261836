<template>
  <div>
    <div v-if="!when">
      <slot></slot>
    </div>
    <v-card
      flat
      v-if="when"
      class="d-flex justify-center text-center align-center"
      v-bind="$attrs"
      :color="color"
    >
      <div>
        <v-list :color="color">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-icon size="90" color="grey" style="opacity: 0.5"
                  >mdi-text-box-remove-outline</v-icon
                >
              </v-list-item-subtitle>
              <v-list-item-subtitle style="white-space: normal;">
                <v-list :color="color">
                  <v-list-item class="justify-center">
                    {{ message }}
                  </v-list-item>
                  <v-list-item
                    class="justify-center"
                    v-if="hasMessageContentSlot"
                  >
                    <slot name="message-content"></slot>
                  </v-list-item>
                </v-list>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "empty-state",
  props: {
    message: String,
    when: {
      type: Boolean,
      default: true
    },
    color: String
  },
  computed: {
    hasMessageContentSlot() {
      return !!this.$slots["message-content"];
    }
  }
};
</script>
