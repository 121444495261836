import { getLeadStatuses as getStatuses } from "@/services/lead";

export default {
  namespaced: true,
  state: {
    lead_statuses: []
  },
  getters: {
    getLeadStatuses(state) {
      return state.lead_statuses;
    }
  },
  mutations: {},
  actions: {
    async loadStatuses({ state }) {
      if (state.lead_statuses.length == 0) {
        try {
          const statuses = await getStatuses();
          state.lead_statuses = statuses ? statuses : [];
        } catch (error) {
          console.error("erro ao buscar status", error);
        }
      } else {
        return false;
      }
    }
  }
};
