<template>
    <div>
        <v-text-field 
            @click="openDialog"
            @click:clear="handleClear"
            :label="label"
            :value="value ? value.name : ''"
            readonly
            clearable
            :error-messages="errorMessages"
            v-bind="$attrs"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="650" persistent eager>
            <ContactFormCard 
                ref="contactForm"
                :title="$capitalize($tc('model.products_budget_sender_title'))"
                @cancel="handleCancel"
                @submit="handleSubmitForm"
                :loading="loading"
                only_companies
            />
        </v-dialog>
    </div>
</template>

<script>
import ContactFormCard from "@/components/ContactFormCard";
import {
    store as storeContact,
    update as updateContact
} from "@/services/contact";

export default {
    components: { ContactFormCard },

    props: {
        errorMessages: Array,
        label: String,
        value: {
            type: Object,
            default: null
        },
    },

    data() {
        return {
            dialog: false,
            loading: false
        };
    },

    methods: {
        handleClear() {
            this.$emit('input', null);
        },
        async handleSubmitForm(event) {
            this.loading = true;
            const payload = {
                ...event
            }
            try {
                const response = payload.id ? await updateContact(payload.id, payload) : await storeContact(payload);
                this.dialog = false;
                this.$emit('input', response);
            } catch(error) {
                console.error(error);
                if(error.response?.status == 422) {
                    this.$refs.contactForm.setErrors(error.response.data.errors);
                }

                this.$store.commit('error', this.$capitalize(this.$tc('model.products_budget_sender_error_message')));
            } finally {
                this.loading = false;
            }
        },
        openDialog() {
            this.dialog = true;

            if(!this.value) {
                this.$refs.contactForm.reset();
            } else {
                this.$refs.contactForm.fill(this.value);
            }
        },
        handleCancel() {
            this.dialog = false;
        }
    }
}
</script>