<template>
    <div>
        <v-row>
            <v-col>
                <v-card>
                    <v-toolbar elevation="0">
                        <v-btn @click="$router.go(-1)" icon>
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title class="primary--text">
                            {{ $capitalize($tc('model.products_change_product_title')) }} 
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card>
            </v-col>
        </v-row>

        <ProductNotFound v-if="productNotFound" />
        <ProductForm v-else ref="productForm" @submit="updateProduct" @cancel="$router.go(-1)" />

        <v-dialog v-model="loading" width="300" persistent>
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingProgressionText}}
                    <v-progress-linear
                        :value="loadingProgressionValue"
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ProductForm from "@/components/products/ProductForm";
import { show } from "@/services/products/products";
import ProductMixin from "@/mixins/ProductMixin";
import ProductNotFound from "@/components/products/ProductNotFound";

export default {
    components: {
        ProductForm,
        ProductNotFound
    },

    mixins: [ ProductMixin ],

    data() {
        return {
            productNotFound: false
        };
    },

    methods: {
        async getData() {
            const leadNumber = this.$route.params.lead_number;
            const productId = this.$route.params.product_id;

            this.$store.commit('setLoading', {
                loading: true
            });

            try {        
                const product = await show(productId, leadNumber);
                this.$refs.productForm.fill({
                    product
                });
            } catch(error) {
                if(error.response.status == 404) {
                    this.productNotFound = true;
                }
            } finally {
                this.$store.commit('setLoading', {
                    loading: false
                });
            }
        },            
    },

    created() {
        this.getData();
    }
}
</script>