<template>
  <v-card :disabled="loading">
    <ValidationObserver ref="form" disabled>
      <v-form @submit.prevent="handleSubmit">
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="handleCancel" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            name="name"
            rules="required|max:100"
            v-slot="{ errors }"
          >
            <v-combobox
              v-model="value.name"
              :label="$capitalize($tc('model.schedulings_steps_name_label'))"
              :placeholder="$capitalize($tc('model.products_enter_name_nif_search'))" 
              :items="contacts"
              :search-input.sync="search"
              :loading="loadingContacts"
              return-object
              item-text="name"
              autofocus
              autocomplete="autocomplete_off_hack_xfr4!k"
              @change="handleChangeName"
              :error-messages="errors"
            ></v-combobox>
          </ValidationProvider>
          <ValidationProvider
            name="email"
            rules="email|max:100"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="value.email"
              type="email"
              :label="__('Email', 'contact')"
              :error-messages="errors"
              name="email"
            ></v-text-field>
          </ValidationProvider>
          <v-row>
            <v-col>
              <ValidationProvider name="contact1" v-slot="{ errors }">
                <v-text-field
                  v-model="value.contact1"
                  :label="$capitalize($tc('model.products_primary_contact'))"
                  :error-messages="errors"
                  name="contact1"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider
                name="contact2"
                v-slot="{ errors }"
                :rules="`is_not:${value.contact1}`"
              >
                <v-text-field
                  v-model="value.contact2"
                  :label="$capitalize($tc('model.products_secondary_contact'))"
                  :error-messages="errors"
                  name="contact2"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider 
            name="type" 
            v-if="ask_for_type"
            v-slot="{ errors }" 
            rules="required"
          >
            <v-select
              name="type"
              v-model="value.type"
              :label="__('Tipo', 'contact')"
              :error-messages="errors"
              :items="[
                {
                  text: __('Pessoa física', 'contact'),
                  value: 'F'
                },
                {
                  text: __('Pessoa jurídica', 'contact'),
                  value: 'J'
                }
              ]"
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="gender"
            v-if="ask_for_gender"
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              v-model="value.gender"
              name="gender"
              :label="__('Gênero', 'contact')"
              :items="[
                {
                  text: $capitalize($tc('model.interface_uninformed')),
                  value: 'N/A'
                },
                {
                  text: __('Masculino', 'contact'),
                  value: 'M'
                },
                {
                  text: __('Feminino', 'contact'),
                  value: 'F'
                }
              ]"
              :error-messages="errors"
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="fiscal_number"
            rules="length:9"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="value.fiscal_number"
              name="fiscal_number"
              label="NIF"
              :error-messages="errors"
              v-mask="'#########'"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider name="iban" rules v-slot="{ errors }">
            <v-text-field
              v-model="value.iban"
              :label="__('IBAN', 'contact')"
              :error-messages="errors"
              v-mask="'AA## #### #### ########### ##'"
            ></v-text-field>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleCancel" text>{{ $capitalize($tc('model.products_cancel_button')) }}</v-btn>
          <v-btn type="submit" color="primary" :loading="loading">{{ $capitalize($tc('model.products_save_button')) }}</v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import { index } from "@/services/contact";
import _ from "lodash";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Formulário contato"
    },
    only_companies: {
      type: Boolean,
      default: false
    },
  },

  directives: { mask },

  data() {
    return {
      value: {},
      search: "",
      contacts: [],
      loadingContacts: false
    };
  },

  methods: {
    setErrors(errors) {
      this.$refs.form.setErrors(errors);
    },
    fill(value) {
      this.value = { ...value };
      this.$refs.form.reset();
    },
    reset() {
      this.value = {};
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    handleCancel() {
      this.$emit("cancel");
    },
    async handleSubmit() {
      if (!(await this.$refs.form.validate())) return;

      this.$emit("submit", {
        ...this.value,
        type: this.only_companies ? "J" : this.value.type,
        gender: this.ask_for_gender ? this.value.gender : "N/A"
      });
    },
    handleChangeName(event) {
      if (typeof event == "object") {
        this.fill(event);
      } else {
        this.value.id = null;
      }
    },
    async getContacts(filter) {
      this.contacts = [];
      this.loadingContacts = true;
      const contactList = await index({
        filter,
        list: true,
        companies_only: this.only_companies
      });
      this.contacts = contactList;
      this.loadingContacts = false;
    }
  },
  computed: {
    ask_for_gender() {
      return this.value.type == 'F' && !this.only_companies;
    },
    ask_for_type() {
      return !this.only_companies;
    }
  },

  watch: {
    search: _.debounce(async function(value) {
      if (!value) return;

      if (value.length > 2) {
        this.getContacts(value);
      } else {
        this.contacts = [];
      }
    }, 500)
  }
};
</script>
