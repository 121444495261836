// Middlewares
import checkScope from "@/middlewares/scope";

// Routers
import productsRouter from "./products";

/**
 * @path /
 */
export default [
  {
    path: "leads",
    beforeEnter: checkScope("leads.read"),
    component: () => import("@/views/Leads.vue"),
    children: [
      {
        path: "",
        name: "leads_list",
        component: () => import("@/views/LeadsList.vue"),
      },
      {
        path: "notifications",
        name: "leads_notifications",
        component: () => import("@/views/LeadsNotifications.vue"),
      },
    ],
  },
  {
    path: "leads/ops",
    name: "lead_not_found",
    component: () => import("@/views/LeadNotFound.vue"),
  },
  {
    path: "leads/new",
    name: "lead_new",
    component: () => import("@/views/NewLead.vue"),
  },
  {
    path: "leads/new/blank/:insurer_id",
    name: "lead_new_blank",
    component: () => import("@/views/NewBlankLeadPage.vue"),
  },
  {
    path: "leads/new/import/:insurer_id",
    name: "lead_new_import",
    component: () => import("@/views/ImportLeadPage.vue"),
    children: [
      {
        path: "/",
        name: "lead_new_import_file",
        component: () => import("@/views/ImportLeadFile.vue"),
      },
      {
        path: "form",
        name: "lead_new_import_form",
        component: () => import("@/views/ImportLeadForm.vue"),
      },
    ],
  },
  {
    path: "leads/new/import_robot/:job_id",
    name: "lead_new_import_robot",
    component: () => import("@/views/ImportLeadRobot.vue"),
  },
  {
    path: "leads/new/import_robot/:job_id/form",
    name: "lead_new_import_robot_form",
    component: () => import("@/views/ImportLeadRobotForm.vue"),
  },
  {
    path: "form_lead/:lead_number",
    name: "lead_new_blank",
    component: () => import("@/views/NewBlankLeadPage.vue"),
  },
  {
    path: "form_lead/:lead_number/edit",
    name: "lead_edit",
    component: () => import("@/views/EditLeadPage.vue"),
  },
  {
    path: "leads/:lead_number",
    beforeEnter: checkScope("leads.read"),
    component: () => import("@/views/LeadDetail.vue"),
    children: [
      {
        path: "/",
        name: "lead_detail_data",
        component: () => import("@/components/Lead/DataTab.vue"),
      },
      {
        path: "policy",
        name: "lead_detail_policy",
        component: () => import("@/components/Lead/PolicyTab.vue"),
      },
      {
        path: "scheduling",
        beforeEnter: checkScope("leads.schedulings.read"),
        component: () => import("@/components/Lead/SchedulingTab.vue"),
        children: [
          {
            path: "/",
            name: "lead_detail_scheduling_list",
            component: () =>
              import("@/components/Lead/Scheduling/SchedulingList.vue"),
          },
        ],
      },
      {
        path: "comments",
        name: "lead_detail_comments",
        beforeEnter: checkScope("leads.comments.read"),
        component: () => import("@/components/Lead/CommentsTab.vue"),
      },
      {
        path: "annotation",
        name: "lead_detail_annotation",
        beforeEnter: checkScope("leads.annotations.read"),
        component: () => import("@/components/Lead/Annotation.vue"),
      },
      {
        path: "files",
        name: "lead_detail_files",
        beforeEnter: checkScope("leads.attachments.read"),
        component: () => import("@/components/Lead/FilesTab.vue"),
      },
      {
        path: "integration",
        name: "integration",
        beforeEnter: checkScope("leads.integration.read"),
        component: () => import("@/components/Lead/Integration.vue"),
      },
      {
        path: "send_service",
        name: "lead_send_service",
        component: () => import("@/components/Lead/LeadSendService.vue"),
      },
      {
        path: "reports",
        beforeEnter: checkScope("leads.reports.read"),
        component: () => import("@/components/Lead/ReportsTab.vue"),
        children: [
          {
            path: "",
            name: "reports",
            redirect: {
              name: "lead_final_reports",
            },
          },
          {
            path: "visit",
            name: "lead_visit_report",
            components: {
              multirisks: () => import("@/components/Lead/VisitReport.vue"),
            },
          },
          {
            path: "research",
            name: "lead_research_report",
            components: {
              multirisks: () => import("@/components/Lead/ResearchReport.vue"),
            },
          },
          {
            path: "construction",
            name: "lead_construction_report",
            components: {
              multirisks: () =>
                import("@/components/Lead/ConstructionReport.vue"),
            },
          },
          {
            path: "photographic",
            name: "lead_photographic_reports",
            components: {
              multirisks: () =>
                import(
                  "@/components/Lead/PhotographicReport/PhotographicReports.vue"
                ),
            },
          },
          {
            path: "final",
            name: "lead_final_reports",
            beforeEnter: checkScope("leads.final_report.read"),
            components: {
              multirisks: () =>
                import("@/components/Lead/FinalReport/FinalReports.vue"),
              eletric_risk: () =>
                import("@/components/Lead/FinalReport/FinalReports.vue"),
            },
          },
        ],
      },
      {
        path: "reports/final/multirisks/new",
        name: "create_multirisks_final_report",
        beforeEnter: checkScope("leads.final_report.add"),
        component: () =>
          import("@/components/Lead/FinalReport/MultirisksFinalReportForm.vue"),
      },
      {
        path: "reports/final/multirisks/:final_report_id",
        name: "view_multirisks_final_report",
        beforeEnter: checkScope("leads.final_report.read"),
        component: () =>
          import("@/components/Lead/FinalReport/MultirisksFinalReportForm.vue"),
      },
      {
        path: "reports/final/eletric_risk/new",
        name: "create_eletric_risk_final_report",
        beforeEnter: checkScope("leads.final_report.add"),
        component: () =>
          import(
            "@/components/Lead/FinalReport/EletricRiskFinalReportForm.vue"
          ),
      },
      {
        path: "reports/final/eletric_risk/:final_report_id",
        name: "view_eletric_risk_final_report",
        beforeEnter: checkScope("leads.final_report.read"),
        component: () =>
          import(
            "@/components/Lead/FinalReport/EletricRiskFinalReportForm.vue"
          ),
      },
      {
        path: "budgets",
        name: "budgets",
        beforeEnter: checkScope("leads.read"),
        component: () => import("@/views/Budgets.vue"),
      },
      ...productsRouter,
      {
        path: "lead_forms",
        name: "lead_lead_forms",
        component: () => import("@/views/leads/LeadLeadForms.vue"),
      },
    ],
  },
];
