import Vue from "vue";
import vuexStore from "@/store";

export async function refreshParticipants(lead_identifier_code) {
  const lead = vuexStore.state.lead;

  const response = await Vue.prototype.$http.get("/participants", {
    params: {
      paginate: false,
      lead_identifier_code,
    },
  });

  const participants = response.data;

  vuexStore.commit("setLead", {
    ...vuexStore.state.lead,
    participants,
  });
}

export async function changeStatus(lead_id, status_id) {
  const response = await Vue.prototype.$http.post("/leads/change_status", {
    lead_id,
    status_id,
  });

  return response.data;
}

export async function getLeadStatuses() {
  const response = await Vue.prototype.$http.get("/lead_statuses");

  return response.data;
}

export async function approveLead(
  lead_id,
  approved,
  approve_justification = null,
  motive = null
) {
  const response = await Vue.prototype.$http.post("/leads/approve", {
    approve_lead: {
      lead_id,
      approved,
      approve_justification,
      motive,
    },
  });

  return response.data;
}

export async function my_leads(params) {
  const response = await Vue.prototype.$http.get(`/my_leads`, {
    params,
  });

  return response.data;
}

export async function indexUnities(lead_number, team_id) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/unities`,
    {
      params: { team_id },
    }
  );

  return response.data;
}

export async function getStatusHistory(lead_id) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_id}/status_history`
  );

  return response.data;
}

export async function change_manager(lead_id, payload) {
  const response = await Vue.prototype.$http.put(
    `/leads/${lead_id}/change_manager`,
    payload
  );

  return response.data;
}

export async function change_scheduling_participant(lead_id, payload) {
  const response = await Vue.prototype.$http.put(
    `/leads/${lead_id}/change_scheduling_participant`,
    payload
  );

  return response.data;
}

export async function change_team(lead_id, team_id) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_id}/team`, {
    team_id,
  });

  return response;
}

export async function indexReportsInfo(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/reports_info`
  );

  return response.data;
}

export async function indexAvailableOriginTypes(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/available_origin_types`
  );

  return response.data;
}

export async function indexLeadsWithParticipantsNames(lead_number = null) {
  const response = await Vue.prototype.$http.get(`/leads/participants_names`, {
    params: {
      lead_number,
    },
  });

  return response.data;
}

export async function showCreateLeadMetadata(insurer_id) {
  const response = await Vue.prototype.$http.get(
    `/insurer/${insurer_id}/leads/create/metadata`
  );

  return response.data;
}

export async function showEditLeadMetadata(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/edit/metadata`
  );

  return response.data;
}

export async function store(insurer_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/insurer/${insurer_id}/leads/manual`,
    payload
  );

  return response.data;
}

export async function update(lead_number, payload) {
  const response = await Vue.prototype.$http.put(
    `/leads/${lead_number}/edit`,
    payload
  );

  return response.data;
}

export async function deleteLead(lead_number) {
  const response = await Vue.prototype.$http.delete(`/leads/${lead_number}`);

  return response.data;
}

export async function showImportLeadMetadata(insurer_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/insurer/${insurer_id}/leads/import/metadata`,
    payload
  );

  return response.data;
}
