<template>
    <div>
        <v-row>
            <v-col>
                <BudgetPageToolbar
                    :title="$capitalize($tc('model.products_external_budget_title'))"
                    :backTo="{
                        name: 'ProductPageData'
                    }"
                    :newTo="{
                        name: 'ProductNewCompensation'
                    }"
                    version-route-name="ProductCompensationData"
                    :budget-versions="compensations"
                />
            </v-col>
        </v-row>

        <router-view></router-view>
    </div>
</template>

<script>
import BudgetPageToolbar from "@/components/Budget/BudgetPageToolbar";

export default {
    components: {
        BudgetPageToolbar
    },

    data() {
        return {
            
        };
    },

    methods: {
        
    },

    computed: {
        compensations() {
            return this.$store.getters['product/getCompensations'];
        }
    }
}
</script>