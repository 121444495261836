import Vue from "vue";

export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/configs/equipaments/types", {
    params,
  });

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post(
    "/configs/equipaments/types",
    payload
  );

  return response.data.data;
}

export async function merge(payload) {
  const response = await Vue.prototype.$http.post(
    "/configs/equipaments/types/merge",
    payload
  );

  return response;
}

export async function update(id, payload) {
  const response = await Vue.prototype.$http.put(
    `/configs/equipaments/types/${id}`,
    payload
  );

  return response.data.data;
}

export async function destroy(data) {
  const response = await Vue.prototype.$http.delete(
    `/configs/equipaments/types`,
    {
      data,
    }
  );

  return response;
}

export async function indexProducts(type_id) {
  const response = await Vue.prototype.$http.get(
    `/configs/equipaments/types/${type_id}/products`
  );

  return response.data.data;
}

export async function search(search) {
  const response = await Vue.prototype.$http.get(`/product_types/search`, {
    params: {
      search,
    },
  });

  return response.data.data;
}
