<template>
  <div>
    <slot v-if="verifyPermission()" />
    <slot name="not" v-else-if="!verifyPermission()" />
  </div>
</template>

<script>
import ScopesMixin from "../mixins/ScopesMixin.vue";

export default {
  name: "ScopeProvider",
  props: ["scope", "onlyMaster", "component"],
  mixins: [ScopesMixin],
  methods: {
    verifyPermission() {
      if (this.onlyMaster) {
        return this.isMaster();
      }

      return this.verifyScope(this.scope);
    },
  },
};
</script>
