import { getSchedulingTypes } from "@/services/scheduling";

export default {
  namespaced: true,
  state: {
    scheduling_types: [],
    loading_scheduling_types: false
  },
  getters: {
    getSchedulingTypes(state) {
      return state.scheduling_types;
    }
  },
  mutations: {},
  actions: {
    async loadSchedulingTypes({ state }) {
      if (state.scheduling_types.length == 0) {
        state.loading_scheduling_types = true;
        try {
          const types = await getSchedulingTypes();
          state.scheduling_types = types ? types : [];
        } catch (error) {
          console.error("erro ao buscar tipos de agendamento", error);
        } finally {
          state.loading_scheduling_types = false;
        }
      } else {
        return false;
      }
    }
  }
};
