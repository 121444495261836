import Vue from "vue";

export const damage_types = {
  edificio: 1,
  conteudo: 2
};

export const damage_classification = {
  suitable: 1,
  unsuitable: 2,
  inconclusive: 3
};

export async function indexDamageTypes() {
  const response = await Vue.prototype.$http.get(`/damage_types`);

  return response.data;
}

export async function indexDamageClaims(lead_number) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_number}/damage_claims`
  );

  return response.data;
}

export async function storeDamageClaim(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_number}/damage_claims`,
    payload
  );

  return response.data;
}

export async function updateDamageClaim(lead_number, damage_claim_id, payload) {
  const response = await Vue.prototype.$http.put(
    `/leads/${lead_number}/damage_claims/${damage_claim_id}`,
    payload
  );

  return response.data;
}

export async function destroyDamageClaim(lead_number, damage_claim_id) {
  const response = await Vue.prototype.$http.delete(
    `/leads/${lead_number}/damage_claims/${damage_claim_id}`
  );

  return response.data;
}

export async function uploadImage(claim_id, file) {
  const formData = new FormData();
  formData.append("attachment", file);

  const response = await Vue.prototype.$http.post(
    `/damage_claims/${claim_id}/upload/images`,
    formData
  );

  return response.data;
}

export async function deleteImage(claim_id, attachment_id) {
  const response = await Vue.prototype.$http.delete(
    `/damage_claims/${claim_id}/images/${attachment_id}`
  );

  return response.data;
}

export async function linkAttachmentsToDamageClaim(claim_id, attachments) {
  const response = await Vue.prototype.$http.post(
    `/damage_claims/${claim_id}/link/images`,
    { attachments }
  );

  return response.data;
}

export async function patchExternalBudgetDoc(
  lead_number,
  damage_claim_id,
  file
) {
  const formData = new FormData();
  formData.append("attachment", file);
  formData.append("file_id", file?.id ? file.id : "");

  const response = await Vue.prototype.$http.post(
    `/leads/${lead_number}/damage_claims/${damage_claim_id}/external_budget`,
    formData
  );

  return response.data;
}
