<template>
  <div id="product-form">
    <ValidationObserver ref="productDetails" v-slot="{ errors, failed }">
      <v-form @submit.prevent="handleSubmitForm">
        <v-row v-if="failed">
          <v-col>
            <v-alert type="error" ref="">
              <div class="font-weight-bold">
                {{ __("validações", "products") }}
              </div>
              <ol>
                <li
                  v-for="(error, index) in prepareErrors(errors)"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ol>
            </v-alert>
          </v-col>
        </v-row>

        <!-- Detalhes do produto -->
        <v-row>
          <v-col>
            <v-card>
              <v-form>
                <v-card-title>
                  {{ $capitalize($tc("model.products_product_details")) }}
                </v-card-title>
                <v-card-text>
                  <ValidationProvider
                    name="product.barcode"
                    rules="alpha_num"
                    v-slot="{ errors }"
                  >
                    <barcode-field
                      v-model="form.product.barcode"
                      :error-messages="errors"
                      @applySugestion="fillSugestion"
                    >
                    </barcode-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="product.type"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <ProductTypePicker
                      v-model="form.product.type"
                      @change="handleChangeProductType"
                      data-vv-name="product.type"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider
                        name="product.brand"
                        :rules="{}"
                        v-slot="{ errors }"
                      >
                        <ProductBrandPicker
                          v-model="form.product.brand"
                          @change="generateProductName()"
                          :error-messages="errors"
                          name="product.brand"
                          clearable
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider
                        name="product.model"
                        :rules="{
                          required: false,
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          name="product.model"
                          v-model="form.product.model"
                          :label="
                            $capitalize($tc('model.products_model_product'))
                          "
                          :placeholder="
                            $capitalize(
                              $tc('model.products_exemple_model_placeholder')
                            ) + ': TK33432'
                          "
                          @input="generateProductName()"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider
                        name="product.serial_number"
                        :rules="{
                          required: false,
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          name="product.model"
                          v-model="form.product.serial_number"
                          :label="
                            $capitalize($tc('model.products_serial_number'))
                          "
                          :placeholder="
                            $capitalize(
                              $tc('model.products_exemple_model_placeholder')
                            ) + ': 4CE0460D0G'
                          "
                          @input="generateProductName()"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="product.purchase_date"
                        :rules="{
                          required: false,
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          :label="
                            $capitalize(
                              $tc('model.products_purchase_date_optional')
                            )
                          "
                          type="date"
                          v-model="form.product.purchase_date"
                          @change="updateProductAge"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="product.age"
                        :rules="{
                          numeric: true,
                          min: 0,
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          :label="
                            $capitalize(
                              $tc('model.products_product_age_optional')
                            )
                          "
                          type="number"
                          v-model="form.product.age"
                          :error-messages="errors"
                          name="product.age"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="product.depreciation_percentage"
                        v-slot="{ errors }"
                      >
                        <MoneyInput
                          v-model="form.product.depreciation_percentage"
                          :options="{
                            locale: 'pt-PT',
                            prefix: '',
                            suffix: '%',
                            length: 11,
                            precision: 2,
                          }"
                          :label="
                            $capitalize($tc('model.products_devaluation'))
                          "
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <ValidationProvider
                    name="product.name"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :label="$capitalize($tc('model.products_name_product'))"
                      :placeholder="
                        $capitalize(
                          $tc('model.products_exemple_model_placeholder')
                        ) + ': TV Samsung TK33432'
                      "
                      v-model="form.product.name"
                      append-icon="mdi-replay"
                      @click:append="generateProductName()"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="product.insurance_object_id"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <insurance-object-input
                      name="product.insurance_object_id"
                      :items="lead.policy.insurance_objects"
                      v-model="form.product.insurance_object_id"
                      key="product.insurance_object_id"
                      :error-messages="errors"
                    ></insurance-object-input>
                  </ValidationProvider>

                  <ValidationProvider
                    name="product.size"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-radio-group
                      name="product.size"
                      v-model="form.product.size"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        <div>
                          {{ $capitalize($tc("model.products_size_product")) }}
                        </div>
                      </template>
                      <v-radio
                        value="big"
                        :label="
                          $capitalize($tc('model.products_big_size_product'))
                        "
                      ></v-radio>
                      <v-radio
                        value="small"
                        :label="
                          $capitalize($tc('model.products_small_size_product'))
                        "
                      ></v-radio>
                    </v-radio-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="product.owner_id"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <ParticipantPicker
                      v-model="form.product.owner_id"
                      :lead="lead"
                      :label="$capitalize($tc('model.products_owner_product'))"
                      :placeholder="
                        $capitalize($tc('model.products_choose_participant'))
                      "
                      insured
                      :error-messages="errors"
                      name="product.owner_id"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    name="product.storage_participant_id"
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <ParticipantPicker
                      v-model="form.product.storage_participant_id"
                      :lead="lead"
                      :label="
                        $capitalize($tc('model.products_responsible_product'))
                      "
                      :placeholder="
                        $capitalize($tc('model.products_insured_workshop'))
                      "
                      :error-messages="errors"
                      @filter="filterParticipantCanStoreProducts"
                      :disabled="!form.product.owner_id"
                      hint="Selecione o dono do produto"
                      :persistent-hint="!form.product.owner_id"
                    />
                  </ValidationProvider>
                </v-card-text>
              </v-form>
            </v-card>
          </v-col>
        </v-row>

        <!-- Ficha técnica -->
        <v-row>
          <v-col>
            <ProductTechnicalDetails
              ref="technicalDetails"
              v-model="form.product.technical_details"
              :product-type-id="form.product.type ? form.product.type.id : null"
            />
          </v-col>
        </v-row>

        <!-- Avaliação do produto -->
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                {{ $capitalize($tc("model.products_product_evaluation")) }}
              </v-card-title>

              <v-card-text>
                <ValidationProvider
                  name="product.technical_analysis"
                  :rules="{
                    required: false,
                    min: 50,
                  }"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    v-model="form.product.technical_analysis"
                    :label="
                      $capitalize(
                        $tc('model.products_technical_evaluation_optional')
                      )
                    "
                    outlined
                    :error-messages="errors"
                    name="product.technical_analysis"
                    counter
                  ></v-textarea>
                </ValidationProvider>
                <ValidationProvider
                  name="product.fitting"
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                >
                  <v-radio-group v-model="fitting" :error-messages="errors">
                    <template v-slot:label>
                      <div>
                        {{ $capitalize($tc("model.products_product_framed")) }}
                      </div>
                    </template>
                    <v-radio
                      :value="UNKNOWN_FITTING"
                      :label="
                        $capitalize(
                          $tc('model.products_not_identified_product')
                        )
                      "
                    ></v-radio>
                    <v-radio
                      :value="FITTED"
                      :label="$capitalize($tc('model.products_yes_product'))"
                    ></v-radio>
                    <v-radio
                      :value="UNFITTED"
                      :label="$capitalize($tc('model.products_no_product'))"
                    ></v-radio>
                  </v-radio-group>
                </ValidationProvider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <AttachmentGallery
              :title="$capitalize($tc('model.products_photographs_product'))"
              v-model="form.product.photos"
              can-delete
            >
              <template v-slot:prepend>
                <div>
                  <input
                    @input="handleUploadAttachments"
                    type="file"
                    ref="fileInput"
                    accept="image/*"
                    v-show="false"
                    multiple
                  />
                  <div class="mb-2">
                    <v-alert v-model="uploadingPhotos" dense text type="info">
                      {{ $capitalize($tc("model.products_loading_photo"))
                      }}<b>{{ uploadCounter }}</b>
                    </v-alert>
                    <v-alert
                      v-model="successUploading"
                      type="success"
                      dismissible
                      border="left"
                      colored-border
                      elevation="2"
                    >
                      <div>
                        <b class="success--text">
                          {{
                            $capitalize(
                              $tc(
                                "model.products_photos_added_document_management"
                              )
                            )
                          }}
                        </b>
                      </div>

                      <div>
                        {{
                          $capitalize(
                            $tc(
                              "model.products_photos_remain_document_management"
                            )
                          )
                        }}
                      </div>
                    </v-alert>
                  </div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary">
                        <v-icon left>mdi-plus</v-icon>
                        {{
                          $capitalize($tc("model.schedulings_steps_add_button"))
                        }}
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="photosDialog = true">
                        <v-icon left>mdi-file-search-outline</v-icon>
                        {{
                          $capitalize(
                            $tc("model.products_from_document_management")
                          )
                        }}
                      </v-list-item>
                      <v-list-item @click="handleOpenUploadDialog">
                        <v-icon left>mdi-cellphone-link</v-icon>
                        {{ $capitalize($tc("model.products_from_my_device")) }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </AttachmentGallery>
          </v-col>
        </v-row>

        <!-- Action Bar -->
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title> </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="handleCancel()" class="mr-2" elevation="0">
                  {{ $capitalize($tc("model.damages_cancel_button")) }}
                </v-btn>
                <v-btn type="submit" color="primary">{{
                  $capitalize($tc("model.damages_save_button"))
                }}</v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <SelectPhotosDialog
      v-model="photosDialog"
      :photos="leadPhotos"
      :selected_photos="form.product.photos"
      @submit="handleSubmitPhotos"
    />
  </div>
</template>
<script>
import ProductTypePicker from "@/components/products/ProductTypePicker";
import BarCodeField from "@/components/products/BarCodeField";
import ProductBrandPicker from "@/components/products/ProductBrandPicker";
import ParticipantPicker from "@/components/Lead/ParticipantPicker";
import ProductTechnicalDetails from "@/components/products/ProductTechnicalDetails";
import AttachmentGallery from "@/components/AttachmentGallery";
import SelectPhotosDialog from "@/components/Lead/PhotographicReport/SelectPhotosDialog";
import InsuranceObjectInput from "@/components/Lead/InsuranceObjectInput";
import MoneyInput from "@/components/MoneyInput";
import {
  indexLeadPhotos,
  store as storeAttachment,
} from "@/services/attachment";
import i18n from "@/mixins/i18n";

export default {
  components: {
    ProductTypePicker,
    ProductBrandPicker,
    ParticipantPicker,
    ProductTechnicalDetails,
    AttachmentGallery,
    "barcode-field": BarCodeField,
    SelectPhotosDialog,
    InsuranceObjectInput,
    MoneyInput,
  },
  mixins: [i18n],
  data() {
    return {
      FITTED: 1,
      UNFITTED: 2,
      UNKNOWN_FITTING: 3,

      form: {
        product: {
          name: "",
          type: null,
          technical_details: [],
          age: null,
          photos: [],
        },
      },
      imagesToUpload: [],
      photosDialog: false,
      leadPhotos: [],
      uploadingPhotos: false,
      uploadCounter: "",
      successUploading: false,
    };
  },

  methods: {
    uploadFile(file) {
      const data = new FormData();
      data.append("attachment", file);
      data.append("category_id", 1);

      return storeAttachment(this.$route.params.lead_number, data);
    },
    async handleUploadAttachments(event) {
      const files = event.target.files;

      this.uploadingPhotos = true;
      for (const [index, file] of [...files].entries()) {
        this.uploadCounter = `${index + 1}/${files.length}`;
        this.form.product.photos.push(await this.uploadFile(file));
      }
      this.uploadingPhotos = false;
      this.successUploading = true;
    },
    handleOpenUploadDialog() {
      this.$refs.fileInput.click();
    },
    fillSugestion(sugestion) {
      this.form.product = {
        ...this.form.product,
        type: { ...sugestion.type },
        brand: { ...sugestion.brand },
        model: sugestion.model,
        name: sugestion.name,
        technical_details: [],
      };

      let newFeatures = [];

      sugestion.technical_details.forEach((feature) => {
        newFeatures = this.$refs.technicalDetails.saveFeature(
          feature,
          newFeatures
        );
      });

      this.form.product.technical_details = newFeatures;

      this.$store.commit("alert", "Produto aplicado");
    },
    setErrors(errors) {
      this.$refs.productDetails.setErrors(errors);
      this.$vuetify.goTo("#product-form");
    },
    fill(form) {
      this.form = { ...form };
      this.$nextTick(() => {
        this.$refs.productDetails.reset();
      });
    },
    prepareErrors(errors) {
      if (!errors) return;

      const preparedErrors = [];

      for (const field in errors) {
        if (errors[field].length) {
          preparedErrors.push(errors[field][0]);
        }
      }

      return preparedErrors;
    },
    updateProductAge(purchase_date) {
      if (purchase_date) {
        const purchase_date_moment = this.$moment(purchase_date);
        const product_age = this.$moment().diff(purchase_date_moment, "years");
        this.form.product.age = product_age;
      }
    },
    handleChangeProductType(event) {
      this.generateProductName();
    },
    generateProductName() {
      const type = this.form.product?.type?.name;
      const brand = this.form.product?.brand?.name;
      const model = this.form.product?.model;

      this.form.product.name = [type, brand, model].filter(Boolean).join(" ");
    },
    async handleSubmitForm() {
      let validation = true;
      validation = await this.$refs.productDetails.validate();

      if (!validation) {
        this.$vuetify.goTo("#product-form");
        return;
      }

      this.$emit("submit", {
        ...this.form,
        product: {
          ...this.form.product,
        },
        imagesToUpload: this.imagesToUpload,
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    async loadLeadPhotos() {
      this.leadPhotos = await indexLeadPhotos(this.$route.params.lead_number);
    },
    handleSubmitPhotos(event) {
      this.form.product.photos = this.form.product.photos.concat(event);
    },
    filterParticipantCanStoreProducts(participant) {
      return (
        participant.id == this.form.product.owner_id ||
        participant.type.canonical == "workshop"
      );
    },
  },

  watch: {
    photosDialog(value) {
      if (value === true) {
        this.loadLeadPhotos();
      }
    },
  },

  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    fitting: {
      get() {
        if (!this.form.product) return this.UNKNOWN_FITTING;

        if (this.form.product.fitting === true) return this.FITTED;
        else if (this.form.product.fitting === false) return this.UNFITTED;

        return this.UNKNOWN_FITTING;
      },
      set(value) {
        if (value === this.UNFITTED) {
          this.form.product.fitting = false;
        } else if (value === this.FITTED) {
          this.form.product.fitting = true;
        } else {
          this.form.product.fitting = null;
        }
      },
    },
  },
};
</script>
