<template>
    <v-autocomplete
        :label="label"
        :placeholder="placeholder"
        :items="items"
        item-value="id"
        :item-text="getItemText"
        @input="$emit('change', $event)"
        :name="name"
        :error-messages="errorMessages"
        :value="value"
        :return-object="returnObject"
        v-bind="$attrs"
    ></v-autocomplete>
</template>

<script>
import { abreviate } from "@/tools/names";

export default {
    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: [Number, Object],
        abreviated: Boolean,
        lead: {
            type: Object,
            default: null
        },
        label: {
            default: 'Participante',
            type: String
        },
        placeholder: {
            default: '',
            type: String
        },
        insured: {
            type: Boolean,
            default: false
        },
        notInsured: {
            type: Boolean,
            default: false
        },
        returnObject: Boolean,
        name: String,
        errorMessages: Array
    },

    data() {
        return {};
    },

    methods: {
        getItemText(item) {            
            if(this.abreviated) {
                return abreviate(item.contact?.name);
            }

            return item.contact?.name;
        }
    },

    computed: {
        items() {
            if(!this.lead) return [];

            const filtered = this.lead.participants.filter(participant => {
                if(this.insured) {
                    return participant.insured;
                } else if(this.notInsured) {
                    return participant.insured == false;
                }

                if (this.$listeners.filter) {
                    return this.$listeners.filter(participant);
                }

                return true;
            });

            const prepared = filtered.map(participant => {
                return {
                    ...participant,
                    contact: {
                        ...participant.contact,
                        name: `${participant.contact.name} (${participant.type.description})`
                    }
                }
            });

            return prepared;
        }
    }
}
</script>