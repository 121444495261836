import { indexActions } from "@/services/eps";

export default {
  namespaced: true,
  state: {
    actions: []
  },
  getters: {
    getActions(state) {
      return state.actions;
    }
  },
  mutations: {},
  actions: {
    async loadActions({ state }) {
      if (state.actions.length == 0) {
        try {
          const actions = await indexActions();
          state.actions = actions ? actions : [];
        } catch (error) {
          console.error("erro ao buscar ações", error);
        }
      } else {
        return false;
      }
    }
  }
};
