// Middlewares
import checkScope from "@/middlewares/scope";

// Pages
import ProductSubstituitionPage from "@/views/ProductSubstituitionPage";
import ProductNewSubstituitionPage from "@/views/ProductNewSubstituitionPage";
import ProductSubstituitionData from "@/views/ProductSubstituitionData";

/**
 * @path /leads/:lead_number/products/:product_id
 */
export default [
    {
        path: "substituitions",
        beforeEnter: checkScope("leads.read"),
        component: ProductSubstituitionPage,
        children: [
            {
                path: "/",
                redirect: { name: "ProductSubstituitionDataLatest" }
            },
            {
                path: "new",
                name: "ProductNewSubstituition",
                component: ProductNewSubstituitionPage
            },
            {
                path: "latest",
                name: "ProductSubstituitionDataLatest",
                component: ProductSubstituitionData
            },
            {
                path: ":version",
                name: "ProductSubstituitionData",
                component: ProductSubstituitionData
            },
        ]
    },
];