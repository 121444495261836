import {
  indexBudgetTypes,
  indexBudgetObjectives,
  indexBudgetsByCoverages,
  indexParticipants
} from "@/services/established_budgets";

export default {
  namespaced: true,
  state: {
    fetching_budgets: false,
    budget_types: [],
    budget_objectives: [],
    participants: [],

    fetching_budgets_by_coverages: false,
    budgets_by_coverages: []
  },
  getters: {
    getBudgetTypes(state) {
      return state.budget_types;
    },
    getBudgetObjectives(state) {
      return state.budget_objectives;
    },
    getParticipants(state) {
      return state.participants;
    },
    getBudgetsByCoverage(state) {
      return state.budgets_by_coverages;
    }
  },
  mutations: {
    setBudgetTypes(state, payload) {
      state.budget_types = payload;
    },
    setBudgetObjectives(state, payload) {
      state.budget_objectives = payload;
    },
    setParticipants(state, payload) {
      state.participants = payload;
    },
    setBudgetsByCoverage(state, payload) {
      state.budgets_by_coverages = payload;
    }
  },
  actions: {
    clearParticipants(context) {
      context.commit("setParticipants", []);
    },
    addBudget(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      participant.budgets.push({
        ...payload
      });
    },
    addExternalBudget(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      participant.external_budgets.push({
        ...payload
      });
    },
    editBudget(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      const budget = participant.budgets.find(
        budget => budget.id == payload.id
      );

      Object.assign(budget, payload);
    },
    editExternalBudget(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      const budget = participant.external_budgets.find(
        budget => budget.id == payload.id
      );

      Object.assign(budget, payload);
    },
    removeBudget(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      participant.budgets = participant.budgets.filter(
        budget => budget.id != payload.id
      );
    },
    removeExternalBudget(context, payload) {
      const participant = context.state.participants.find(
        participant => participant.id == payload.participant_id
      );

      participant.external_budgets = participant.external_budgets.filter(
        budget => budget.id != payload.id
      );
    },
    async loadBudgets(context, lead_number) {
      context.commit("setParticipants", []);
      context.state.fetching_budgets = true;
      try {
        const response = await indexParticipants(lead_number);
        context.commit("setParticipants", response);
      } catch (e) {
        context.commit(
          "sendMessage",
          {
            text: "Não foi possível carregar os danos dos participantes",
            color: "red"
          },
          { root: true }
        );
      } finally {
        context.state.fetching_budgets = false;
      }
    },
    async loadBudgetTypes({ state, commit }) {
      if (state.budget_types.length == 0) {
        try {
          const budget_types = await indexBudgetTypes();
          commit("setBudgetTypes", budget_types ? budget_types : []);
        } catch (error) {
          console.error("erro ao buscar tipos de orçamentos", error);
        }
      } else {
        return false;
      }
    },
    async loadBudgetObjectives({ state, commit }) {
      if (state.budget_objectives.length == 0) {
        try {
          const budget_objectives = await indexBudgetObjectives();
          commit("setBudgetObjectives", budget_objectives ? budget_objectives : []);
        } catch (error) {
          console.error("erro ao buscar objetivos de orçamentos", error);
        }
      } else {
        return false;
      }
    },
    async loadBudgetsByCoverages({ state, commit }, lead_number) {
      state.fetching_budgets_by_coverages = true;
      try {
        const budgets_by_coverages = await indexBudgetsByCoverages(lead_number);
        commit("setBudgetsByCoverage", budgets_by_coverages ? budgets_by_coverages : []);
      } catch (error) {
        console.error("erro ao buscar orçamentos", error);
      } finally {
        state.fetching_budgets_by_coverages = false;
      }
    }
  }
};
