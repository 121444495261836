import { index as indexRoomTypes } from "@/services/room_types";

export default {
  namespaced: true,
  state: {
    room_types: []
  },
  getters: {
    getRoomTypes(state) {
      return state.room_types;
    }
  },
  mutations: {},
  actions: {
    async loadRoomTypes({ state }) {
      if (state.room_types.length == 0) {
        try {
          const types = await indexRoomTypes();

          state.room_types = types;
        } catch (error) {
          console.error("erro ao buscar os tipos de fracções", error);
        }
      }
    }
  }
};
