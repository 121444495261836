<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon>
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        @click="handleChangeLanguage(language)"
        :disabled="isActualLanguage(language)"
        v-for="language in languages"
        :key="language.id"
        :class="{ 'primary--text': isActualLanguage(language) }"
      >
        <v-list-item-avatar size="25">
          <v-img :src="language.flag_image_url"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ language.origin_name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  methods: {
    handleChangeLanguage(language) {
      this.$store.dispatch("localization/changeLanguage", language);
    },
    isActualLanguage(language) {
      return language.id === this.actualLanguage.id;
    },
  },

  computed: {
    ...mapState("localization", ["languages", "changing_language"]),
    actualLanguage() {
      return this.user.language || {};
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },
};
</script>
