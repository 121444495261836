import Vue from "vue";

export async function index(params = {}) {
  const response = await Vue.prototype.$http.get("/info_requests", {
    params
  });

  return response.data;
}

export async function indexMyInfoRequests(params = {}) {
  const response = await Vue.prototype.$http.get("/my_info_requests", {
    params
  });

  return response.data;
}

export async function close(id) {
  const response = await Vue.prototype.$http.put(`/info_requests/${id}/close`);
  return response.data;
}

export async function reopen(id) {
  const response = await Vue.prototype.$http.put(`/info_requests/${id}/reopen`);
  return response.data;
}

export async function sendAndCloseInfoRequest(lead_number, closing_comment_id, payload) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_number}/comment/${closing_comment_id}/close`, payload);
  return response.data;
}

export async function openInfoRequest(lead_number, payload) {
  const response = await Vue.prototype.$http.post(`/leads/${lead_number}/info_requests/open`, payload);
  return response.data;
}

export function getInboundNotificationStatus(notification) {
  let icon = null;
  let text = null;
  let status = null;

  if (notification) {
    if (notification?.blocked) {
      icon = "mdi-alert";
      status = "error";
      text = "Falha na integração da resposta ao pedido de informação";
    } else if (notification?.executed_at) {        
      icon = "mdi-check-circle-outline";
      status = "success";
      text = "Resposta ao pedido de informação integrada com sucesso";
    } else {
      icon = "mdi-pause-circle-outline";
      status = "info";
      text = "Aguardando integração da resposta ao pedido de informação";
    }
  }

  return {
    icon,
    text,
    status
  };
}
