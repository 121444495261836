<template>
  <v-container class="px-5 py-5" fluid>
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "container",
}
</script>