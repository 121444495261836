import { index as indexRoomForfaits } from '@/services/room_forfaits';


export default {
  namespaced: true,
  state: {
    room_forfaits: []
  },
  getters: {
    getRoomForfaits(state) {
      return state.room_forfaits;
    }
  },
  mutations: {},
  actions: {
    async loadRoomForfaits({ state }) {
      if (state.room_forfaits.length == 0) {
        try {
          const forfaits = await indexRoomForfaits();

          state.room_forfaits = forfaits;
        } catch (error) {
          console.error("erro ao buscar os forfaits das fracções", error);
        }
      }
    }
  }
};
