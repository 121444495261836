<template>
    <div>
        <div class="productInfo" v-if="product">
            <v-row>
                <v-col>
                    <v-card>
                        <v-toolbar elevation="0">
                            <v-btn @click="$router.push({
                                name: 'ProductsList'
                            })" icon>
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                            <v-toolbar-title class="primary--text">
                                {{ product.name }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon :to="{
                                name: 'EditProduct',
                                params: {
                                    product_id: $route.params.product_id
                                }
                            }">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-menu offset-x left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item :to="{ name: 'NewProduct' }"> 
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-icon left>mdi-plus</v-icon>
                                                    {{ $capitalize($tc('model.products_new_product_button')) }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :to="{
                                        name: 'EditProduct',
                                        params: {
                                            product_id: $route.params.product_id
                                        }
                                    }"> 
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-icon left>mdi-pencil</v-icon>
                                                    {{ $capitalize($tc('model.products_change_product')) }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="deleteProduct"> 
                                        <v-list-item-content>
                                            <v-list-item-title class="red--text">
                                                <v-icon color="red" left>mdi-delete</v-icon>
                                                    {{ $capitalize($tc('model.products_delete_product')) }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                    </v-card>
                </v-col>
            </v-row>

            <router-view></router-view>
        </div>
        <ProductNotFound class="productNotFound" v-else-if="notFound" />
    </div>
</template>

<script>
import { destroy } from "@/services/products/products";
import ProductNotFound from "@/components/products/ProductNotFound";

export default {
    components: { 
        ProductNotFound
    },

    data() {
        return {
            loading: false,
            notFound: false
        };
    },

    methods: {
        deleteProduct() {
            this.$store.dispatch("confirm_dialog/confirm", {
                title: this.$capitalize(this.$tc('model.products_confirmation_delect_product_title')),
                description: this.$capitalize(this.$tc('model.products_description_delect_product')),
                positive_action: async () => {
                    const productId = this.$route.params.product_id;
                    const leadNumber = this.$route.params.lead_number;

                    await destroy(productId, leadNumber);
                    this.$store.commit('alert', this.$capitalize(this.$tc('model.products_delete_product_alert')));
                    this.$router.replace(`/leads/${leadNumber}/products`);
                },
            });  
        }
    },

    computed: {
        product: {
            get() {
                return this.$store.getters['product/getProduct'];
            },
            set(value) {
                return this.$store.commit('product/setProduct', value);
            }
        }
    },

    async created() {
        const leadNumber = this.$route.params.lead_number;
        const productId = this.$route.params.product_id;
        
        this.$store.commit('setLoading', {
            loading: true
        });
        
        try {
            await this.$store.dispatch('product/loadProduct', { productId, leadNumber });
            await this.$store.dispatch('product/loadCompensations', { productId });
            await this.$store.dispatch('product/loadReparations', { productId });
            await this.$store.dispatch('product/loadSubstituitions', { productId });
        } catch(error) {
            if(error.response.status == 404) {
                this.notFound = true;
            }
        } finally {
            this.$store.commit('setLoading', {
                loading: false
            });
        }
    }
}
</script>