<template>
  <v-dialog :value="value" @change="handleChange" persistent max-width="500px">
    <v-card>
      <ValidationObserver ref="reminder_form" v-slot="{ changed }">
        <template v-if="screen == 1">
          <v-app-bar flat>
            <v-toolbar-title>{{ $capitalize($tc("model.reminder_reminder_title")) }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="handleClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list>
            <ValidationProvider name="predefined_date">
              <v-list-item-group v-model="predefined_date">
                <v-list-item v-for="predefined_date in predefined_dates" v-slot="{ active }" :key="predefined_date.value" :value="predefined_date">
                  <v-row dense>
                    <v-col cols="5">
                      <div>
                        <v-icon left color="primary">{{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
                        <span>{{ predefined_date.text }}</span>
                      </div>
                    </v-col>

                    <v-col class="text-right">
                      {{ predefined_date.default_time}}
                    </v-col>
                  </v-row>
                  <v-progress-linear indeterminate v-if="active && loading" />
                </v-list-item>
              </v-list-item-group>
            </ValidationProvider>
            <v-list-item @click="screen = 2">
              <v-icon left>mdi-clock-outline</v-icon>
              {{ $capitalize($tc("model.reminder_choose_date_time_text")) }}
            </v-list-item>
          </v-list>
        </template>
        <template v-if="screen == 2">
          <v-app-bar flat>
            <v-icon left @click="screen = 1">mdi-arrow-left</v-icon>
            <v-toolbar-title>{{ $capitalize($tc("model.reminder_choose_date_time_text")) }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="handleClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-card-text class="pt-5">
            <ValidationProvider rules="required" name="date" v-slot="{ errors }">
              <Timestamp
                v-model="local_datetime"
                :date.sync="local_date"
                :time.sync="local_time"
                :error-messages="errors"
              >
                <v-text-field
                  v-model="local_date"
                  type="date"
                  dense
                  outlined
                  :min="min_date"
                />
                <v-text-field
                  v-model="local_time"
                  type="time"
                  dense
                  outlined
                />
              </Timestamp>
            </ValidationProvider>
          </v-card-text>
        </template>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <ValidationProvider name="send_mail">
            <v-checkbox
              class="my-0 align-self-end pr-3"
              v-model="local_send_mail"
              :label="
                $capitalize($tc('model.reminder_send_email_checkbox'))
              "
              color="primary"
            />
          </ValidationProvider>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            @click="handleSubmit" 
            :loading="predefined_date == null && loading"
            :disabled="disabledApply || !changed"
          >{{ $capitalize($tc("model.reminder_apply_button")) }}</v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Timestamp from "@/components/tools/Timestamp";
export default {
  name: 'reminder-dialog',
  components: {
    Timestamp
  },
  props: {
    value: Boolean,
    reminder: Object
  },
  data() {
    return {
      screen: 1,

      predefined_date: null,
      predefined_dates: [
        {
          value: 'today',
          text: this.$capitalize(this.$tc('model.reminder_today_text')),
          default_time: this.getTodayDefaultTime()
        },
        {
          value: 'tomorrow',
          text: this.$capitalize(this.$tc('model.reminder_tomorrow_text')),
          default_time: '09:00'
        }
      ],
      local_datetime: "",
      local_date: "",
      local_time: "",
      local_send_mail: false,

      loading: false
    };
  },
  computed: {
    min_date() {
      return this.$moment().format("YYYY-MM-DD");
    },
    disabledApply() {
      return this.local_datetime == null;
    }
  },
  methods: {
    getTodayDefaultTime() {
      return this.$moment().add(4, 'hours').startOf('hour').format('HH:mm');
    },
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      this.reset();

      if (this.$listeners.close) this.$listeners.close();
    },
    reset() {
      this.screen = 1;
      this.predefined_date = null;
      this.local_datetime = "";
      this.local_date = "";
      this.local_time = "";
      this.local_send_mail = false;
    },
    async handleSubmit() {
      this.loading = true;
      await this.$listeners.submit({
        date: this.local_datetime,
        send_mail: this.local_send_mail
      });
      this.loading = false;
      this.handleClose();
    },
    initializeEditMode() {
      if (this.reminder) {
        this.screen = 2;
        this.local_datetime = this.reminder.date;
        this.local_send_mail = this.reminder.send_mail;
      }
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.initializeEditMode();
      } else {
        this.handleClose();
      }
    },
    predefined_date(value) {
      if (value) {
        const now = this.$moment();
        if (value.value == 'today') {
          this.local_datetime = now.format('YYYY-MM-DD') + ' ' + value.default_time;
        } else if (value.value == 'tomorrow') {
          this.local_datetime = now.add(1, 'day').format('YYYY-MM-DD') + ' ' + value.default_time;
        }
      }
    }
  },
  created() {
    this.initializeEditMode();
  }
}
</script>

<style>

</style>