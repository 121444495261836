<template>
    <v-autocomplete
        @input="handleInput"
        :value="value"
        :label="label" 
        :items="attachments"
        item-value="id"
        item-text="name"
        :loading="loading"
        v-bind="$attrs"
    >
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        value: String,
        loading: {
            type: Boolean,
            default: false
        },
        attachments: {
            type: Array,
            default: () => ([])
        },
        label: {
            type: String,
            default: "Anexo"
        }
    },

    methods: {
        handleInput(event) {
            this.$emit('input', event);
        }
    }
}
</script>