<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="primary" v-on="on" v-bind="attrs">
        {{ $capitalize($tc('model.products_select_button')) }} <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        @click="handleInput(budget)"
        v-for="budget in items"
        :key="budget.id"
      >
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ budget.objective.description }}
            <span v-if="budget.external_objective">
              - {{ budget.external_objective.description }}
            </span>
          </v-list-item-subtitle>
          <v-list-item-title>
            {{ euro(budget.total_value_with_iva) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Formatter from "@/tools/money";

export default {
  props: {
    productId: Number
  },

  data() {
    return {
      euro: Formatter.euro
    };
  },

  methods: {
    async handleInput(item) {
      const response = await this.$http.put(
        `/products/${this.productId}/budgets/${item.id}/select`
      );

      this.$emit("input", response.data);
    }
  },

  computed: {
    items() {
      const lastCompensation = this.$store.getters[
        "product/getCompensationByVersion"
      ]("latest");

      const lastReparation = this.$store.getters[
        "product/getReparationByVersion"
      ]("latest");

      const lastSubstituition = this.$store.getters[
        "product/getSubstituitionByVersion"
      ]("latest");

      return [lastCompensation, lastReparation, lastSubstituition].filter(
        item => !!item
      );
    }
  }
};
</script>
